import React from "react";

function AvailableTimes({ addedSlots, currentDay }) {
  let daySlots = addedSlots?.find((item) => item?.dayOfWeek === currentDay);
  const hasAllDaySlot = daySlots?.slots?.some(
    (slot) => slot?.startTime === "09:00" && slot?.endTime === "18:00"
  );

  return (
    <div className="range">
      {hasAllDaySlot ? (
        <div className="noSchedule">
          <div className="time">
            <p>All Day - Opening hours</p>
          </div>
        </div>
      ) : (
        daySlots?.slots?.map((slot) => {
          return (
            <div className="from">
              <div className="time">
                <p>{slot?.startTime}</p>
              </div>
              <div className="rangeIndicator">-</div>
              <div className="time">
                <p>{slot?.endTime}</p>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default AvailableTimes;

class addedVaccineTemplate {
  constructor(title, order) {
    this.obj = {
      groupId: "00000000-0000-0000-0000-000000000000",
      order: order,
      sectionTitle: title,
      isParent: false,
      userControls: [
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Vaccine",
          type: 1,
          label: "Vaccine Name",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 1,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "BatchNumber",
          type: 1,
          label: "Batch Number",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 2,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Expiry",
          type: 1,
          label: "Expiry",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 3,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Site",
          type: 1,
          label: "Site",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 4,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Route",
          type: 1,
          label: "Route",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 5,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Notes",
          type: 1,
          label: "Notes",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 6,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Quantity",
          type: 1,
          label: "Quantity",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 7,
          parentUserControlId: null,
          isChildControl: null,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Dose",
          type: 1,
          label: "Dose",
          inline: false,
          required: false,
          value: "",
          listItems: [],
          order: 8,
          parentUserControlId: null,
          isChildControl: null,
        },
      ],
      templateSectionType: 3,
    };
  }
}
export default addedVaccineTemplate;

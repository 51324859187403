import React from "react";
import { Modal, Box, Fade, Backdrop } from "@mui/material";
import CustomButton from "../../../controls/CustomButton";

const TemplateConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title = "Confirm Delete",
  description = "Are you sure you want to delete the selected items?",
  confirmButtonText = "Delete",
  cancelButtonText = "Cancel",
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    textAlign: "center",
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="template-confirmation-box">
          <h2 id="delete-modal-title">{title}</h2>
          <p id="delete-modal-description">{description}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <CustomButton label={cancelButtonText} action={onClose} />

            <CustomButton label={confirmButtonText} action={onConfirm} />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TemplateConfirmationModal;

import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import useTitanApp from "../../../../../hooks/useTitanApp";
import OrbitAgencyUserSessionSkeleton from "./OrbitAgencyUserSessionSkeleton";

export default function OrbitAgencyUserSessions() {
  const [rowData, setRowData] = useState([]);
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState;
  const { orbitUserSessions, isUserSessionLoading } = orbitState || {};
  const gridApiRef = useRef(null);
  useEffect(() => {
    if (orbitUserSessions) {
      setRowData(
        orbitUserSessions.map((item) => ({
          earning: item.earning,
          totalTime: item.totalTime,
          today: item.today,
          totalNmsIntervention: item.totalNmsIntervention,
          totalNmsFollowUp: item.totalNmsFollowUp,
        }))
      );
    }
  }, [orbitUserSessions]);

  // Define columns based on the correct data fields
  const columnDefs = [
    {
      headerName: "Earning",
      field: "earning",
      minWidth: 100,
      flex: 1, // Column can grow if there's extra space
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Weekdays",
      field: "today",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Total Time",
      field: "totalTime",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Interventions",
      field: "totalNmsIntervention",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Follow-ups",
      field: "totalNmsFollowUp",
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true,
    },
  ];

  return (
    <div className="orbit_box orbit-ag agency_max-height">
      <div className="orbit_label_alt">
        <p>User Sessions</p>
      </div>
      {isUserSessionLoading ? (
        <OrbitAgencyUserSessionSkeleton />
      ) : orbitUserSessions?.length > 0 ? (
        <div
          className={`orbit-ag-grid ${
            darkMode
              ? "ag-theme-alpine-dark orbit_agency-ag"
              : "ag-theme-alpine orbit_agency-ag"
          }`}
        >
          <AgGridReact
            ref={gridApiRef}
            rowData={rowData}
            columnDefs={columnDefs}
            scrollbarWidth={3}
            onGridReady={(params) => {
              gridApiRef.current = params.api;
              if (isUserSessionLoading) {
                params.api.showLoadingOverlay();
              } else {
                params.api.hideOverlay();
              }
            }}
          />
        </div>
      ) : (
        <div>
          <p className="orbit_bottomNote t-center">No user sessions for now.</p>
        </div>
      )}
    </div>
  );
}

import React from "react";
import useTitanApp from "../../../../hooks/useTitanApp";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrivateService from "../../PrivateService";

export default function ConsultationForm({
  show,
  handleClose,
  handleGetPrivateServices,
}) {
  const { appState } = useTitanApp();
  const { darkMode } = appState;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: darkMode ? "#222" : "#fff",
    // border: "1px solid #b0b0b0",
    boxShadow: 24,
    // borderRadius: '10px',
    padding: "40px 0",
    overflow: "hidden",
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            margin: -1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <PrivateService
          closeModal={handleClose}
          handleGetPrivateServices={handleGetPrivateServices}
        />
      </Box>
    </Modal>
  );
}

import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Skeleton } from "@mui/material";
import "@mui/icons-material/QueryBuilder";
import useTitanApp from "../../../../../hooks/useTitanApp";

const OrbitAgencyUserSessionSkeleton = () => {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  const gridOptions = {
    columnDefs: [
      {
        headerName: "Earning",
        field: "earning",
        minWidth: 100,
        flex: 1, // Column can grow if there's extra space
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Weekdays",
        field: "today",
        minWidth: 120,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Total Time",
        field: "totalTime",
        minWidth: 120,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Interventions",
        field: "totalNmsInterventions",
        minWidth: 150,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Follow-ups",
        field: "totalNmsFollowUp",
        minWidth: 130,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Clinical Checks",
        field: "totalClinicalChecks",
        minWidth: 150,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Rates",
        field: "rates",
        minWidth: 120,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Session Time",
        field: "sessionTime",
        minWidth: 150,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
    ],
    rowData: new Array(20).fill(null), // Creates 15 empty rows
    defaultColDef: {
      resizable: true,
    },
  };

  return (
    <div
      className={`orbit-ag-grid ${
        darkMode
          ? "ag-theme-alpine-dark orbit_agency-ag"
          : "ag-theme-alpine orbit_agency-ag"
      }`}
    >
      <AgGridReact gridOptions={gridOptions} />
    </div>
  );
};

const SkeletonCellRenderer = ({ value }) => {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <Skeleton animation="wave" sx={{ bgcolor: darkMode ? "grey.800" : "" }} />
  );
};

export default OrbitAgencyUserSessionSkeleton;

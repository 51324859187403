import React, { useState } from "react";
import "../assets/prescriptionheader.css";
import ClinicalWarning from "./PrescriptionClinicalWarning";

import CustomLabel from "./controls/CustomLabel";
import { IoDocumentOutline } from "react-icons/io5";
import { getFormattedDate } from "../utils/FormattedDate";
import { treatmentTypes } from "../enum/treatmentType";
import { PrescriptionSourceTypes } from "../enum/prescriptionSourcetype";
import chatIcon from "./chaticon.svg";
import PrescriptionNotesControl from "../components/NotesControl/PrescriptionNotesControl";
import useTitanApp from "../hooks/useTitanApp";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
  setCheckNowModel,
  setIsParked,
} from "../dispatchers/clinicalChecksDispatchers";
import setCheckNow from "../Model/CheckNow/setCheckNow";

function PrescriptionHeader() {
  const { clincicalChecksState } = useTitanApp();

  const {
    prescriptions,
    clinicalChecksNotes,
    checkNowModel,
    isParked,
    selectedBucketType,
    prescriptionChecks,
  } = clincicalChecksState || {};
  const [isNotes, setIsNotes] = useState(false);
  const {
    prescriptionDate,
    numberofItems,
    prescriptionTreatmentType,
    prescriptionSourceType,
    externalSourceBarcode,
  } = prescriptions || {};
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1976d2",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#444",
      boxSizing: "border-box",
    },
  }));

  const dateOfrx = getFormattedDate(prescriptionDate),
    sourceType =
      PrescriptionSourceTypes[
        prescriptionSourceType ? prescriptionSourceType : 0
      ],
    title = `General Prescription- ${sourceType}`,
    treatmenttype = treatmentTypes[prescriptionTreatmentType];

  function setNotes() {
    setIsNotes(!isNotes);
  }

  const handleSwitchChange = (event) => {
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      "",
      "",
      0,
      "",
      false,
      "",
      false,
      0,
      false,
      "",
      false,
      "",
      false,
      "",
      "",
      event,
      selectedBucketType,
      0,
      null,
      false,
      null
    );
    setCheckNowModel(newCheckNowModel);
    setIsParked(event);
  };

  function checkPrescriptionActions() {
    let allMatch = true;
    const prescriptionCheckActions = checkNowModel?.prescriptionCheckActions;
    const filteredChecks = prescriptionChecks.filter(
      (check) => check?.checkSection === 1
    );
    allMatch = filteredChecks.every((check) =>
      prescriptionCheckActions.some(
        (action) =>
          action?.clinicalCheckId === check?.clinicalCheckId &&
          check?.clinicalCheckOptions.some(
            (option) =>
              option?.clinicalCheckOptionId === action?.clinicalCheckOptionId
          )
      )
    );
    return allMatch;
  }
  return (
    <div
      className="parent_template"
      style={{
        borderLeft:
          checkPrescriptionActions() === true
            ? "2px solid #00ca69"
            : "2px solid #E22424",
      }}
    >
      <div className="prescription-card customcard">
        <div
          className="card-step-number"
          style={{
            background:
              checkPrescriptionActions() === true ? "#00ca69" : "#E22424",
          }}
        >
          <h2>1</h2>
        </div>
        <div className="card-actions clinical-checks-first">
          <h2 className="card-heading pres-card">
            <div>
              <IoDocumentOutline />
              {title}
            </div>
          </h2>
          <div className="card-right-actions">
            <div className="d-flex">
              <p>Send to parked</p>
              <AntSwitch
                onChange={(e) => handleSwitchChange(e.target.checked)}
                checked={isParked}
              />
            </div>
            <p>{clinicalChecksNotes?.length}</p>
            <img src={chatIcon} onClick={setNotes} />
          </div>
        </div>
        <div className="card-inner-boxes">
          <div className="titan-prescription">
            <span>
              <CustomLabel name="Date of Rx" />
              <p className="card-inner-value"> {dateOfrx}</p>
            </span>
          </div>
          <div className="titan-prescription">
            <span>
              <CustomLabel name="Prescription Id" />
              <p className="card-inner-value"> {externalSourceBarcode}</p>
            </span>
          </div>
          <div className="titan-prescription">
            {" "}
            <span>
              <CustomLabel name="Number of items" />
              <p className="card-inner-value">{numberofItems}</p>
            </span>
          </div>
          <div className="titan-prescription">
            <span>
              <CustomLabel name="Treatment Type" />
              <p className="card-inner-value">{treatmenttype}</p>
            </span>
          </div>
        </div>
        {isNotes ? <PrescriptionNotesControl /> : <></>}
        {prescriptionChecks?.map((items) => {
          if (items.checkSection === 1) {
            return <ClinicalWarning item={items} />;
          }
        })}
      </div>
    </div>
  );
}

export default PrescriptionHeader;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./../../assets/css/AddTeam.scss";
import AddNewInputField from "./components/AddNewInputField";
import AddNewUploadPhoto from "./components/AddNewUploadPhoto";
import AddNewButtons from "./components/AddNewButtons";
import CustomInputLabel from "./../../components/controls/CustomInputLabel";
import SelectPharmacies from "./components/SelectPharmacies";
import { ReactComponent as GlobeSVG } from "./../../assets/images/globe.svg";
import Avatar from "react-initials-avatar";
import useTitanTeam from "../../hooks/useTitanTeam";
import {
  setAddTeamModal,
  setAddTeamImage,
  setAddTeamTitle,
} from "../../dispatchers/teamDispatchers";

export default function AddTeamModal(props) {
  const { teamState } = useTitanTeam();
  const { addTeamTitle, addTeamImage, addTeamModal } = teamState || "";
  const [showPharmacy, setShowPharmacy] = useState(false);
  const [teamNameError, setTeamNameError] = useState("");
  const [isExceeded, setIsExceeded] = useState(false);

  let pharmacyArray = [];

  const handleOpenPharmacies = () => {
    if (!props.teamsFormik.values.teamName) {
      setTeamNameError("Please enter a team name");
      return;
    } else {
      setAddTeamModal(false);
      setShowPharmacy(true);
    }
  };
  function updateSelectedPharmacies(pharmacies) {
    pharmacyArray = pharmacies;
  }

  function showAddTeamModal(show) {
    setShowPharmacy(false);
    setAddTeamModal(addTeamModal);
  }

  function closePharmaciesModal(show) {
    setShowPharmacy(false);
    props.teamsFormik.values.teamName = "";
    props.teamsFormik.values.displayName = "";
  }
  function setUploadedpicture(image) {
    setAddTeamImage(image);
  }

  const changeHandler = (e) => {
    setIsExceeded(false);
    const { id, value } = e.target;
    if (value.length <= 49) {
      setAddTeamTitle(value);
    } else {
      setIsExceeded(true);
    }
    props.teamsFormik.setFieldValue(id, value);
  };

  return (
    <div>
      <Modal
        show={addTeamModal}
        onHide={() => setAddTeamModal(false)}
        backdrop="static"
        centered
        dialogClassName="addNewModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="addNew_header">
          <div className="addNew_iconBox">
            <div className="team_icon-box label-btn">
              {addTeamImage ? (
                <img src={addTeamImage} alt="team-badge" />
              ) : (
                <Avatar name={addTeamTitle} className="initials" />
              )}
            </div>
          </div>
          <div>
            <h5>{addTeamTitle ? addTeamTitle : "Global View"}</h5>
          </div>
        </div>
        <form onSubmit={props.teamsFormik.handleSubmit}>
          <CustomInputLabel name="Team Name" />
          <AddNewInputField
            id="teamName"
            name="teamName"
            type="teamName"
            placeholder="Please enter a team"
            value={props.teamsFormik.values.teamName}
            onBlur={props.teamsFormik.handleBlur}
            onChange={changeHandler}
            error={
              props.teamsFormik.touched.teamName &&
              Boolean(props.teamsFormik.errors.teamName)
            }
            helperText={
              props.teamsFormik.touched.teamName &&
              props.teamsFormik.errors.teamName
            }
          />
          {props.teamsFormik.touched.teamName &&
            props.teamsFormik.errors.teamName ? (
            <div className="error-message">
              {props.teamsFormik.errors.teamName}
            </div>
          ) : null}
          {isExceeded && (
            <p className="max-word-limit">
              *Your character limit has been reached
            </p>
          )}

          <CustomInputLabel name="Upload photo" />
          <AddNewUploadPhoto
            onDarkModeChange={props.onDarkModeChange}
            handleCallback={setUploadedpicture}
          />

          <AddNewButtons text="Next" onClick={handleOpenPharmacies} />
        </form>
      </Modal>

      <Modal
        show={showPharmacy}
        onHide={() => setShowPharmacy(false)}
        backdrop="static"
        centered
        dialogClassName="addNewPharmacySelection"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="pharmacy-wrapper">
          <SelectPharmacies
            displayName={props.teamsFormik.values.displayName}
            teamName={props.teamsFormik.values.teamName}
            teamImage={addTeamImage}
            onDarkModeChange={props.onDarkModeChange}
            handleCloseCallBack={showAddTeamModal}
            handleClosePharmaciesCallBack={closePharmaciesModal}
            handleCallback={props.handleCallback}
            clickedTeamIndex={props.clickedTeamIndex}
          />
        </div>
      </Modal>
    </div>
  );
}

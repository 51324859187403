import React from "react";
import { ReactComponent as CalenderIcon } from "./../../../../../../src/assets/images/calender-icon.svg";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../../../../hooks/useTitanApp";

export default function OrbitAgencyInterventions() {
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState;
  const { isOtherDetailsLoading, otherDetails } = orbitState || {};
  const { intervention } = otherDetails || {};
  return (
    <div className="orbit_box">
      <div className="orbit_label">
        <CalenderIcon />
        <p>Intervention</p>
      </div>
      {isOtherDetailsLoading ? (
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={50}
          animation="wave"
          variant="rounded"
          style={{ marginTop: "10px" }}
        />
      ) : (
        <p className="orbit_value">{intervention}</p>
      )}
    </div>
  );
}

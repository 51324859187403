import { queryByAltText } from "@testing-library/react";
import prescriptionItemCheckActions from "./prescriptionItemCheckActions";

class prescriptionItems {
  constructor(
    response,

    PrescriptionItemCheckActionsObj,
    PrescriptionItemId,
    qty,
    dose,
    isUpdate,
    patientMedicationId,
    isActionUpdate,
    ItemCrossedOut,
    NotDispensedReasonCode,
    isNotes,
    Notesmodel,
    EndorseModel,
    qualifiedService,
    uomCode,
    isUpdatedUom
  ) {
    this.obj = response?.map((value) => {
      return {
        prescriptionItemId: value.prescriptionItemId,
        parentPrescriptionItemId: value.parentPrescriptionItemId,
        quantity:
          isUpdate === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? parseInt(qty)
              : value.quantity
            : value.quantity,

        prescribedQuantity: value.prescribedQuantity,
        unitOfMeasureCode:
          isUpdatedUom === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? uomCode
              : value.unitOfMeasureCode
            : value.unitOfMeasureCode,
        dosageText:
          isUpdate === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? dose
              : value.dosageText
            : value.dosageText,

        additionalDosage: value.additionalDosage,
        runningTotal: value.runningTotal,
        allocationType: value.allocationType,
        positionId: value.positionId,
        itemCrossedOut:
          isActionUpdate === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? ItemCrossedOut
              : value.itemCrossedOut
            : value.itemCrossedOut,
        notDispensedReasonCode:
          isActionUpdate === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? NotDispensedReasonCode
              : value.notDispensedReasonCode
            : value.notDispensedReasonCode,
        prescriptionItemLabelWarnings: value.prescriptionItemLabelWarnings,
        epsLineItem: value.epsLineItem,
        epsLineItemStatus: value.epsLineItemStatus,
        drugWrittenAs: value.drugWrittenAs
          ? value.drugWrittenAs
          : value.originalPrescribedDrugName,
        prescriptionId: value.prescriptionId,
        prescribedItemId: value.prescribedItemId,
        prescribedItemSspId: value.prescribedItemSspId,
        cdSchedule: value.cdSchedule,
        numberOfCharges: value.numberOfCharges,
        chargesAmount: value.chargesAmount,
        drugTariffPrice: value.drugTariffPrice,
        dispensingFee: value.dispensingFee,
        markupPercent: value.markupPercent,
        finalFee: value.finalFee,
        prescriptionMedicationId:
          isUpdate === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? patientMedicationId
                ? patientMedicationId
                : value.prescriptionMedicationId
              : value.prescriptionMedicationId
            : value.prescriptionMedicationId,

        prescriptionItemCheckActions:
          PrescriptionItemCheckActionsObj !== ""
            ? value.prescriptionItemId === PrescriptionItemId
              ? new prescriptionItemCheckActions(
                  PrescriptionItemCheckActionsObj,
                  value.prescriptionItemCheckActions,
                  PrescriptionItemId
                ).obj
              : value.prescriptionItemCheckActions
            : value.prescriptionItemCheckActions,

        qualifiedService:
          qualifiedService === 1 ? qualifiedService : value.qualifiedService,
        originalDosageText: value.originalDosageText,
        aiDoseText: value.aiDoseText,
        isParent: value.isParent,
        additionalEndorsements:
          EndorseModel !== ""
            ? value.prescriptionItemId === PrescriptionItemId
              ? EndorseModel
              : value.additionalEndorsements
            : value.additionalEndorsements,
        prescriptionItemEmergency: value.prescriptionItemEmergency,
        prescriptionItemSchedules: value.prescriptionItemSchedules,
        notes:
          isNotes === true
            ? value.prescriptionItemId === PrescriptionItemId
              ? Notesmodel
              : value.notes
            : value.notes,

        needCheck: value.needCheck,
        isDirty: value.isDirty,
      };
    });
  }
}

export default prescriptionItems;

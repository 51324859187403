import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useTitanApp from "../../../../hooks/useTitanApp";
import useTitanTemplate from "../../../../hooks/useTitanTemplate";

const PreviewEmailTemplate = ({ show, handleClose, emailContent }) => {
  const { appState } = useTitanApp();
  const { templateState } = useTitanTemplate();
  const { darkMode } = appState || {};
  const { emailSubject } = templateState || {};

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: darkMode ? '#222' : '#f2f2f2',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
  };

  const parseContent = (content) => {
    // Wrap placeholders in a styled span to show as placeholders
    const color = darkMode ? '#fff' : '#000';
    return content.replace(/\[([^\]]+)\]/g, (match) => {
      return `<span style="color: ${color}; font-weight: bold;">${match}</span>`;
    });
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            margin: -1
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            width: "70%",
            padding: "20px",
            paddingBottom: "5px !important",
            border: "1px solid #ddd",
            marginTop: "20px",
            margin: "0 auto",
            borderRadius: "10px",
            backgroundColor: darkMode ? '#000' : '#fff',
            fontSize: "14px",
            color: darkMode ? '#f2f2f2' : '#333',
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="email-template-main-wrapper">
            <div className="template-header">
              <h6 className="template-title-content">Invatech Pharmacy</h6>
            </div>
            <div className="template-title">
              <h6 className="template-title-content">
                {emailSubject}
              </h6>
            </div>
            <div className="template-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: parseContent(emailContent).replace(
                    /\n/g,
                    "<br>"
                  ),
                }}
              />
            </div>
            <div className="template-footer">
              <p>
                Invatech Pharmacy, Bristol BS57SE, UK
              </p>
              <p className="copyright">Copyright © {new Date().getFullYear()}</p>
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreviewEmailTemplate;
import React, { useState } from "react";
import { TextField } from "@mui/material";
import useTitanApp from "../../../../../../../../../../hooks/useTitanApp";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import { setGeneralData } from "../../../../../../../../../../dispatchers/typeDispatchers";
export default function OnlineBookingTypesGeneral() {
  const { appState } = useTitanApp();
  const { typeState } = useTitanType();
  const { clinicalServiceBuilderModel, defaultClinicalServiceBuilderModel } =
    typeState;
  // Safely access clinicalServiceBuilderResponse and nested properties

  // Safely access onlineBookings and general details
  const generalDetails =
    clinicalServiceBuilderModel?.clinicalServiceBuilder?.onlineBookings
      ?.general ??
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder?.onlineBookings
      ?.general ??
    {};

  const [cancellationNotice, setCancellationNotice] = useState(
    generalDetails?.cancellationsNotice ?? ""
  );
  const [bookingFrequency, setBookingFrequency] = useState(
    generalDetails?.bookingsFrequency ?? ""
  );
  const [confirmationText, setConfirmationText] = useState(
    generalDetails?.confirmationText ?? ""
  );
  const [minimumNoticeForBooking, setMinimumNoticeForBooking] = useState(
    generalDetails?.minimumNoticeForBooking ?? ""
  );
  const [numberOfWeeksDisplayed, setNumberOfWeeksDisplayed] = useState(
    generalDetails?.numberOfWeeksDisplayed ?? ""
  );

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === "cancellationNotice") {
      setCancellationNotice(value);
    } else if (name === "bookingFrequency") {
      setBookingFrequency(value);
    } else if (name === "confirmationText") {
      setConfirmationText(value);
    } else if (name === "minimumNoticeForBooking") {
      setMinimumNoticeForBooking(value);
    } else if (name === "numberOfWeeksDisplayed") {
      setNumberOfWeeksDisplayed(value);
    }
    setGeneralData({
      cancellationsNotice:
        name === "cancellationNotice" ? value : cancellationNotice,
      bookingsFrequency: name === "bookingFrequency" ? value : bookingFrequency,
      confirmationText: name === "confirmationText" ? value : confirmationText,
      minimumNoticeForBooking:
        name === "minimumNoticeForBooking" ? value : minimumNoticeForBooking,
      numberOfWeeksDisplayed:
        name === "numberOfWeeksDisplayed" ? value : numberOfWeeksDisplayed,
    });
  };

  return (
    <div className="privateservices_two_layout">
      <div className="settings_formFields d-flex">
        <TextField
          id="cancellation-notice"
          label="Minimum notice for cancellations"
          variant="outlined"
          name="cancellationNotice"
          fullWidth
          value={cancellationNotice}
          onChange={handleTextFieldChange}
        />

        {/* <TextField
          id="booking-frequency"
          label="Frequency of bookings"
          variant="outlined"
          name="bookingFrequency"
          fullWidth
          value={bookingFrequency}
          onChange={handleTextFieldChange}
        /> */}

        <TextField
          id="minimum-notice-for-booking"
          label="Minimum notice for booking"
          variant="outlined"
          name="minimumNoticeForBooking"
          fullWidth
          value={minimumNoticeForBooking}
          onChange={handleTextFieldChange}
        />

        <TextField
          id="number-of-weeks-displayed"
          label="Number of weeks displayed to patient"
          variant="outlined"
          name="numberOfWeeksDisplayed"
          fullWidth
          value={numberOfWeeksDisplayed}
          onChange={handleTextFieldChange}
        />
      </div>

      {/* <div className="mt-15">
        <TextField
          id="confirmation-text"
          label="Confirmation text (displayed in browser)"
          variant="outlined"
          name="confirmationText"
          fullWidth
          value={confirmationText}
          onChange={handleTextFieldChange}
        />
      </div> */}
    </div>
  );
}

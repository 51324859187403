import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Switch from "@mui/material/Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { FaCopy } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { BsTrash3 } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import useTitanApp from "../../../../../../../../../../hooks/useTitanApp";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import {
  deleteSlot,
  addOrUpdateSlot,
} from "../../../../../../../../../../dispatchers/typeDispatchers";
import AvailableTimes from "./AvailableTimes";

function ProductTypeSchedules() {
  const { appState } = useTitanApp();
  const { typeState } = useTitanType();

  const {
    clinicalServiceBuilderModel,
    defaultClinicalServiceBuilderModel,
    isTemplateVerified,
    isTypeLoading,
  } = typeState || {};

  const [addedSlots, setAddedSlots] = useState([]);
  useEffect(() => {
    const availability =
      clinicalServiceBuilderModel?.clinicalServiceBuilder?.product
        ?.availability || [];
    setAddedSlots(availability);
  }, [clinicalServiceBuilderModel]);
  function handleAddedSlots(data) {
    setAddedSlots(data);
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1976d2",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#444",
      boxSizing: "border-box",
    },
  }));
  const startTimeArray = [
    { name: "startTime", value: "09:00" },
    { name: "startTime", value: "10:00" },
    { name: "startTime", value: "11:00" },
    { name: "startTime", value: "12:00" },
    { name: "startTime", value: "13:00" },
    { name: "startTime", value: "14:00" },
    { name: "startTime", value: "15:00" },
    { name: "startTime", value: "16:00" },
    { name: "startTime", value: "17:00" },
  ];

  const endTimeArray = [
    { name: "endTime", value: "09:00" },
    { name: "endTime", value: "10:00" },
    { name: "endTime", value: "11:00" },
    { name: "endTime", value: "12:00" },
    { name: "endTime", value: "13:00" },
    { name: "endTime", value: "14:00" },
    { name: "endTime", value: "15:00" },
    { name: "endTime", value: "16:00" },
    { name: "endTime", value: "17:00" },
    { name: "endTime", value: "18:00" },
  ];

  const copyDuration = (dayOfWeek, index) => {
    // Find the current day and the slot to be copied
    const currentDay = addedSlots.find((day) => day?.dayOfWeek === dayOfWeek);
    const copiedSlot = { ...currentDay.slots[index] };

    // Update the local state
    const updatedSlots = addedSlots.map((day) => {
      if (day?.dayOfWeek === dayOfWeek) {
        return {
          ...day,
          slots: [...day?.slots, copiedSlot], // Add the copied slot
        };
      }
      return day;
    });

    handleAddedSlots(updatedSlots); // Update local state

    // Update Redux state
    const updatedDay = updatedSlots.find((day) => day.dayOfWeek === dayOfWeek);
    addOrUpdateSlot(dayOfWeek, updatedDay.slots); // Dispatch Redux action
  };

  const addDuration = (dayOfWeek) => {
    const availability = [...(addedSlots || [])]; // Create a shallow copy of the array
    const existingIndex = availability.findIndex(
      (slot) => slot.dayOfWeek === dayOfWeek
    );

    if (existingIndex !== -1) {
      // Create a new slots array and update the specific day
      const updatedSlots = [
        ...availability[existingIndex].slots,
        { startTime: "", endTime: "" },
      ];
      availability[existingIndex] = {
        ...availability[existingIndex],
        slots: updatedSlots,
      };
    } else {
      // Add a new day with default slots
      availability.push({ dayOfWeek, slots: [{ startTime: "", endTime: "" }] });
    }

    handleAddedSlots(availability); // Update local state
    addOrUpdateSlot(dayOfWeek, availability[existingIndex]?.slots || []); // Dispatch Redux action
  };

  const handleDropdownChange = (e, index, name, selectedDayOfWeek) => {
    const { value } = e?.target || {};

    // Update local state
    const updatedArray = addedSlots.map((item) => {
      if (item?.dayOfWeek === selectedDayOfWeek) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (i === index) {
              return {
                ...slot,
                [name]: value, // Update startTime or endTime
              };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    handleAddedSlots(updatedArray); // Update local state

    // Update Redux state
    const updatedDay = updatedArray.find(
      (item) => item.dayOfWeek === selectedDayOfWeek
    );
    addOrUpdateSlot(selectedDayOfWeek, updatedDay.slots); // Dispatch Redux action
  };

  const ChangeWeeklySlots = (e, dayOfWeek) => {
    if (e.target.checked) {
      const newDay = {
        dayOfWeek,
        slots: [{ startTime: "09:00", endTime: "18:00" }],
      };
      handleAddedSlots([...addedSlots, newDay]); // Update local state
      addOrUpdateSlot(dayOfWeek, newDay.slots); // Dispatch Redux action
    } else {
      const filteredSlots = addedSlots.filter(
        (slot) => slot.dayOfWeek !== dayOfWeek
      );
      handleAddedSlots(filteredSlots); // Update local state
      deleteSlot(dayOfWeek, null); // Dispatch Redux action to remove the entire day
    }
  };

  const filterEndTimeOptions = (slot) => {
    return endTimeArray.filter((time) => time.value > slot?.startTime);
  };

  const deleteDuration = (dayOfWeek, indexToDelete) => {
    const availability = [...(addedSlots || [])]; // Create a shallow copy of the array
    const existingIndex = availability.findIndex(
      (slot) => slot.dayOfWeek === dayOfWeek
    );

    if (existingIndex !== -1) {
      const updatedSlots = availability[existingIndex].slots.filter(
        (_, idx) => idx !== indexToDelete
      ); // Create a new slots array without the removed item

      // Update the specific day's slots immutably
      availability[existingIndex] = {
        ...availability[existingIndex],
        slots: updatedSlots,
      };
    }

    handleAddedSlots(availability); // Update local state
    deleteSlot(dayOfWeek, indexToDelete); // Dispatch Redux action
  };
  return (
    <div className="serviceSchedulesContainer">
      <div className="schedule">
        <Accordion
          className="schedule-accordian"
          expanded={addedSlots?.some((slot) =>
            slot.dayOfWeek === 1 ? true : false
          )}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer membersSchedule_header">
              <div>
                <div className="switch">
                  <AntSwitch
                    checked={addedSlots?.some((slot) => slot?.dayOfWeek === 1)}
                    onChange={(e) => ChangeWeeklySlots(e, 1)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
                <div>
                  <p className="day">Monday</p>
                </div>
              </div>
              <AvailableTimes addedSlots={addedSlots} currentDay={1} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="scheduledetails">
              {addedSlots
                ?.filter((item) => item?.dayOfWeek === 1)
                ?.map((day) => day?.slots)
                ?.map((item) => {
                  return item?.map((slot, index) => {
                    return (
                      <div className="duration" key={index}>
                        <Dropdown
                          value={slot?.startTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "startTime", 1)
                          }
                          options={startTimeArray}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="until-text">
                          <p>until</p>
                        </div>
                        <Dropdown
                          value={slot?.endTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "endTime", 1)
                          }
                          options={filterEndTimeOptions(slot)}
                          optionLabel="value"
                          className="scheduleDropdown"
                          placeholder="Select time"
                        />
                        <div className="icons-container">
                          {index === item?.length - 1 ? (
                            <>
                              {item?.length < 3 && (
                                <BsPlusCircle
                                  className="addIcon"
                                  onClick={() => addDuration(1)}
                                />
                              )}
                              {item?.length < 3 && (
                                <FaCopy
                                  className="copyIcon"
                                  onClick={() => copyDuration(1, index)}
                                />
                              )}
                            </>
                          ) : (
                            <BsTrash3
                              className="trashIcon"
                              onClick={() => deleteDuration(1, index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="schedule-accordian"
          expanded={addedSlots?.some((slot) =>
            slot.dayOfWeek === 2 ? true : false
          )}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer membersSchedule_header">
              <div>
                <div className="switch">
                  <AntSwitch
                    checked={addedSlots?.some((slot) => slot?.dayOfWeek === 2)}
                    onChange={(e) => ChangeWeeklySlots(e, 2)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
                <div>
                  <p className="day">Tuesday</p>
                </div>
              </div>
              <AvailableTimes addedSlots={addedSlots} currentDay={2} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="scheduledetails">
              {addedSlots
                ?.filter((item) => item?.dayOfWeek === 2)
                ?.map((day) => day?.slots)
                ?.map((item) => {
                  return item?.map((slot, index) => {
                    return (
                      <div className="duration" key={index}>
                        <Dropdown
                          value={slot?.startTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "startTime", 2)
                          }
                          options={startTimeArray}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="until-text">
                          <p>until</p>
                        </div>
                        <Dropdown
                          value={slot?.endTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "endTime", 2)
                          }
                          options={filterEndTimeOptions(slot)}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="icons-container">
                          {index === item?.length - 1 ? (
                            <>
                              {item?.length < 3 && (
                                <BsPlusCircle
                                  className="addIcon"
                                  onClick={() => addDuration(2)}
                                />
                              )}
                              {item?.length < 3 && (
                                <FaCopy
                                  className="copyIcon"
                                  onClick={() => copyDuration(2, index)}
                                />
                              )}
                            </>
                          ) : (
                            <BsTrash3
                              className="trashIcon"
                              onClick={() => deleteDuration(2, index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="schedule-accordian"
          expanded={addedSlots?.some((slot) =>
            slot.dayOfWeek === 3 ? true : false
          )}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer membersSchedule_header">
              <div>
                <div className="switch">
                  <AntSwitch
                    checked={addedSlots?.some((slot) => slot?.dayOfWeek === 3)}
                    onChange={(e) => ChangeWeeklySlots(e, 3)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>

                <div>
                  <p className="day">Wednesday</p>
                </div>
              </div>
              <AvailableTimes addedSlots={addedSlots} currentDay={3} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="scheduledetails">
              {addedSlots
                ?.filter((item) => item?.dayOfWeek === 3)
                ?.map((day) => day?.slots)
                ?.map((item) => {
                  return item?.map((slot, index) => {
                    return (
                      <div className="duration" key={index}>
                        <Dropdown
                          value={slot?.startTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "startTime", 3)
                          }
                          options={startTimeArray}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="until-text">
                          <p>until</p>
                        </div>
                        <Dropdown
                          value={slot?.endTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "endTime", 3)
                          }
                          options={filterEndTimeOptions(slot)}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="icons-container">
                          {index === item?.length - 1 ? (
                            <>
                              {item?.length < 3 && (
                                <BsPlusCircle
                                  className="addIcon"
                                  onClick={() => addDuration(3)}
                                />
                              )}
                              {item?.length < 3 && (
                                <FaCopy
                                  className="copyIcon"
                                  onClick={() => copyDuration(3, index)}
                                />
                              )}
                            </>
                          ) : (
                            <BsTrash3
                              className="trashIcon"
                              onClick={() => deleteDuration(3, index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="schedule-accordian"
          expanded={addedSlots?.some((slot) =>
            slot.dayOfWeek === 4 ? true : false
          )}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer membersSchedule_header">
              <div>
                <div className="switch">
                  <AntSwitch
                    checked={addedSlots?.some((slot) => slot?.dayOfWeek === 4)}
                    onChange={(e) => ChangeWeeklySlots(e, 4)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
                <div>
                  <p className="day">Thursday</p>
                </div>
              </div>
              <AvailableTimes addedSlots={addedSlots} currentDay={4} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="scheduledetails">
              {addedSlots
                ?.filter((item) => item?.dayOfWeek === 4)
                ?.map((day) => day?.slots)
                ?.map((item) => {
                  return item?.map((slot, index) => {
                    return (
                      <div className="duration" key={index}>
                        <Dropdown
                          value={slot?.startTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "startTime", 4)
                          }
                          options={startTimeArray}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="until-text">
                          <p>until</p>
                        </div>
                        <Dropdown
                          value={slot?.endTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "endTime", 4)
                          }
                          options={filterEndTimeOptions(slot)}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="icons-container">
                          {index === item?.length - 1 ? (
                            <>
                              {item?.length < 3 && (
                                <BsPlusCircle
                                  className="addIcon"
                                  onClick={() => addDuration(4)}
                                />
                              )}
                              {item?.length < 3 && (
                                <FaCopy
                                  className="copyIcon"
                                  onClick={() => copyDuration(4, index)}
                                />
                              )}
                            </>
                          ) : (
                            <BsTrash3
                              className="trashIcon"
                              onClick={() => deleteDuration(4, index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="schedule-accordian"
          expanded={addedSlots?.some((slot) =>
            slot.dayOfWeek === 5 ? true : false
          )}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer membersSchedule_header">
              <div>
                <div className="switch">
                  <AntSwitch
                    checked={addedSlots?.some((slot) => slot?.dayOfWeek === 5)}
                    onChange={(e) => ChangeWeeklySlots(e, 5)}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
                <div>
                  <p className="day">Friday</p>
                </div>
              </div>
              <AvailableTimes addedSlots={addedSlots} currentDay={5} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="scheduledetails">
              {addedSlots
                ?.filter((item) => item?.dayOfWeek === 5)
                ?.map((day) => day?.slots)
                .map((item) => {
                  return item?.map((slot, index) => {
                    return (
                      <div className="duration" key={index}>
                        <Dropdown
                          value={slot?.startTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "startTime", 5)
                          }
                          options={startTimeArray}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="until-text">
                          <p>until</p>
                        </div>
                        <Dropdown
                          value={slot?.endTime}
                          onChange={(e) =>
                            handleDropdownChange(e, index, "endTime", 5)
                          }
                          options={filterEndTimeOptions(slot)}
                          optionLabel="value"
                          placeholder="Select time"
                          className="scheduleDropdown"
                        />
                        <div className="icons-container">
                          {index === item?.length - 1 ? (
                            <>
                              {item?.length < 3 && (
                                <BsPlusCircle
                                  className="addIcon"
                                  onClick={() => addDuration(5)}
                                />
                              )}
                              {item?.length < 3 && (
                                <FaCopy
                                  className="copyIcon"
                                  onClick={() => copyDuration(5, index)}
                                />
                              )}
                            </>
                          ) : (
                            <BsTrash3
                              className="trashIcon"
                              onClick={() => deleteDuration(5, index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={true}
          className="schedule-accordian holiday-accordian"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer">
              <div className="switch">
                <AntSwitch
                  onChange={(e) => ChangeWeeklySlots(e, 6)}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </div>
              <div>
                <p className="day">
                  <s>Saturday</s>
                </p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
        <Accordion
          disabled={true}
          className="schedule-accordian holiday-accordian"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="accordianHeaderContainer">
              <div className="switch">
                <AntSwitch
                  onChange={(e) => ChangeWeeklySlots(e, 0)}
                  inputProps={{ "aria-label": "ant design" }}
                />
              </div>
              <div>
                <p className="day">
                  <s>Sunday</s>
                </p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default ProductTypeSchedules;

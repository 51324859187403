import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import useTitanApp from "../../../../../hooks/useTitanApp";
import OrbitPharmanautSkeleton from "./OrbitPharmanautSkeleton";
import { setSidebarVisible } from "../../../../../dispatchers/orbitDispatchers";
import { setSelectedRowData } from "../../../../../dispatchers/orbitDispatchers";
import {
  getOrbitAgnecyUserByEmail,
  getOrbitAgencyServicesByCompanyId,
} from "../../../../../dispatchers/orbitDispatchers";
import Avatar from "react-initials-avatar";

function getRowHeight(params) {
  return params.node.data.name.services.length > 0 ? 75 : 40;
}

function OrbitPharmanautList() {
  const [rowData, setRowData] = useState([]);
  const { appState, orbitState, onboardingVerificationState } = useTitanApp();
  const { userData } = onboardingVerificationState || {};
  const { companyID } = userData;
  const { darkMode } = appState;
  const { orbitAgencyUsers, orbitAgencyLoader, isUserSessionLoading } =
    orbitState || {};
  const gridApiRef = useRef(null);

  const gridOptions = {
    getRowClass: (params) => (params?.node?.isSelected ? "selected-row" : ""),
    getRowHeight: getRowHeight,
    onCellClicked: (event) => {
      handleRowClick(event.data); // Pass clicked row data to the handler
    },
  };
  useEffect(() => {
    if (orbitAgencyUsers && orbitAgencyUsers.length > 0) {
      const formattedData = orbitAgencyUsers.map((user) => ({
        name: {
          avatar: "https://example.com/default-avatar.jpg",
          name: user.UserFullName,
          services: user.Services || [], // Add services array for tags
        },
        email: user.EmailAddress,
        status:
          user.UserStatus === 2
            ? "Active"
            : user.UserStatus === 1
            ? "Inactive"
            : "Offline",
        hoursOnline: `${user.HoursOnline}`,
        avgTime: `${user.AvgTime}`,
        contribution: `${user.Contribution}%`,
        completed: `${user.Completed}%`,
      }));
      setRowData(formattedData);
    }
  }, [orbitAgencyUsers]);

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      width: 200,
      cellRenderer: (params) => {
        const avatarName = params.value?.name || "N/A";
        const services = params.value?.services || [];

        return (
          <div className="pharmanaut-list-name">
            <div className="pharmanaut-av-main">
              <div className="pharmanaut-avatar">
                {/* Ensure Avatar component is rendered with a valid name */}
                <Avatar name={avatarName} size={40} fontSize={18} />
              </div>
              <span>
                <p className="pharmanaut-name-value">{avatarName}</p>
              </span>
            </div>
            <div className="pharmanauts-tags">
              {/* Safely render the services if available */}
              {services.map((service, index) => (
                <div className="pharmanaut-tag" key={index}>
                  {service?.ServiceName || "Unnamed Service"}
                </div>
              ))}
            </div>
          </div>
        );
      },
      suppressMovable: true,
    },
    {
      headerName: "Status",
      field: "status",
      width: 115,
      cellRenderer: (params) => {
        let color;
        if (params?.value === "Active") color = "green";
        else if (params?.value === "Inactive") color = "yellow";
        else color = "red"; // Offline
        return (
          <div className="pharmanaut-status">
            <span
              style={{
                backgroundColor: color,
              }}
              className="pharmanaut-status-indicator"
            ></span>
            <p className="pharmanaut-list-value">{params?.value}</p>
          </div>
        );
      },
      suppressMovable: true,
    },
    {
      headerName: "Hours Online",
      field: "hoursOnline",
      width: 150,
      cellRenderer: (params) => {
        return (
          <p className="pharmanaut-list-value">
            {params?.value ? params.value : "00h 00m"}
          </p>
        );
      },
      suppressMovable: true,
    },
    {
      headerName: "Avg Time",
      field: "avgTime",
      width: 130,
      cellRenderer: (params) => {
        return (
          <p className="pharmanaut-list-value">
            {params?.value ? params.value : "00h 00m"}
          </p>
        );
      },
      suppressMovable: true,
    },
    {
      headerName: "Contribution",
      field: "contribution",
      width: 130,
      cellRenderer: (params) => (
        <p className="pharmanaut-list-value">{params?.value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Completed",
      field: "completed",
      width: 120,
      cellRenderer: (params) => (
        <p className="pharmanaut-list-value">{params?.value}</p>
      ),
      suppressMovable: true,
    },
  ];

  const handleRowClick = (rowData) => {
    const { email } = rowData;
    getOrbitAgnecyUserByEmail({ email });
    getOrbitAgencyServicesByCompanyId({ companyID });
    setSidebarVisible(true);
    setSelectedRowData(rowData);
  };

  return (
    <div className="orbit_box orbit-ag orbit-pharmanaut">
      <div className="orbit_label_alt">
        <p>Pharmanauts</p>
      </div>
      {orbitAgencyLoader ? (
        <OrbitPharmanautSkeleton />
      ) : orbitAgencyUsers?.length > 0 ? (
        <div
          className={`orbit-pharmanaut-ag-grid ${
            darkMode
              ? "ag-theme-alpine-dark orbit_agency-ag"
              : "ag-theme-alpine orbit_agency-ag"
          }`}
        >
          <AgGridReact
            ref={gridApiRef}
            rowData={rowData}
            columnDefs={columnDefs}
            scrollbarWidth={3}
            gridOptions={gridOptions}
            onGridReady={(params) => {
              gridApiRef.current = params.api;
              if (orbitAgencyLoader) {
                params.api.showLoadingOverlay();
              } else {
                params.api.hideOverlay();
              }
            }}
          />
        </div>
      ) : (
        <div>
          <p className="orbit_bottomNote t-center">No data available</p>
        </div>
      )}
    </div>
  );
}

export default OrbitPharmanautList;

import React, { useState } from "react";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { getTimeLessDate } from "../../../../Dashboard/ServicesTab/ServicesContainer/ServicesAnalytics/components/DateRangePicker";
import LoadingComponentMonthlyReport from "../../../../../components/LoadingComponentOrbit/LoadingComponentMonthlyReport";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { getOrbitMonthlyReport } from "../../../../../dispatchers/orbitDispatchers";
import { Skeleton } from "@mui/material";
import { MonthlyReportShape } from "../../../../../assets/images/monthlyReportShape";

export default function OrbitAgencyMonthlyReport() {
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState || {};
  const { monthlyReport, isMonthlyReportLoading } = orbitState || {};
  const currentDate = moment();
  const firstDayOfMonth = moment(currentDate).startOf("month");
  const lastDayOfMonth = moment(currentDate).endOf("month");
  const startDate = firstDayOfMonth.format("YYYY-MM-DDTHH:mm:ss");
  const endDate = lastDayOfMonth.format("YYYY-MM-DDTHH:mm:ss");
  const [dates, setDates] = useState([
    getTimeLessDate(startDate),
    getTimeLessDate(endDate),
  ]);
  const handleCalendarChange = async (event) => {
    const selectedDates = event?.value;
    if (selectedDates?.length === 2) {
      setDates(selectedDates);
      if (selectedDates[0] !== null && selectedDates[1] !== null) {
        const tzoffset = new Date().getTimezoneOffset() * 60000;
        let localISOStartTime = new Date(selectedDates[0] - tzoffset);
        let localISOEndTime = new Date(selectedDates[1] - tzoffset);
        localISOStartTime = moment(localISOStartTime).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        localISOEndTime = moment(localISOEndTime).format("YYYY-MM-DDTHH:mm:ss");
        getOrbitMonthlyReport({
          startDate: localISOStartTime,
          endDate: localISOEndTime,
        });
      }
    }
  };
  return (
    <div className="orbit_box monthly_report agency_monthly_report">
      <div className="orbit_box-container agency_monthly_report-container">
        <div className="orbit_label_alt">
          <p>Monthly report</p>
          {isMonthlyReportLoading ? (
            <Skeleton
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
              height={20}
              width={20}
              animation="wave"
              variant="rounded"
            />
          ) : (
            <div className="date-range-picker-container">
              <div className="date-range-picker analytics-tab-date-picker">
                <Calendar
                  value={dates}
                  onChange={handleCalendarChange}
                  selectionMode="range"
                  dateFormat="dd/mm/yy"
                  readOnlyInput
                  showButtonBar
                  showIcon
                />
              </div>
            </div>
          )}
        </div>
        <div className="content">
          <div className="monthlyReport_boxes agency_monthlyReport_boxes">
            <div>
              <p>Total Sessions</p>
              {isMonthlyReportLoading ? (
                <Skeleton
                  sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                  height={18}
                  width={70}
                  animation="wave"
                  variant="rounded"
                />
              ) : (
                <p>{monthlyReport?.totalOnline}</p>
              )}
            </div>
            <div>
              <p>Clinical Checks</p>
              {isMonthlyReportLoading ? (
                <Skeleton
                  sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                  height={18}
                  width={30}
                  animation="wave"
                  variant="rounded"
                />
              ) : (
                <p>0</p>
              )}
            </div>
            <div>
              <p>Total Services</p>
              {isMonthlyReportLoading ? (
                <Skeleton
                  sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                  height={18}
                  width={30}
                  animation="wave"
                  variant="rounded"
                />
              ) : (
                <p>{monthlyReport?.totalProcessed}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="lower">
        <MonthlyReportShape />
      </div>
    </div>
  );
}

import React from "react";
import { Dropdown } from "primereact/dropdown";
import CustomLabel from "../controls/CustomLabel";

const PrescribedItemEdit = ({
  buttonClass,
  quantityValue,
  doseValue,
  medicineValues,
  isFixUOM,
  uomOptionsValue,
  uomValue,
  isTherapy,
  handleDropdownChange,
  changeitemQuantity,
  changeitemDose,
}) => {
  return (
    <>
      {buttonClass ? (
        <>
          <div className="outer-boxes">
            <div className="outer-item">
              <CustomLabel name="Quantity" />
              <input
                className={"card-inner-value quantity-box"}
                type="text"
                disabled={false}
                value={quantityValue}
                onChange={changeitemQuantity}
              />
            </div>
          </div>
          <div className="outer-boxes">
            <div className="outer-item">
              <CustomLabel name="Dose" />
              <input
                className={"card-inner-value quantity-box"}
                type="text"
                disabled={false}
                value={doseValue}
                onChange={changeitemDose}
              />
            </div>
            <div className="outer-item">
              <CustomLabel name="Measure" />
              {isFixUOM === false ? (
                <input
                  className={"card-inner-value quantity-box"}
                  type="text"
                  disabled={false}
                  value={medicineValues.unitText || ""}
                />
              ) : (
                <Dropdown
                  onChange={(e) => handleDropdownChange(e)}
                  options={uomOptionsValue}
                  value={uomValue}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="outer-boxes">
            <div className="outer-item">
              <CustomLabel name="Quantity" />
              {buttonClass ? (
                <input
                  className={"card-inner-value quantity-box"}
                  type="text"
                  disabled={isTherapy}
                  value={quantityValue}
                  onChange={changeitemQuantity}
                />
              ) : (
                <label className="custom-label custom-label-value">{`${quantityValue} ${medicineValues.unitText}`}</label>
              )}
            </div>
            <div className="outer-item">
              <CustomLabel name="Dose" />
              <CustomLabel name={doseValue} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PrescribedItemEdit;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import "../../../src/assets/css/TherapyModel.scss";
import {
  setTheraphyModal,
  getPostMedication,
} from "../../dispatchers/clinicalChecksDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import CoreAcoordion from "../PrescribedMedicines/CoreAccordion";
import ScheduleAccordion from "../PrescribedMedicines/ScheduleAccordion";
import MdsAccordion from "../PrescribedMedicines/MdsAccordion";
import ReportsAccordion from "../PrescribedMedicines/ReportsAccordion";
import setAddMedication from "../../Model/AddMedication/setAddMedication";
import CustomButton from "../controls/CustomButton";
import { RxCross2 } from "react-icons/rx";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props || {};
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  boxShadow: 24,
  padding: "16px",
};

function onClose() {
  setTheraphyModal(false);
}

export default function TherapyModalComponent(props) {
  const [medicineName, setMedicineName] = useState("");

  const { clincicalChecksState, appState } = useTitanApp();
  const {
    isTherapyModal,
    therapyComponents,
    defaultDosageTimings,
    patientId,
    therapyModelContents,

    clinicalChecksPharmacy,
  } = clincicalChecksState || {};
  const { darkMode } = appState;

  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  const { medicineValues, itemInfo, prescribedItemId } =
    therapyModelContents || {};

  const [patientMedicationTherapy, setPatientMedicationTherapy] =
    useState(null);

  useEffect(() => {
    if (therapyModelContents) {
      if (medicineValues) {
        setMedicineName(medicineValues?.prescribedItem.writtenAs);
      }
      const therapyObj = {
        quantity: medicineValues ? medicineValues.quantity : "",
        dose: medicineValues ? medicineValues?.dosageText : "",
        therapyType: itemInfo ? itemInfo.therapyType : 0,
        startDate: "2024-01-01",
        stopDate: "2024-01-01",
        archive: itemInfo ? itemInfo.neverAutoArchive : false,
        preparationType: itemInfo ? itemInfo.preparationType : 0,
        marking: itemInfo ? itemInfo.marking : "",
        color: itemInfo ? itemInfo.colour : 0,
        report: itemInfo ? itemInfo.showOnReport : false,
        prescribedItemId: prescribedItemId,
        prescriptionMedicationId: medicineValues?.prescriptionMedicationId,
        patientId: patientId,
        patientMedicationTime: itemInfo
          ? itemInfo.patientMedicationTime
          : defaultDosageTimings,
      };
      setPatientMedicationTherapy(therapyObj);
    }
  }, [therapyModelContents, patientId, defaultDosageTimings]);

  const changeTherapyData = (therapyData) => {
    setPatientMedicationTherapy(therapyData);
  };

  if (!patientMedicationTherapy) {
    return null; // Render nothing until the therapy object is initialized
  }

  async function addTherapy(isInterim) {
    if (
      patientMedicationTherapy?.quantity !== "" &&
      patientMedicationTherapy?.dose !== ""
    ) {
      const prescriptionCheckBody = new setAddMedication(
        itemInfo,
        patientMedicationTherapy,
        patientMedicationTherapy?.patientMedicationTime,
        isInterim
      ).obj;
      await getPostMedication({ prescriptionCheckBody, pharmacyID });
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={isTherapyModal}
        closeAfterTransition
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        // slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent default close on backdrop click
        }}
      >
        <Fade in={isTherapyModal}>
          <Box sx={style} className="endorsement-box">
            <div className="additional-div">
              <div className="card-actions">
                <h5> {medicineName}</h5>
                <RxCross2
                  color={darkMode ? "#fff" : "#0a0a0a"}
                  size={20}
                  className="collapse-icon"
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>
            </div>
            {therapyComponents.includes(1) && (
              <CoreAcoordion
                medicineValues={therapyModelContents?.medicineValues}
                patientMedicationTherapy={patientMedicationTherapy}
                changeTherapyData={changeTherapyData}
              />
            )}
            {therapyComponents.includes(2) && (
              <ScheduleAccordion
                medicineValues={therapyModelContents?.medicineValues}
                patientMedicationTherapy={patientMedicationTherapy}
                changeTherapyData={changeTherapyData}
              />
            )}
            {therapyComponents.includes(4) && (
              <MdsAccordion
                medicineValues={therapyModelContents?.medicineValues}
                patientMedicationTherapy={patientMedicationTherapy}
                changeTherapyData={changeTherapyData}
              />
            )}
            {therapyComponents.includes(5) && (
              <ReportsAccordion
                medicineValues={therapyModelContents?.medicineValues}
                patientMedicationTherapy={patientMedicationTherapy}
                changeTherapyData={changeTherapyData}
              />
            )}
            <div className="buttons-bar-en">
              <CustomButton
                label="Cancel"
                action={(event) => {
                  onClose();
                }}
              />
              <CustomButton
                label="Save"
                action={(event) => {
                  addTherapy("");
                  setTheraphyModal(false);
                }}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

TherapyModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

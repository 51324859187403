import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { Checkbox, Skeleton } from "@mui/material";
import { HiLockClosed } from "react-icons/hi2";
import { TbCalendarTime } from "react-icons/tb";
import DatePicker from "react-datepicker";
import PatientTag from "../PatientTag";
import PatientTelephone from "../PatientTelephone";
import SentSmsText from "../SentSmsText";
import ServiceMedication from "../ServiceMedication";
import {
  getStartDateForStages,
  getTotalAttemptsForStages,
  getTotalDaysLeft,
  getAge,
  isDateBeforeMin,
} from "../../../../../data/getStartDateForStages";
import {
  getClinicalServiceByClinicalServiceId,
  getTravelInformation,
} from "../../../../../dispatchers/nmsDispatchers";
import useTitanApp from "../../../../../hooks/useTitanApp";
import commonData from "../../../../../data/commonData";
import {
  getUserLog,
  setCurrentPharmacyServices,
  setEligibilityStageScrollPosition,
  setInterventionStageScrollPosition,
  setOrbitClinicalServiceId,
  setScrollPosition,
  setSentToOrbit,
  updateCalendarEventByClinicalServiceId,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import { isSmsSent } from "../../../../../data/getKanbanAndListViewData";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import ServicesCheckbox from "../ServicesCheckbox";
import ServiceKanbanDate from "./ServiceKanbanDate";
import PatientConsent from "../PatientConsent";
import { toast } from "react-toastify";
import { showError } from "../../../../../components/ToastedMessage/ToastedMessage";

const ServiceItemList = ({
  service,
  stage,
  index,
  group,
  smsQueue,
  reSmsQueue,
  interventionQueue,
  declineQueue,
  rescheduleQueue,
  pdsQueue,
  orbitQueue,
  servicesUpdatedData,
  setCurrentSmsQueue,
  setCurrentReSmsQueue,
  setCurrentInterventionQueue,
  setCurrentDeclineQueue,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentOrbitQueue,
  checksCount,
  setChecksCount,
  setShowPatientContactModal,
  setEligibilityScroll,
  setContactServiceID,
}) => {
  // set and initialize component
  const [newestDateTime, setNewestDateTime] = useState("");

  const {
    pharmacyServiceState,
    appState,
    getTimelineDataForClinicalServiceId,
  } = useTitanApp();
  const navigate = useNavigate();
  const { darkMode } = appState || {};
  const { isPharmacyServiceLoading, pharmacyServices } = pharmacyServiceState;
  const { serviceIcons } = commonData || {};
  const hasConsent = group.some((item) => item?.isConsent);
  const serviceIcon = serviceIcons[service.serviceName];
  const parsedDate = getStartDateForStages(service, stage?.stageName);
  const totalAttempts = getTotalAttemptsForStages(service, stage?.stageName);
  const clinicalServiceId = service?._id;
  const serviceType = service?.serviceType;
  const stageType = stage?.stageType;
  const updated = servicesUpdatedData?.includes(clinicalServiceId);
  const patientName = service?.patientName;
  const patientDob = service?.dob;
  const isDateMinBefore = isDateBeforeMin(patientDob);
  const patientAge = getAge(service?.dob);
  const patientTags = service?.patientTags;
  const patientTelephoneNumber = service?.telephoneNumber;
  const serviceMedication = service?.serviceMedications;

  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };

  const handleClick = async (clinicalServiceId, isLocked, service) => {
    if (serviceType === 2) {
      navigate(`/privateservice/${clinicalServiceId}`);
    } else {
      if (isLocked) {
        setOrbitClinicalServiceId(clinicalServiceId);
        setSentToOrbit(true);
      } else {
        getUserLog(clinicalServiceId).then((data) => {
          if (!data?.isActive) {
            navigate(`/nmsintervention/${clinicalServiceId}`, {
              state: {
                serviceType: "Kanban",
              },
            });
          }
        });
      }
    }
  };

  const handleDateDivClick = (e) => {
    e.stopPropagation();
  };

  const handleChange = (newDate, index) => {
    if (newestDateTime !== "") {
      const firstDateTime = moment(newDate).format("HH:mm:ss");
      const updatedSecondDate = moment(newestDateTime)
        .set({
          hour: moment(firstDateTime, "HH:mm:ss").hour(),
          minute: moment(firstDateTime, "HH:mm:ss").minute(),
          second: moment(firstDateTime, "HH:mm:ss").second(),
        })
        .toDate();
      setNewestDateTime(updatedSecondDate);
    } else {
      setNewestDateTime(newDate);
    }
  };

  const handleCalendarClose = (clinicalServiceId) => {
    if (newestDateTime !== "") {
      const parsedDate = moment(
        newestDateTime,
        "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)"
      );
      const formattedDateTime = parsedDate.format("YYYY-MM-DDTHH:mm:ss") + "Z";
      const formattedDateTimeToReplace = parsedDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      let stageNo;
      switch (stage?.stageType) {
        case 1:
          stageNo = 0;
          break;
        case 2:
          stageNo = 1;
          break;
        case 3:
          stageNo = 2;
          break;
        case 4:
          stageNo = 3;
          break;
      }

      if (formattedDateTime !== "") {
        updateCalendarEventByClinicalServiceId({
          formattedDateTime,
          clinicalServiceId,
          stageNo,
        }).then((data) => {
          try {
            const newData = pharmacyServices?.map((stage) => ({
              ...stage,
              sortedServices: stage?.sortedServices?.map((service) => ({
                ...service,
                clinicalServices: service?.clinicalServices.map(
                  (clinicalService) => ({
                    ...clinicalService,
                    stages: clinicalService?.stages.map((stage) => ({
                      ...stage,
                    })),
                  })
                ),
              })),
            }));

            newData?.forEach((stage) => {
              stage?.sortedServices?.forEach((serviceGroup) => {
                serviceGroup?.clinicalServices?.forEach((clinicalService) => {
                  if (clinicalService._id === clinicalServiceId) {
                    let stageFound = false;
                    clinicalService.stages = clinicalService?.stages?.map(
                      (stage) => {
                        if (
                          !stageFound &&
                          stage.clinicalServiceStageStatus === 0
                        ) {
                          stageFound = true;
                          return {
                            ...stage,
                            startDate: formattedDateTimeToReplace,
                          };
                        }
                        return stage;
                      }
                    );
                  }
                });
              });
            });
            showToast("Record has been rescheduled successfully!");
            setCurrentPharmacyServices(newData);
            const stagesWrapper = document.querySelector(".stages-wrapper");
            if (stagesWrapper) {
              setScrollPosition(stagesWrapper.scrollLeft);
            }
            const stageEligible = document.getElementById("stage_Eligible");
            if (stageEligible) {
              setEligibilityStageScrollPosition(stageEligible.scrollTop);
            }
            const stageIntervention =
              document.getElementById("stage_Intervention");
            if (stageIntervention) {
              setInterventionStageScrollPosition(stageIntervention.scrollTop);
            }
          } catch (error) { }
        });
      } else {
        showError("Something went wrong!");
      }
    }
  };

  return (
    <div
      className="services_list"
      onClick={() =>
        handleClick(clinicalServiceId, service?.sendToOrbit, service)
      }
    >
      {isPharmacyServiceLoading ? (
        <div className="service_item_skeleton">
          <Skeleton
            sx={{
              bgcolor: darkMode ? "grey.900" : "",
            }}
            variant="rounded"
            height={95}
          />
        </div>
      ) : (
        <div
          className={`service_item ${updated ? "kanban-success-border" : ""}`}
        // style={{
        //   width:
        //     pharmacyServices?.length === 1
        //       ? "100%"
        //       : pharmacyServices?.length === 2
        //       ? "100%"
        //       : pharmacyServices?.length > 2
        //       ? "430px"
        //       : "",
        // }}
        >
          <div className="name-box">
            <div className="checkbox_name">
              <ServicesCheckbox
                view={"kanban"}
                stageType={stage.stageType}
                service={service}
                smsQueue={smsQueue}
                reSmsQueue={reSmsQueue}
                interventionQueue={interventionQueue}
                declineQueue={declineQueue}
                rescheduleQueue={rescheduleQueue}
                pdsQueue={pdsQueue}
                orbitQueue={orbitQueue}
                setCurrentSmsQueue={setCurrentSmsQueue}
                setCurrentReSmsQueue={setCurrentReSmsQueue}
                setCurrentInterventionQueue={setCurrentInterventionQueue}
                setCurrentDeclineQueue={setCurrentDeclineQueue}
                setCurrentRescheduleQueue={setCurrentRescheduleQueue}
                setCurrentPdsQueue={setCurrentPdsQueue}
                setCurrentOrbitQueue={setCurrentOrbitQueue}
                checksCount={checksCount}
                setChecksCount={setChecksCount}
              />
              <p className="services_kanban-serviceName">
                {patientName}
                {patientDob !== null && isDateMinBefore
                  ? ` (${patientAge})`
                  : ""}
              </p>
            </div>
            <div className="lock_days">
              {service?.sendToOrbit && (
                <span>
                  <HiLockClosed size={15} />
                </span>
              )}
              <span className="services_listview-date">
                {getTotalDaysLeft(service, stage?.stageName)}
              </span>
            </div>
          </div>
          <div className="service_tags_list">
            {patientTags?.map((tag, index) => (
              <PatientTag key={index} tag={tag} index={index} />
            ))}
          </div>
          {patientTelephoneNumber === null || patientTelephoneNumber === "" ? (
            <PatientTelephone
              id={clinicalServiceId}
              index={index}
              setShowPatientContactModal={setShowPatientContactModal}
              setEligibilityScroll={setEligibilityScroll}
              setContactServiceID={setContactServiceID}
            />
          ) : (
            <></>
          )}
          <PatientConsent
            group={group}
            service={service}
            stageType={stageType}
          />
          <div className="text">
            <p className="attempts">
              {totalAttempts !== 0 && totalAttempts + " attempts"}
            </p>
            {stageType === 2 && isSmsSent(clinicalServiceId) ? (
              <SentSmsText id={clinicalServiceId} />
            ) : (
              <></>
            )}
          </div>
          {serviceMedication?.map((medications, index) => (
            <ServiceMedication key={index} medications={medications} />
          ))}
          <div className="services_bottom">
            <div
              className="services_listview-service date-time-select"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="services_iconboxService">
                <TbCalendarTime size={15} color={darkMode ? "#fff" : "#000"} />
                <DatePicker
                  className="widgetDatePicker"
                  onChange={(newDate) => handleChange(newDate, index)}
                  showTimeSelect
                  selected={parsedDate}
                  timeIntervals={15}
                  dateFormat="dd.MM HH:mm"
                  onCalendarClose={() => handleCalendarClose(service?._id)}
                />
              </div>
            </div>
            <div className="services_listview-service">
              <div className="services_iconboxService">
                {/* <div className="services_icon">{serviceIcon}</div> */}
                <span className="service_service_name">
                  {service?.serviceName}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ServiceItemList);

class setFindPatient {
  constructor(patient) {
    this.NHSNumber = patient?.nhsNumber;
    this.Surname = patient?.surname;
    this.Dob = this.formatDate(patient?.dob);
    this.Sex = patient?.sex;
    this.PatientType = "0";
    this.Postcode = patient?.preferredAddress.address.postcode;
  }

  formatDate(dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}

export default setFindPatient;

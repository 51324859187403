import React, { useState, useEffect } from "react";
import CustomLabel from "../controls/CustomLabel";
import SearchableDropdown from "./SearchableDropdown";
import "./../../assets/css/AddPrescriptionItem.scss";
import { inputTypes } from "../../enum/inputTypes";
import CustomDropdown from "../controls/CustomDropdown";
import { drugNames } from "../../data/drugNames";
import TextField from "@mui/material/TextField";
import { ReactComponent as IconSVG } from "./../../assets/images/icon-drug.svg";
import { medAboutData, medData } from "../../data/psMedicineData";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoCopyOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";
import { IoIosWarning } from "react-icons/io";
import { getDrugInformation } from "../../dispatchers/nmsDispatchers";
import DrugSkeleton from "./DrugSkeleton";

function AddPrescriptionItem({
  template,
  number,
  handleChangeModel,
  handleRemove,
  active,
}) {
  const { appState, nmsServiceState } = useTitanApp();
  const { drugsData, drugsAboutData, isDrugInformationLoading } =
    nmsServiceState || {};
  const { darkMode } = appState || {};
  const { userControls, sectionTitle } = template || {};
  useEffect(() => {
    setInputValues(userControls);
    const updatedObj = drugNames.filter(
      (item) => item.drugName === sectionTitle
    );
    setData(...updatedObj);
  }, [userControls]);
  const [inputValues, setInputValues] = useState(userControls);
  const [infoMessage, setInfoMessage] = useState(false);
  const [value, setValue] = useState("");
  const [data, setData] = useState();

  const handleFormChange = async (
    index,
    event,
    type,
    selectedVal,
    medicationData
  ) => {
    let value;
    if (index === 0) {
      value = selectedVal;
    } else {
      value = event?.target?.value;
    }
    if (medicationData?.prescribedItemId) {
      var response = await getDrugInformation(medicationData?.prescribedItemId);
      if (response !== null) {
        setInfoMessage(true);
      }
    }
    await setUserControl(index, value);

    const UserControlObj = inputValues;

    handleChangeModel(
      selectedVal === null ? sectionTitle : selectedVal,
      UserControlObj,
      active,
      index == 0 ? medicationData : null
    );
  };
  const setUserControl = async (index, value) => {
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[index].value = value;
      return newInputValues;
    });
  };
  function handleMedicineName(val) {
    setValue(val?.drugName);
    handleFormChange(0, null, 1, val?.drugName, val);
  }

  return (
    <div
      className="parent_template ps-add-template"
      style={{
        borderLeft: "2px solid #00ca69",
      }}
    >
      <div className="medicine-card customcard">
        <div
          className="card-step-number"
          style={{
            background: "#00ca69",
          }}
        >
          <h2>{number}</h2>
        </div>
        <div className="card-content ps-add-item">
          <div className="card-actions nms-title">
            <div className="d-flex">
              <IconSVG class />
              <h2 class="medicine-card-heading">Medicine</h2>
            </div>
            <button
              onClick={() =>
                handleRemove(value === "" ? sectionTitle : value, 98, 99)
              }
            >
              <svg
                width="20"
                height="23"
                viewBox="0 0 15 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 3.25C9 2.42157 8.32843 1.75 7.5 1.75C6.67157 1.75 6 2.42157 6 3.25H5C5 1.86929 6.11929 0.75 7.5 0.75C8.88071 0.75 10 1.86929 10 3.25H14.5C14.7761 3.25 15 3.47386 15 3.75C15 4.02614 14.7761 4.25 14.5 4.25H13.946L12.6499 15.4792C12.5335 16.4884 11.679 17.25 10.6631 17.25H4.33688C3.321 17.25 2.4665 16.4884 2.35006 15.4792L1.053 4.25H0.5C0.25454 4.25 0.0503916 4.07312 0.00805569 3.83988L0 3.75C0 3.47386 0.223858 3.25 0.5 3.25H9ZM12.938 4.25H2.061L3.34347 15.3646C3.40169 15.8692 3.82894 16.25 4.33688 16.25H10.6631C11.1711 16.25 11.5983 15.8692 11.6565 15.3646L12.938 4.25ZM6 6.75C6.24546 6.75 6.44961 6.90477 6.49194 7.10886L6.5 7.1875V13.3125C6.5 13.5541 6.27614 13.75 6 13.75C5.75454 13.75 5.55039 13.5952 5.50806 13.3911L5.5 13.3125V7.1875C5.5 6.94588 5.72386 6.75 6 6.75ZM9 6.75C9.24546 6.75 9.44961 6.90477 9.49194 7.10886L9.5 7.1875V13.3125C9.5 13.5541 9.27614 13.75 9 13.75C8.75454 13.75 8.55039 13.5952 8.50806 13.3911L8.5 13.3125V7.1875C8.5 6.94588 8.72386 6.75 9 6.75Z"
                  className="icons-color"
                />
              </svg>
            </button>
          </div>
          <div className="outer-boxes">
            <div className="outer-item not-disp-reason">
              <CustomLabel name="Prescribed medicine" />
              <SearchableDropdown
                searchType="drug"
                label="name"
                id="id"
                selectedVal={value === "" ? sectionTitle : value}
                handleChange={handleMedicineName}
                options={drugNames}
              />
            </div>
          </div>
          <div className="outer-boxes">
            <div className="template_control">
              <TextField
                className="template_input text_input_field"
                id="outlined-required"
                disabled={false}
                type="number"
                value={inputValues[2]?.value}
                label="Quantity"
                onChange={(event) =>
                  handleFormChange(
                    2,
                    event,
                    inputTypes[inputValues[2].type],
                    null
                  )
                }
                inputProps={{ maxLength: 5000 }}
              />
            </div>
          </div>
          <div className="outer-boxes">
            <div className="template_control">
              <TextField
                className="template_input text_input_field"
                id="outlined-required"
                disabled={false}
                type="text"
                label="Directions"
                value={inputValues[1]?.value}
                onChange={(event) =>
                  handleFormChange(
                    1,
                    event,
                    inputTypes[inputValues[1].type],
                    null
                  )
                }
                inputProps={{ maxLength: 5000 }}
              />
            </div>
          </div>

          {isDrugInformationLoading ? (
            <>
              <DrugSkeleton />
            </>
          ) : (
            <>
              {drugsData.length > 0 ? (
                <div className="age-presc">
                  {drugsData.map((data, index) => (
                    <div className="presc" key={index}>
                      <p className="title">{data.type}</p>
                      <div className="d-flex">
                        <p className="description">{data.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              {drugsAboutData.length > 0 ? (
                <div className="about-presc">
                  {drugsAboutData.map((data) => (
                    <div className="about">
                      <div className="d-flex">
                        <IoIosWarning color="#3EF420" />
                        <p className="title">{data.type}</p>
                      </div>
                      <p>{data.description}</p>
                    </div>
                  ))}
                </div>
              ) : (
                infoMessage === true && (
                  <div className="error-msg">
                    <p>Medicine information is not available!</p>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddPrescriptionItem;

import React, { useEffect } from "react";
import CustomLabel from "../controls/CustomLabel";
import "./../../assets/css/MetaDataSection.scss";
function MetaDataSection({
  SectionTitle,
  UserControls,
  boxStyling,
  number,
  icon,
  darkModeChange,
}) {
  const theme = {
    color: `${darkModeChange === false ? "#000" : "#fff"}`,
  };

  useEffect(() => {});

  return (
    <div className="medicine-card customcard">
      <div className="card-step-number">
        <h2>{number}</h2>
      </div>
      <div className="card-content">
        <div className="card-actions nms-title">
          <img src="" />
          <h2 className="medicine-card-heading" style={theme}>
            {SectionTitle}
          </h2>
        </div>
        <div className="mds-inner-boxes">
          {UserControls?.map((item, index) => {
            return (
              <div
                key={index}
                style={boxStyling(UserControls?.length, index)}
                className="box-element"
              >
                <CustomLabel name={item.label} style={theme} />
                <p>{item.value}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MetaDataSection;

import React, { useRef, useState } from "react";
import LoadingComponentNMSPatient from "../../../../components/LoadingComponentNMS/LoadingComponentNMSPatient";
import CustomLabel from "../../../../components/controls/CustomLabel";
import useTitanApp from "../../../../hooks/useTitanApp";
import { Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as healthTravelInfo from "./../../../../enum/healthTravelInfoEnums";
import CustomButton from "../../../../components/controls/CustomButton";
import { MdAdd } from "react-icons/md";

export default function PrivateServiceTravelInfo({ AddVaccine }) {
  const { nmsServiceState, appState, auth } = useTitanApp();
  const { user } = auth || {};
  const userFullName = user?.name || "";
  const { darkMode } = appState || {};
  const { isNmsLoading, travelInfo, NmsModel, value } = nmsServiceState || {};
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [expandTempAccordian, setexpandTempAccordian] = useState(false);
  const [mtExpandedAccordion, setMtExpandedAccordion] = useState(false);
  const [arExpandedAccordion, setArExpandedAccordion] = useState(false);
  const mapRef = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: 33.713,
    longitude: 73.1615,
    zoom: 12,
  });
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };
  const handleTempAccordionChange = (panel) => (event, isExpanded) => {
    setexpandTempAccordian(isExpanded ? panel : false);
  };
  const handleAccordionChangeMT = (panel) => (event, isExpanded) => {
    setMtExpandedAccordion(isExpanded ? panel : false);
  };
  const handleAccordionChangeAR = (panel) => (event, isExpanded) => {
    setArExpandedAccordion(isExpanded ? panel : false);
  };

  const destination = travelInfo[0]?.destinationSummary?.countryName;
  const capital = travelInfo[0]?.destinationSummary?.capital;
  const languages = travelInfo[0]?.destinationSummary?.languages;
  const general = travelInfo[0]?.destinationSummary?.generalHealthAdvice;

  const infectiousDiseases =
    travelInfo[0]?.latestHealthTravelInformation?.infectiousDiseases;
  const foodWaterSafety =
    travelInfo[0]?.latestHealthTravelInformation?.foodWaterSafety;
  const insectBornIllnesses =
    travelInfo[0]?.latestHealthTravelInformation?.insectBorneIllnesses;
  const healthCareAccess =
    travelInfo[0]?.latestHealthTravelInformation?.healthcareAccess;
  const climateRisks =
    travelInfo[0]?.latestHealthTravelInformation?.climateRelatedRisks;
  const mostTravellers = travelInfo[0]?.vaccineRecommendations?.mostTravelers;
  const additionalRecommendations =
    travelInfo[0]?.vaccineRecommendations?.additionalRecommendations;
  const insectBorneDiseases =
    travelInfo[0]?.otherHealthRisks?.insectBorneDiseases;
  const waterBorneDiseases =
    travelInfo[0]?.otherHealthRisks?.waterborneDiseases;
  const foodBorneIllnesses =
    travelInfo[0]?.otherHealthRisks?.foodborneIllnesses;
  const climateRelatedRisksOther =
    travelInfo[0]?.otherHealthRisks?.climateRelatedRisks;
  const additionalRisks = travelInfo[0]?.otherHealthRisks?.additionalRisks;
  return isNmsLoading ? (
    <LoadingComponentNMSPatient />
  ) : (
    <div className="content-div patient-info-container private-service-travel-info">
      <div className="general-inner-boxes">
        {travelInfo?.length !== 0 ? (
          <>
            {travelInfo?.length > 1 ? (
              <>
                {travelInfo?.map((travel, index) => {
                  return (
                    <div key={index}>
                      <Accordion
                        expanded={expandTempAccordian === `panel${index}`}
                        onChange={handleTempAccordionChange(`panel${index}`)}
                        className="nms_info_accordion tag-row"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className="heading"
                        >
                          <div className="header">
                            <p>{travel?.countryName}</p>
                          </div>
                        </AccordionSummary>
                        <>
                          <div className="genaral_pds">
                            <h2 className="card-heading">General</h2>
                          </div>
                          <div className="general-info grids-2">
                            <div className="travel-info-box">
                              <span>
                                <CustomLabel name="Destination" />
                                <p className="card-inner-value">
                                  {travel.destinationSummary?.countryName}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="general-info">
                            <div className="travel-info-box">
                              <span>
                                <CustomLabel name="Capital City" />
                                <p className="card-inner-value name">
                                  {travel.destinationSummary?.capital}
                                </p>
                              </span>
                            </div>
                            <div className="travel-info-box">
                              <span>
                                <CustomLabel name="Languages" />
                                <p className="card-inner-value">
                                  {travel.destinationSummary?.languages}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="general-info grids-2 general">
                            <div className="travel-info-box">
                              <span>
                                <CustomLabel name="General" />
                                <p className="card-inner-value">
                                  {
                                    travel.destinationSummary
                                      ?.generalHealthAdvice
                                  }
                                </p>
                              </span>
                            </div>
                          </div>

                          {/* <div className="general-info grids-2 general map">
                        <div className="info-box">
                          <img src={mapImage} alt="map" />
                        </div>
                      </div> */}

                          <div className="container-separate">
                            <div className="genaral_pds">
                              <h2 className="card-heading">
                                Latest Information
                              </h2>
                            </div>
                            <Accordion
                              onChange={handleAccordionChange("panel1")}
                              defaultExpanded={true}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Infectious Diseases</p>
                                  <p className="risk-level">high</p>
                                </div>
                              </AccordionSummary>
                              {travel.latestHealthTravelInformation?.infectiousDiseases?.map(
                                (disease, index) => (
                                  <div className="acc-content" key={index}>
                                    <p className="lbl">{disease.disease}</p>
                                    <p className="description">
                                      {disease?.precautions}
                                    </p>
                                    <p>Risk Level: {disease.risk_level}</p>
                                  </div>
                                )
                              )}
                            </Accordion>
                            <Accordion
                              onChange={handleAccordionChange("panel2")}
                              defaultExpanded={true}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Food and Water Safety</p>
                                  <p className="risk-level">high</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.latestHealthTravelInformation
                                      ?.foodWaterSafety?.concerns
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.latestHealthTravelInformation
                                      ?.foodWaterSafety?.precautions
                                  }
                                </p>
                              </div>
                            </Accordion>
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel3")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Insect Borne Illnesses</p>
                                </div>
                              </AccordionSummary>
                              {travel.latestHealthTravelInformation?.insectBorneIllnesses?.map(
                                (disease, index) => (
                                  <div className="acc-content" key={index}>
                                    <p className="description">{disease}</p>
                                  </div>
                                )
                              )}
                            </Accordion>
                            <Accordion
                              onChange={handleAccordionChange("panel4")}
                              className="nms_info_accordion tag-row"
                              defaultExpanded={true}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4-content"
                                id="panel4-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Healthcare Access</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.latestHealthTravelInformation
                                      ?.healthcareAccess?.quality
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.latestHealthTravelInformation
                                      ?.healthcareAccess.precautions
                                  }
                                </p>
                              </div>
                            </Accordion>{" "}
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel5")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5-content"
                                id="panel5-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Climate Risks</p>
                                  <p className="risk-level">high</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.latestHealthTravelInformation
                                      ?.climateRelatedRisks.risks
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.latestHealthTravelInformation
                                      ?.climateRelatedRisks.precautions
                                  }
                                </p>
                              </div>
                            </Accordion>
                          </div>

                          <div className="container-separate">
                            <div className="genaral_pds">
                              <h2 className="card-heading">
                                Vaccine Recommendations (Most Travelers)
                              </h2>
                            </div>
                            {travel.vaccineRecommendations?.mostTravelers?.map(
                              (mt, index) => (
                                <Accordion
                                  key={index}
                                  defaultExpanded={true}
                                  onChange={handleAccordionChangeMT(index)}
                                  className="nms_info_accordion tag-row"
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-content"
                                    id="panel-header"
                                    className="heading"
                                  >
                                    <div className="header">
                                      <p>{mt.vaccine}</p>
                                    </div>
                                    <div>
                                      <MdAdd
                                        size={20}
                                        className="member_edit_icon add_vaccine_icon"
                                        onClick={() => {
                                          AddVaccine(mt?.vaccine);
                                        }}
                                      />
                                    </div>
                                  </AccordionSummary>
                                  <div className="acc-content">
                                    <p className="description">
                                      {mt?.description}
                                    </p>
                                  </div>
                                </Accordion>
                              )
                            )}
                          </div>

                          <div className="container-separate">
                            <div className="genaral_pds">
                              <h2 className="card-heading">
                                Vaccine Recommendations (Others)
                              </h2>
                            </div>
                            {travel.vaccineRecommendations?.additionalRecommendations?.map(
                              (ar, index) => (
                                <Accordion
                                  key={index}
                                  defaultExpanded={true}
                                  onChange={handleAccordionChangeAR(index)}
                                  className="nms_info_accordion tag-row"
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-content"
                                    id="panel-header"
                                    className="heading"
                                  >
                                    <div className="header">
                                      <p>{ar.vaccine}</p>
                                    </div>
                                    <div>
                                      <MdAdd
                                        size={20}
                                        className="member_edit_icon add_vaccine_icon"
                                        onClick={() => {
                                          AddVaccine(ar?.vaccine);
                                        }}
                                      />
                                    </div>
                                  </AccordionSummary>
                                  <div className="acc-content">
                                    <p className="description">
                                      {ar?.description}
                                    </p>
                                  </div>
                                </Accordion>
                              )
                            )}
                          </div>

                          <div className="container-separate">
                            <div className="genaral_pds">
                              <h2 className="card-heading">Other Risks</h2>
                            </div>
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel6")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6-content"
                                id="panel6-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Insect Borne Diseases</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.insectBorneDiseases
                                      .description
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.insectBorneDiseases
                                      .prevention
                                  }
                                </p>
                              </div>
                            </Accordion>
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel7")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel7-content"
                                id="panel7-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Water Borne Diseases</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.waterborneDiseases
                                      .description
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.waterborneDiseases
                                      .prevention
                                  }
                                </p>
                              </div>
                            </Accordion>
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel8")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel8-content"
                                id="panel8-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Water Borne Diseases</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.foodborneIllnesses
                                      ?.description
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.foodborneIllnesses
                                      ?.prevention
                                  }
                                </p>
                              </div>
                            </Accordion>
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel9")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel9-content"
                                id="panel9-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Climate Related Risks</p>
                                </div>
                              </AccordionSummary>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.climateRelatedRisks
                                      ?.description
                                  }
                                </p>
                              </div>
                              <div className="acc-content">
                                <p className="description">
                                  {
                                    travel.otherHealthRisks?.climateRelatedRisks
                                      .prevention
                                  }
                                </p>
                              </div>
                            </Accordion>
                            <Accordion
                              defaultExpanded={true}
                              onChange={handleAccordionChange("panel10")}
                              className="nms_info_accordion tag-row"
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel10-content"
                                id="panel10-header"
                                className="heading"
                              >
                                <div className="header">
                                  <p>Additional Risks</p>
                                </div>
                              </AccordionSummary>
                              {travel.otherHealthRisks?.additionalRisks?.map(
                                (risk, index) => (
                                  <div className="acc-content" key={index}>
                                    <p className="lbl">{risk.risk}</p>
                                    <p className="description">
                                      {risk?.description}
                                    </p>
                                    <p>Prevention: {risk.prevention}</p>
                                  </div>
                                )
                              )}
                            </Accordion>
                          </div>

                          <div className="travel-info-footer">
                            <CustomButton
                              label={"Print Leaflet"}
                              action={null}
                              className={"ps-btn-blue"}
                            />
                          </div>
                        </>
                      </Accordion>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="genaral_pds">
                  <h2 className="card-heading">General</h2>
                </div>
                <div className="general-info grids-2">
                  <div className="travel-info-box">
                    <span>
                      <CustomLabel name="Destination" />
                      <p className="card-inner-value">{destination}</p>
                    </span>
                  </div>
                </div>
                <div className="general-info">
                  <div className="travel-info-box">
                    <span>
                      <CustomLabel name="Capital City" />
                      <p className="card-inner-value name">{capital}</p>
                    </span>
                  </div>
                  <div className="travel-info-box">
                    <span>
                      <CustomLabel name="Languages" />
                      <p className="card-inner-value">{languages}</p>
                    </span>
                  </div>
                </div>
                <div className="general-info grids-2 general">
                  <div className="travel-info-box">
                    <span>
                      <CustomLabel name="General" />
                      <p className="card-inner-value">{general}</p>
                    </span>
                  </div>
                </div>
                {/* <div className="ps-map-box">
  <div className="map-container">
    <Map
      initialViewState={viewport}
      scrollZoom={true}
      mapStyle={
        darkMode
          ? "mapbox://styles/mapbox/dark-v11"
          : "mapbox://styles/mapbox/light-v11"
      }
      style={{
        width: "100%",
        height: "40vh",
      }}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      attributionControl={false}
    >
      <Marker longitude={73.1615} latitude={33.713}>
        <div className="single-marker">
          <LuMapPin color="#fff" size={20} />
          <p className="pharmacy-name">Islamabad</p>
        </div>
      </Marker>
    </Map>
  </div>
</div> */}
                {/* a */}

                <div className="container-separate">
                  <div className="genaral_pds">
                    <h2 className="card-heading">Latest Information</h2>
                  </div>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel1")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Infectious Diseases</p>
                        <p className="risk-level">high</p>
                      </div>
                    </AccordionSummary>
                    {infectiousDiseases?.map((disease, index) => (
                      <div className="acc-content" key={index}>
                        <p className="lbl">{disease.disease}</p>
                        <p className="description">{disease.precautions}</p>
                        <p>Risk Level: {disease.risk_level}</p>
                      </div>
                    ))}
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel2")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Food and Water Safety</p>
                        <p className="risk-level">high</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">{foodWaterSafety?.concerns}</p>
                    </div>
                    <div className="acc-content">
                      <p className="description">
                        {foodWaterSafety?.precautions}
                      </p>
                    </div>
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel3")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Insect Borne Illnesses</p>
                      </div>
                    </AccordionSummary>
                    {insectBornIllnesses?.map((disease, index) => (
                      <div className="acc-content" key={index}>
                        <p className="description">{disease}</p>
                      </div>
                    ))}
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel4")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4-content"
                      id="panel4-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Healthcare Access</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">{healthCareAccess?.quality}</p>
                    </div>
                    <div className="acc-content">
                      <p className="description">
                        {healthCareAccess?.precautions}
                      </p>
                    </div>
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel5")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5-content"
                      id="panel5-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Climate Risks</p>
                        <p className="risk-level">high</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">{climateRisks?.risks}</p>
                    </div>
                    <div className="acc-content">
                      <p className="description">{climateRisks?.precautions}</p>
                    </div>
                  </Accordion>
                </div>

                <div className="container-separate">
                  <div className="genaral_pds">
                    <h2 className="card-heading">
                      Vaccine Recommendations (Most Travelers)
                    </h2>
                  </div>
                  {mostTravellers?.map((mt, index) => (
                    <Accordion
                      key={index}
                      defaultExpanded={true}
                      onChange={handleAccordionChangeMT(index)}
                      className="nms_info_accordion tag-row"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                        className="heading"
                      >
                        <div className="header">
                          <p>{mt.vaccine}</p>
                        </div>
                        <div>
                          <MdAdd
                            size={20}
                            className="member_edit_icon add_vaccine_icon"
                            onClick={() => {
                              AddVaccine(mt.vaccine);
                            }}
                          />
                        </div>
                      </AccordionSummary>
                      <div className="acc-content">
                        <p className="description">{mt?.description}</p>
                      </div>
                    </Accordion>
                  ))}
                </div>

                <div className="container-separate">
                  <div className="genaral_pds">
                    <h2 className="card-heading">
                      Vaccine Recommendations (Others)
                    </h2>
                  </div>
                  {additionalRecommendations?.map((ar, index) => (
                    <Accordion
                      key={index}
                      defaultExpanded={true}
                      onChange={handleAccordionChangeAR(index)}
                      className="nms_info_accordion tag-row"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                        className="heading"
                      >
                        <div className="header">
                          <p>{ar.vaccine}</p>
                        </div>
                        <div>
                          <MdAdd
                            size={20}
                            className="member_edit_icon add_vaccine_icon"
                            onClick={() => {
                              AddVaccine(ar.vaccine);
                            }}
                          />
                        </div>
                      </AccordionSummary>
                      <div className="acc-content">
                        <p className="description">{ar?.description}</p>
                      </div>
                    </Accordion>
                  ))}
                </div>

                <div className="container-separate">
                  <div className="genaral_pds">
                    <h2 className="card-heading">Other Risks</h2>
                  </div>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel6")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6-content"
                      id="panel6-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Insect Borne Diseases</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">
                        {insectBorneDiseases?.description}
                      </p>
                    </div>
                    <div className="acc-content">
                      <p className="description">
                        {insectBorneDiseases?.prevention}
                      </p>
                    </div>
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel7")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7-content"
                      id="panel7-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Water Borne Diseases</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">
                        {waterBorneDiseases?.description}
                      </p>
                    </div>
                    <div className="acc-content">
                      <p className="description">
                        {waterBorneDiseases?.prevention}
                      </p>
                    </div>
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel8")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8-content"
                      id="panel8-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Water Borne Diseases</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">
                        {foodBorneIllnesses?.description}
                      </p>
                    </div>
                    <div className="acc-content">
                      <p className="description">
                        {foodBorneIllnesses?.prevention}
                      </p>
                    </div>
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel9")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel9-content"
                      id="panel9-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Climate Related Risks</p>
                      </div>
                    </AccordionSummary>
                    <div className="acc-content">
                      <p className="description">
                        {climateRelatedRisksOther?.description}
                      </p>
                    </div>
                    <div className="acc-content">
                      <p className="description">
                        {climateRelatedRisksOther?.prevention}
                      </p>
                    </div>
                  </Accordion>
                  <Accordion
                    defaultExpanded={true}
                    onChange={handleAccordionChange("panel10")}
                    className="nms_info_accordion tag-row"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel10-content"
                      id="panel10-header"
                      className="heading"
                    >
                      <div className="header">
                        <p>Additional Risks</p>
                      </div>
                    </AccordionSummary>
                    {additionalRisks?.map((risk, index) => (
                      <div className="acc-content" key={index}>
                        <p className="lbl">{risk.risk}</p>
                        <p className="description">{risk?.description}</p>
                        <p>Prevention: {risk?.prevention}</p>
                      </div>
                    ))}
                  </Accordion>
                </div>

                <div className="travel-info-footer">
                  <CustomButton
                    label={"Print Leaflet"}
                    action={null}
                    className={"ps-btn-blue"}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <div className="error-msg">
            <p>Travel Information is not available!</p>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Skeleton } from "@mui/material";
import "@mui/icons-material/QueryBuilder";
import useTitanApp from "../../../../../hooks/useTitanApp";
function OrbitPharmanautSkeleton() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  const gridOptions = {
    columnDefs: [
      {
        headerName: "Name",
        field: "Name",
        minWidth: 100,
        flex: 1, // Column can grow if there's extra space
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Email",
        field: "email",
        minWidth: 120,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Status",
        field: "status",
        minWidth: 120,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Rate",
        field: "rate",
        minWidth: 150,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Avg Time",
        field: "avgTime",
        minWidth: 130,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Wasted",
        field: "wasted",
        minWidth: 150,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
      {
        headerName: "Completed",
        field: "completed",
        minWidth: 120,
        flex: 1,
        cellRenderer: () => <SkeletonCellRenderer />,
      },
    ],
    rowData: new Array(20).fill(null), // Creates 15 empty rows
    defaultColDef: {
      resizable: true,
    },
  };

  return (
    <div
      className={`orbit-ag-grid ${
        darkMode
          ? "ag-theme-alpine-dark orbit_agency-ag"
          : "ag-theme-alpine orbit_agency-ag"
      }`}
    >
      <AgGridReact gridOptions={gridOptions} />
    </div>
  );
}

const SkeletonCellRenderer = ({ value }) => {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <Skeleton animation="wave" sx={{ bgcolor: darkMode ? "grey.800" : "" }} />
  );
};

export default OrbitPharmanautSkeleton;

export const setPatientTherapy = (type, value, patientMedicationTherapy) => {
  const therapyObj = {
    quantity: type === "quantity" ? value : patientMedicationTherapy?.quantity,
    dose: type === "dose" ? value : patientMedicationTherapy?.dose,
    therapyType:
      type === "therapyType" ? value : patientMedicationTherapy?.therapyType,
    startDate:
      type === "startDate" ? value : patientMedicationTherapy?.startDate,
    stopDate: type === "stopDate" ? value : patientMedicationTherapy?.stopDate,
    archive: type === "archive" ? value : patientMedicationTherapy?.archive,
    preparationType:
      type === "preparationType"
        ? value
        : patientMedicationTherapy?.preparationType,
    marking: type === "marking" ? value : patientMedicationTherapy?.marking,
    color: type === "color" ? value : patientMedicationTherapy?.color,
    report: type === "report" ? value : patientMedicationTherapy?.report,
    prescribedItemId: patientMedicationTherapy?.prescribedItemId,
    prescriptionMedicationId:
      patientMedicationTherapy?.prescriptionMedicationId,
    patientId: patientMedicationTherapy?.patientId,
    patientMedicationTime:
      type === "medicationTime"
        ? value
        : patientMedicationTherapy?.patientMedicationTime,
  };
  return therapyObj;
};

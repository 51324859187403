import React, { useState } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { MdAdd, MdArrowForward, MdDelete } from "react-icons/md";
import Checkbox from "@mui/material/Checkbox";
import SettingsSidebar from "../../SettingsSidebar/SettingsSidebar";
import AsyncRendererRouter from "../../AsyncRendererRouter/AsyncRendererRouter";
import RolesLoadingComponent from "../../SettingsLoadingComponents/RolesLoadingComponent";
import useTitanTemplate from "../../../hooks/useTitanTemplate";
import {
  setTemplateMessageType,
  setUpdateMessagetemplate,
  setEmailSubject,
  deleteTemplates,
} from "../../../dispatchers/templateDispatchers";
import { checkUserPermissions } from "../../../utils/checkUserPermissions";
import { accessEmailTemplate } from "../../../data/constantStrings";
import TemplateConfirmationModal from "./component/TemplateConfirmationModal";
import { set } from "date-fns";

export default function EmailAndSms() {
  const { templateState } = useTitanTemplate();
  const { emailsAndSmsTemplates, isTemplatesLoading } = templateState || {};
  const navigate = useNavigate();

  const [selectedEmailItems, setSelectedEmailItems] = useState([]);
  const [selectedSmsItems, setSelectedSmsItems] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddNewTemplate = (messageType) => {
    setTemplateMessageType(messageType);
    navigate("/settings/communication/messagetemplate/new");
  };

  const handleEditTemplate = (value, message) => {
    setTemplateMessageType(message?.messageType);
    setEmailSubject(message?.subject);
    setUpdateMessagetemplate(message);
    navigate("/settings/communication/messagetemplate/update");
  };

  const handleEmailCheckboxChange = (id) => {
    setSelectedSmsItems([]); // Clear selected SMS items
    setSelectedEmailItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSmsCheckboxChange = (id) => {
    setSelectedEmailItems([]); // Clear selected Email items
    setSelectedSmsItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleEmailDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleSmsDeleteClick = () => {
    setIsModalOpen(true);
  };
  const handleSingleDelete = (id, messageType) => {
    setIsModalOpen(true);

    if (messageType === 0) {
      // Email template
      setSelectedEmailItems([id]);
      setSelectedSmsItems([]);
    } else if (messageType === 1) {
      // SMS template
      setSelectedSmsItems([id]);
      setSelectedEmailItems([]);
    }
  };

  const handleConfirmDelete = () => {
    if (selectedEmailItems.length > 0) {
      deleteTemplates(selectedEmailItems);
      setSelectedEmailItems([]);
    }
    if (selectedSmsItems.length > 0) {
      deleteTemplates(selectedSmsItems);
      setSelectedSmsItems([]);
    }
    setIsModalOpen(false);
  };
  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      resolve={appData}
      isLoading={isTemplatesLoading}
      loadingElement={<RolesLoadingComponent />}
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper communication_wrapper">
            <div className="settings_heading template_heading">
              <h1>SMS & Email</h1>
            </div>
            <div className="settings_content_area roles_wrapper email_sms_wrapper">
              <div className="allRoles_wrapper allEmails_wrapper">
                <div className="allView_list_header">
                  <h3>Email Template</h3>
                  <div className="allView_action_btns">
                    {selectedEmailItems.length > 0 && (
                      <div
                        className="addNew_cta"
                        onClick={handleEmailDeleteClick}
                      >
                        <span>
                          {selectedEmailItems.length === 1
                            ? `Template Selected (${selectedEmailItems.length})`
                            : `Templates Selected (${selectedEmailItems.length})`}
                        </span>
                        <MdDelete />
                      </div>
                    )}
                    {checkUserPermissions(accessEmailTemplate) && (
                      <div
                        className="addNew_cta"
                        onClick={() => handleAddNewTemplate(0)}
                      >
                        <span>Add Template</span>
                        <MdAdd />
                      </div>
                    )}
                  </div>
                </div>
                <div className="allView_listview">
                  {emailsAndSmsTemplates?.length > 0 ? (
                    emailsAndSmsTemplates
                      .filter((email) => email?.messageType === 0)
                      .map((email) => (
                        <div className="allView_container" key={email._id}>
                          <div className="allView_title">
                            <div className="allView_checkbox">
                              {checkUserPermissions(accessEmailTemplate) && (
                                <Checkbox
                                  className="template-email-checkbox"
                                  checked={selectedEmailItems.includes(
                                    email._id
                                  )}
                                  onChange={(e) => {
                                    e.stopPropagation(); // Prevent parent div's click event
                                    handleEmailCheckboxChange(email._id);
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}

                              <p className="allView_name">
                                {email?.templateName}
                              </p>
                            </div>
                          </div>
                          <div className="allView_actions">
                            {checkUserPermissions(accessEmailTemplate) && (
                              <MdDelete
                                className="icons-color"
                                onClick={() =>
                                  handleSingleDelete(
                                    email._id,
                                    email.messageType
                                  )
                                } // Pass messageType
                              />
                            )}

                            {checkUserPermissions(accessEmailTemplate) && (
                              <MdArrowForward
                                size={20}
                                className="icons-color member_edit_icon"
                                onClick={
                                  checkUserPermissions(accessEmailTemplate)
                                    ? () => handleEditTemplate(true, email)
                                    : null
                                }
                              />
                            )}
                          </div>
                        </div>
                      ))
                  ) : (
                    <p>No Templates Found</p>
                  )}
                </div>
              </div>
              <div className="allRoles_wrapper allEmails_wrapper">
                <div className="allView_list_header">
                  <h3>SMS Template</h3>

                  <div className="allView_action_btns">
                    {selectedSmsItems.length > 0 && (
                      <div
                        className="addNew_cta"
                        onClick={handleSmsDeleteClick}
                      >
                        <span>
                          {selectedSmsItems.length === 1
                            ? `Template Selected (${selectedSmsItems.length})`
                            : `Templates Selected (${selectedSmsItems.length})`}
                        </span>
                        <MdDelete />
                      </div>
                    )}
                    {checkUserPermissions(accessEmailTemplate) && (
                      <div
                        className="addNew_cta"
                        onClick={() => handleAddNewTemplate(0)}
                      >
                        <span>Add Template</span>
                        <MdAdd />
                      </div>
                    )}
                  </div>
                </div>
                <div className="allView_listview">
                  {emailsAndSmsTemplates?.length > 0 ? (
                    emailsAndSmsTemplates
                      .filter((email) => email?.messageType === 1)
                      .map((email) => (
                        <div className="allView_container" key={email._id}>
                          <div className="allView_title">
                            <div className="allView_checkbox">
                              {checkUserPermissions(accessEmailTemplate) && (
                                <Checkbox
                                  className="template-email-checkbox"
                                  checked={selectedSmsItems.includes(email._id)}
                                  onChange={(e) => {
                                    e.stopPropagation(); // Prevent parent div's click event
                                    handleSmsCheckboxChange(email._id);
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}

                              <p className="allView_name">
                                {email?.templateName}
                              </p>
                            </div>
                          </div>
                          <div className="allView_actions">
                            {checkUserPermissions(accessEmailTemplate) && (
                              <MdDelete
                                className="icons-color"
                                onClick={() =>
                                  handleSingleDelete(
                                    email._id,
                                    email.messageType
                                  )
                                } // Pass messageType
                              />
                            )}

                            {checkUserPermissions(accessEmailTemplate) && (
                              <MdArrowForward
                                size={20}
                                className="icons-color member_edit_icon"
                                onClick={
                                  checkUserPermissions(accessEmailTemplate)
                                    ? () => handleEditTemplate(true, email)
                                    : null
                                }
                              />
                            )}
                          </div>
                        </div>
                      ))
                  ) : (
                    <p>No Templates Found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TemplateConfirmationModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleConfirmDelete}
          title="Confirm Deletion"
          description="Are you sure you want to delete the selected template(s)?"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
        />
        ;
      </div>
    </AsyncRendererRouter>
  );
}

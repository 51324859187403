import { getObject } from "../getObjectValue";

class setPrescriptionChecks {
  constructor(response, currentPatientId) {
    this.patientId =
      currentPatientId === ""
        ? response.patientId
          ? response.patientId
          : null
        : currentPatientId;
    this.prescriptionDate = response.prescriptionDate
      ? response.prescriptionDate
      : "";
    this.patientDob = response.patient.dob ? response.patient.dob : "";
    this.prescriptionType = response.prescriptionType
      ? response.prescriptionType
      : 0;
    this.patientMatchStatus =
      currentPatientId === ""
        ? response.prescriptionPatientMatchStatusType
          ? response.prescriptionPatientMatchStatusType
          : 0
        : 3;
    this.prescriptionId = response.prescriptionId
      ? response.prescriptionId
      : "";
    this.incomingPrescriptionId = response.prescriptionIncomingId
      ? response.prescriptionIncomingId
      : "";
    this.prescriptionCode = response.prescriberCode
      ? response.prescriberCode
      : "";
  }
}

export default setPrescriptionChecks;

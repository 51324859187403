import React from "react";
import { ReactComponent as DocumentIcon } from "./../../../../../../src/assets/images/document-icon.svg";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { Skeleton } from "@mui/material";

export default function OrbitAgencyNMSRate() {
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState;
  const { isOtherDetailsLoading, otherDetails } = orbitState || {};
  const { consultationRate } = otherDetails || {};
  return (
    <div className="orbit_box">
      <div className="orbit_label">
        <DocumentIcon />
        <p>NMS Rate</p>
      </div>
      {isOtherDetailsLoading ? (
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={50}
          animation="wave"
          variant="rounded"
          style={{ marginTop: "10px" }}
        />
      ) : (
        <p className="orbit_value">{consultationRate}</p>
      )}
    </div>
  );
}

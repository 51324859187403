import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Switch, TextareaAutosize } from "@mui/material";
import CustomButton from "../../../controls/CustomButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  emailActionType,
  smsActionType,
} from "../../../../enum/emailActionType";
import useTitanTemplate from "../../../../hooks/useTitanTemplate";
import useTitanApp from "../../../../hooks/useTitanApp";
import {
  updateEmailsAndSmsTemplates,
  getDefaultTemplates,
  setEmailSubject,
} from "../../../../dispatchers/templateDispatchers";
import { showError } from "../../../ToastedMessage/ToastedMessage";
import { emailPlaceholders } from "../../../../data/emailTemplate";
import SettingsSidebar from "../../../SettingsSidebar/SettingsSidebar";
import { GoArrowLeft } from "react-icons/go";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Preview icon
import PreviewEmailTemplate from "./PreviewEmailTemplate";
import {
  changeTemplateNameHandler,
  changeSubjectHandler,
} from "../../../../../src/utils/changeTemplateFieldHandler";

export default function UpdateEmailTemplate() {
  const { templateState } = useTitanTemplate();
  const { appState } = useTitanApp();
  const { templateMessageType, updateMessagetemplateData, emailSubject } =
    templateState || {};
  const { companyId } = appState || {};
  const [isActive, setIsActive] = useState(updateMessagetemplateData?.isActive);
  const [templateName, setTemplateName] = useState(
    updateMessagetemplateData?.templateName
  );
  const [emailContent, setEmailContent] = useState(
    updateMessagetemplateData?.text
  );
  const [templateType, setTemplateType] = useState(
    updateMessagetemplateData?.actionType
  );
  const [charCount, setCharCount] = useState(
    updateMessagetemplateData?.text.length
  );
  const [showModal, setShowModal] = useState(false);
  const [isTemplateNameLengthExceeded, setIsTemplateNameLengthExceeded] =
    useState(false);
  const [isSubjectLengthExceeded, setIsSubjectLengthExceeded] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const navigate = useNavigate();
  const templateTypeArray = Object.entries(emailActionType).map(
    ([id, name]) => ({
      id: Number(id),
      name,
    })
  );
  const smsTypeArray = Object.entries(smsActionType).map(([id, name]) => ({
    id: Number(id),
    name,
  }));
  const textareaRef = useRef(null);

  // Handle action type change
  const handleChange = (event) => {
    setTemplateType(event.target.value);

    if (templateMessageType === 0) {
      getDefaultTemplates(event.target.value).then((data) => {
        setEmailSubject(data?.subject || "");
        setTemplateName(data?.templateName || "");
        setEmailContent(data?.text || "");
      });
    }
  };

  // Toggle active state
  const handleToggleActive = () => {
    setIsActive(!isActive);
  };

  // Handle save logic
  const handleSave = () => {
    const isInvalid =
      templateType === null ||
      templateType === undefined ||
      !templateName ||
      (templateMessageType === 0 && !emailSubject) ||
      !emailContent;
    setIsValidate(isInvalid);

    if (!isInvalid) {
      const dataArray = {
        templateName: templateName,
        actionType: templateType,
        subject: emailSubject,
        text: emailContent,
        isActive: isActive,
        messageType: templateMessageType,
        isDefault: updateMessagetemplateData?.isDefault,
        createdOn: new Date().toISOString(),
        companyID: companyId,
        _id: updateMessagetemplateData?._id,
      };
      updateEmailsAndSmsTemplates(dataArray)
        .then((data) => {
          if (!data) {
            showError("Template could not be updated!");
          }
          navigate("/settings/communication/messagetemplate");
          setEmailSubject("");
        })
        .catch((data) => {
          showError(data);
        });
    }
  };

  // Handle placeholder click
  const handlePlaceholderClick = (placeholder) => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const textBefore = emailContent.substring(0, startPos);
      const textAfter = emailContent.substring(endPos, emailContent.length);

      // Insert the placeholder at the cursor position
      setEmailContent(textBefore + placeholder + textAfter);

      // Move the cursor after the inserted text
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd =
          startPos + placeholder.length;
        textarea.focus();
      }, 0);
      setCharCount(emailContent.length + placeholder.length);
    }
  };

  // Handle drag start
  const handleDragStart = (e, placeholder) => {
    e.dataTransfer.setData("placeholder", placeholder);
  };

  // Handle drop in TextArea
  const handleDrop = (e) => {
    e.preventDefault();
    const placeholder = e.dataTransfer.getData("placeholder");

    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const textBefore = emailContent.substring(0, startPos);
      const textAfter = emailContent.substring(endPos, emailContent.length);

      // Insert the placeholder at the cursor position
      setEmailContent(textBefore + placeholder + textAfter);
      setCharCount(emailContent.length + placeholder.length);
    }
  };

  // Allow drop by preventing default behavior
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Toggle edit/preview mode
  const handleToggleEditPreview = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleContentChange = (e) => {
    const content = e.target.value;
    setEmailContent(content);
    setCharCount(content.length);
  };
  return (
    <div className="settings_page">
      <div className="settings_wrapper">
        <SettingsSidebar />
        <div className="settings_content_wrapper template_content_wrapper">
          <div className="settings_heading">
            <div className="template-header">
              <div
                className="back_to_SettingsListView"
                onClick={() => {
                  navigate("/settings/communication/messagetemplate");
                }}
              >
                <GoArrowLeft className="icons-color" />
                <p>Back</p>
              </div>
            </div>
            <div className="addNewMember_wrapper"></div>
            <div className="addMember_formFields">
              <div className="inner-panels">
                <div className="AddNew_sectionbox">
                  <div className="template_title">
                    <h4>
                      Appointment {templateMessageType === 0 ? "email" : "sms"}
                    </h4>
                  </div>
                  <div className="email_template_content_wrapper">
                    <div className="sidebar">
                      <span className="placeholder_label">Placeholders</span>
                      <p className="placeholder_content">
                        You can click or drag-and-drop any of these tags to
                        include them in your content.
                      </p>
                      <div className="placeholder_tags">
                        {emailPlaceholders?.map((categoryData, index) => (
                          <div key={index}>
                            <span>{categoryData.category}</span>
                            <br />
                            {categoryData.placeholders.map(
                              (placeholder, idx) => (
                                <div
                                  key={idx}
                                  className="placeholder-buttons"
                                  draggable
                                  onDragStart={(e) =>
                                    handleDragStart(e, placeholder.value)
                                  }
                                >
                                  <Button
                                    onClick={() =>
                                      handlePlaceholderClick(placeholder.value)
                                    }
                                  >
                                    {placeholder.display}
                                  </Button>
                                </div>
                              )
                            )}
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="email-template-content">
                      <div>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Template Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={templateType}
                              label="Action Type"
                              onChange={handleChange}
                            >
                              {templateMessageType === 0
                                ? templateTypeArray?.map((template) => (
                                    <MenuItem
                                      key={template.id}
                                      value={template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  ))
                                : smsTypeArray?.map((template) => (
                                    <MenuItem
                                      key={template.id}
                                      value={template.id}
                                    >
                                      {template.name}
                                    </MenuItem>
                                  ))}
                            </Select>
                            {isValidate && !templateType && (
                              <p className="max-word-limit">
                                *Template type is required!
                              </p>
                            )}
                          </FormControl>
                        </Box>
                      </div>
                      <div>
                        <TextField
                          id="outlined-basic"
                          label="Template name"
                          variant="outlined"
                          fullWidth
                          name="templateName"
                          placeholder="Template name"
                          value={templateName}
                          onChange={(e) =>
                            changeTemplateNameHandler(
                              e,
                              setTemplateName,
                              setIsTemplateNameLengthExceeded
                            )
                          }
                          error={isValidate && !templateName}
                          helperText={
                            isValidate && !templateName
                              ? "*Template name is required!"
                              : ""
                          }
                        />
                      </div>
                      {isTemplateNameLengthExceeded && (
                        <p className="max-word-limit">
                          *Your character limit has been reached
                        </p>
                      )}
                      {templateMessageType === 0 && (
                        <TextField
                          id="outlined-basic"
                          label="Subject"
                          variant="outlined"
                          fullWidth
                          name="emailSubject"
                          placeholder="Subject"
                          value={emailSubject}
                          onChange={(e) =>
                            changeSubjectHandler(
                              e,
                              setEmailSubject,
                              setIsSubjectLengthExceeded
                            )
                          }
                          error={
                            isValidate &&
                            templateMessageType === 0 &&
                            !emailSubject
                          }
                          helperText={
                            isValidate &&
                            templateMessageType === 0 &&
                            !emailSubject
                              ? "*Subject is required!"
                              : ""
                          }
                        />
                      )}
                      {isSubjectLengthExceeded && (
                        <p className="max-word-limit">
                          *Your character limit has been reached
                        </p>
                      )}
                      <div className="active-toggle">
                        <span>Active</span>
                        <Switch
                          checked={isActive}
                          onChange={handleToggleActive}
                        />
                      </div>
                      <div>
                        {/* Edit and Preview Icons */}
                        {templateMessageType === 0 ? (
                          <div className="edit-preview-toggle">
                            <Button
                              startIcon={<VisibilityIcon />}
                              onClick={handleToggleEditPreview}
                            >
                              PREVIEW
                            </Button>
                          </div>
                        ) : null}
                        <TextareaAutosize
                          minRows={15}
                          value={emailContent}
                          onChange={handleContentChange}
                          ref={textareaRef}
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          style={{ width: "100%", padding: "10px" }}
                        />
                        {templateMessageType === 1 ? (
                          <div className="counter-wrapper">
                            Count: {charCount}
                          </div>
                        ) : null}
                        {isValidate && !emailContent && (
                          <p className="max-word-limit">
                            *Message is required!
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer_btn template_footer_button">
                  <CustomButton label="Save" action={handleSave} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PreviewEmailTemplate
          show={showModal}
          handleClose={handleCloseModal}
          emailContent={emailContent}
        />
      </div>
    </div>
  );
}

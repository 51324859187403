import React from "react";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import Avatar from "react-initials-avatar";

export default function OrbitAgencyWorkPlannerCalendar() {
  const { orbitState } = useTitanApp();
  const { orbitWorkPlanner, orbitPharmanautsWorkPlanner } = orbitState || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };

  const pharmanautsToShow = orbitPharmanautsWorkPlanner.slice(0, 4);
  const pharmanautsToHide = orbitPharmanautsWorkPlanner.slice(4);

  return (
    <div className="orbit-agency-work-planner-calendar-wrapper">
      {orbitWorkPlanner?.length > 0 ? (
        orbitWorkPlanner.map((work, index) => (
          <div className="orbit-agency-work-planner-date-box" key={index}>
            <div className="header">
              <p className="date">{formatDate(work.date)}</p>
              <p className="day">{work.day}</p>
            </div>
            <div
              className="services-wrapper"
              style={{
                display:
                  work?.orbitServicesModel?.length > 0 ? "grid" : "block",
              }}
            >
              {work?.orbitServicesModel?.length > 0 ? (
                work.orbitServicesModel.map((serviceModel, index) => (
                  <div className="service" key={index}>
                    <p className="name">
                      {serviceModel.serviceName === "New Medicine Service"
                        ? "NMS"
                        : serviceModel.serviceName}
                    </p>
                    <p className="value">{serviceModel.serviceCount}</p>
                  </div>
                ))
              ) : (
                <div>
                  <p className="t-center">No services available</p>
                </div>
              )}
            </div>
            <div className="pharmanauts-wrapper pharmanauts-daydate-wrapper">
              {pharmanautsToShow?.length > 0 ? (
                pharmanautsToShow.map((pharmanaut, index) => (
                  <div
                    className="info-wrapper-list-box pharmanauts pharmanaut-daydate"
                    key={index}
                  >
                    <div className="avatar-box">
                      <Avatar
                        name={pharmanaut.fullName}
                        size={40}
                        fontSize={10}
                      />
                      <div className="pharmanaut-name">
                        <p>{pharmanaut.fullName}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {pharmanautsToHide?.length > 0 ? (
                <p className="pharmanaut-length">
                  {"+" + pharmanautsToHide.length}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))
      ) : (
        <div>
          <p className="orbit-no-data-note">No data available</p>
        </div>
      )}
    </div>
  );
}

import React from "react";
import { ReactComponent as FileSVG } from "./../../../../../assets/images/orbitFile.svg";
import LoadingComponentRank from "../../../../../components/LoadingComponentOrbit/LoadingComponentRank";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { Skeleton } from "@mui/material";

export default function OrbitLiveVolume() {
  const { orbitState, appState } = useTitanApp();
  const { darkMode } = appState;
  const { orbitAgencyDetails, orbitAgencyDetailsLoader } = orbitState || {};
  const { liveVolume } = orbitAgencyDetails || "";
  return (
    <div className="orbit_box">
      {orbitAgencyDetailsLoader ? (
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={150}
          animation="wave"
          variant="rounded"
          style={{ marginTop: "10px" }}
        />
      ) : (
        <>
          {" "}
          <div className="orbit_label">
            <FileSVG />
            <p>Live Volume</p>
          </div>
          <p className="orbit_value">{liveVolume || 0}</p>
        </>
      )}
    </div>
  );
}

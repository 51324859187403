import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import "./../../assets/css/FindPatientModal.scss";
import CustomButton from "../controls/CustomButton";
import { ReactComponent as UserAvatar } from "./../../assets/images/ccPatientModalUserIcon.svg";
import { BsPinMap } from "react-icons/bs";
import { Rating, Skeleton } from "@mui/material";
import { IconField } from "primereact/iconfield";
import { IoSearchOutline } from "react-icons/io5";
import { InputText } from "primereact/inputtext";
import { IoCloseSharp } from "react-icons/io5";
import useTitanApp from "../../hooks/useTitanApp";
import {
  setOpenFindPatientComparisonModal,
  setOpenFindPatientModal,
} from "../../dispatchers/clinicalChecksDispatchers";

export default function FindPatientModal({
  comingFromFindPatientComparisonModal,
  selectedPatient,
  setSelectedPatient,
  setComingFromFindPatientComparisonModal,
}) {
  const { clincicalChecksState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { openFindPatientModal, patientSearchItems, patientSearchLoader } =
    clincicalChecksState || {};

  const handleCloseFindPatientModal = () => {
    setSelectedPatient("");
    setComingFromFindPatientComparisonModal(false);
    setOpenFindPatientModal(false);
  };

  const handlePatientClick = useCallback(
    (patient) => {
      if (selectedPatient.patientId === patient.patientId) {
        setSelectedPatient("");
      } else {
        setSelectedPatient(patient);
      }
    },
    [selectedPatient, setSelectedPatient]
  );

  const [searchTerm, setSearchTerm] = useState("");

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter patient search items based on the search term
  const filteredPatients = patientSearchItems.filter((patientItem) => {
    const fullName =
      `${patientItem?.title} ${patientItem?.forename} ${patientItem?.middleName} ${patientItem?.surname}`.toLowerCase();
    const nhsNumber = patientItem?.nhsNumber
      ? patientItem.nhsNumber.toString().toLowerCase()
      : "";
    return (
      fullName.includes(searchTerm) ||
      nhsNumber.includes(searchTerm) ||
      (patientItem?.address &&
        patientItem?.address.toLowerCase().includes(searchTerm))
    );
  });

  const handleSubmit = () => {
    setOpenFindPatientModal(false);
    setOpenFindPatientComparisonModal(true);
    setComingFromFindPatientComparisonModal(false);
  };
  return (
    <Modal
      size="lg"
      show={openFindPatientModal}
      onHide={() => handleCloseFindPatientModal()}
      backdrop="static"
      centered
      dialogClassName="cc-find-patient-modal"
      contentClassName="cc-find-patient-modal-content"
      animation={comingFromFindPatientComparisonModal ? false : true}
    >
      <Modal.Header>
        {patientSearchLoader ? (
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            width={120}
            height={30}
            animation="wave"
            variant="rounded"
          />
        ) : (
          <p className="heading">
            <strong>Similar patients</strong>
          </p>
        )}

        <IoCloseSharp
          size={20}
          color="#999"
          onClick={handleCloseFindPatientModal}
        />
      </Modal.Header>
      <Modal.Body>
        {!patientSearchLoader && (
          <IconField iconPosition="left">
            <IoSearchOutline />
            <InputText
              placeholder="Search for patient..."
              onChange={handleSearch}
            />
          </IconField>
        )}

        <div className="patient-list">
          {patientSearchLoader ? (
            Array.from({ length: 10 }).map((_, index) => (
              <div className="patient-info-skeleton">
                <Skeleton
                  key={index}
                  sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                  width={800}
                  height={70}
                  animation="wave"
                  variant="rounded"
                />
              </div>
            ))
          ) : filteredPatients.length === 0 ? (
            <div className="no-patient-found">
              <p>No patients found.</p>
            </div>
          ) : (
            filteredPatients.map((patientItem) => {
              const dob = patientItem?.dob
                ? new Date(patientItem.dob).toLocaleDateString("en-GB")
                : "-";
              const age = patientItem?.dob
                ? new Date().getFullYear() -
                  new Date(patientItem.dob).getFullYear()
                : "-";
              const rank = patientItem?.rank;

              return (
                <div
                  className={`patient-info ${
                    patientItem?.patientId === selectedPatient.patientId
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handlePatientClick(patientItem)}
                  key={patientItem?.patientId}
                >
                  <div className="avatar">
                    <UserAvatar />
                  </div>
                  <div className="details">
                    <div className="name-address">
                      <p className="name">
                        {patientItem?.title} {patientItem?.forename}{" "}
                        {patientItem?.middleName} {patientItem?.surname}
                      </p>
                      <div className="address">
                        <BsPinMap color="#999" size={12} />
                        <p>{patientItem?.address}</p>
                      </div>
                    </div>

                    <div className="dob">
                      <p>
                        {dob} ({age})
                      </p>
                    </div>
                  </div>
                  <div className="nhs-no">
                    <Rating
                      size="small"
                      name="read-only"
                      value={rank}
                      readOnly
                    />
                    <p>NHS No. {patientItem?.nhsNumber}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {patientSearchLoader ? (
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            width={150}
            height={40}
            animation="wave"
            variant="rounded"
          />
        ) : (
          <CustomButton label="Cancel" action={handleCloseFindPatientModal} />
        )}
        {patientSearchLoader ? (
          <Skeleton
            sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            width={150}
            height={40}
            animation="wave"
            variant="rounded"
          />
        ) : (
          <CustomButton
            label="Submit"
            isdisable={selectedPatient ? false : true}
            action={handleSubmit}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

import React from "react";
import "../assets/nopatientpopup.css";
import CustomButton from "./controls/CustomButton";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../hooks/useTitanApp";

function NopatientPopup({ screen }) {
  const { appState, clincicalChecksState } = useTitanApp();
  const { isOrbitUser } = appState || {};
  const { clinicalChecksPharmacy } = clincicalChecksState || {};
  const { currentPharmacyId } = appState || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  const navigate = useNavigate();

  function routeToDashboard() {
    if (screen === "Clinical checks") {
      navigate(`/pharmacyservices/${currentPharmacyId}`, {
        state: {
          serviceType: "Kanban",
        },
      });
    } else {
      isOrbitUser ? navigate(`/orbit`) : navigate("/");
    }
  }

  return (
    <div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
      <div className="nopatient-card popup-styles col-4">
        <div>
          <p>{`There are no more ${screen} to process.`}</p>

          <CustomButton label="Go to Dashboard" action={routeToDashboard} />
        </div>
      </div>
    </div>
  );
}

export default NopatientPopup;

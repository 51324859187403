import React, { useState } from "react";
import "./../../assets/css/NmsDetails.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NmsPatientInfo from "./NmsPatientInfo";
import NmsTimeLine from "./NmsTimeLine";
import ImageSlider from "../ImageSlider";
import useTitanApp from "../../hooks/useTitanApp";
import { Skeleton } from "@mui/material";
import NmsPharmacyInfo from "./NmsPharmacyInfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props || {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function NmsDetails({
  Nmsdetails,
  darkModeChange,
  prescriptionImages,
  stageName,
  setContactServiceID,
}) {
  const { appState, nmsServiceState } = useTitanApp();
  const { darkMode, isOrbitUser } = appState || {};
  const {
    isNmsTimelineLoading,
    isNmsLoading,
    isNmsPatientLoading,
    isNmsPrescriptionLoading,
  } = nmsServiceState || {};
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className="patient-tab">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          centered={true}
          style={{
            pointerEvents: isNmsPatientLoading ? "none" : "",
          }}
        >
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isNmsPatientLoading === true ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Patient"
                )}
              </span>
            }
            {...a11yProps(1)}
            className="para-color"
          />
          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isNmsTimelineLoading === true ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Activity"
                )}
              </span>
            }
            {...a11yProps(0)}
            className="para-color"
          />

          <Tab
            label={
              <span className={`tabs_text_alt`}>
                {isNmsPrescriptionLoading === true ? (
                  <Skeleton
                    sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                    height={30}
                    width={100}
                    animation="wave"
                    variant="rounded"
                  />
                ) : (
                  "Prescription"
                )}
              </span>
            }
            {...a11yProps(2)}
            className="para-color"
          />
          {isOrbitUser && (
            <Tab
              label={
                <span className={`tabs_text_alt`}>
                  {isNmsPrescriptionLoading === true ? (
                    <Skeleton
                      sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                      height={30}
                      width={100}
                      animation="wave"
                      variant="rounded"
                    />
                  ) : (
                    "Pharmacy"
                  )}
                </span>
              }
              {...a11yProps(2)}
              className="para-color"
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <NmsPatientInfo
          Nmsdetails={Nmsdetails}
          setContactServiceID={setContactServiceID}
          darkModeChange={darkModeChange}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="ps-timeline-wrapper">
          <NmsTimeLine stageName={stageName} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ImageSlider navigateFrom={"NMS"} />
      </TabPanel>
      {isOrbitUser && (
        <TabPanel value={value} index={3}>
          <NmsPharmacyInfo />
        </TabPanel>
      )}
    </Box>
  );
}

export default NmsDetails;

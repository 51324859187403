import React from "react";

export default function OrderingTooltipOrderDetails({ data }) {
  function formatDate(dateInput) {
    const date = new Date(dateInput);
    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")}`;

    return formattedDate;
  }

  return (
    <div className="ordering-tooltip-order-details">
      <div>
        <p className="label">Monthly usage</p>
        <p className="value">
          {data.avgMonthly === null ? 0 : data.avgMonthly}
        </p>
      </div>
      <div>
        <p className="label">Week usage</p>
        <p className="value">{data.avgWeekly === null ? 0 : data.avgWeekly}</p>
      </div>
      <div>
        <p className="label">Daily usage</p>
        <p className="value">{data.avgDaily === null ? 0 : data.avgDaily}</p>
      </div>
      <div>
        <p className="label">Max day usage</p>
        <p className="value">
          {data.maxDayFreq === null ? 0 : data.maxDayFreq}
        </p>
      </div>
      <div>
        <p className="label">Last ordered</p>
        <p className="value">
          {data?.lastOrderedQuantity} (
          {data?.lastOrdered ? formatDate(data.lastOrdered) : "Not Applicable"})
        </p>
      </div>
      <div>
        <p className="label">Reimbursement</p>
        <p className="value">(£) {data?.reimbursement}</p>
      </div>
    </div>
  );
}

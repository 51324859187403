import React from "react";
import OrbitAgencyName from "./components/OrbitAgencyName.js";
import OrbitAgencyNMSRate from "./components/OrbitAgencyNMSRate.js";
import OrbitAgencyAvgTime from "./components/OrbitAgencyAvgTime.js";
import OrbitAgencyInterventions from "./components/OrbitAgencyInterventions.js";
import OrbitAgencyFollowups from "./components/OrbitAgencyFollowups.js";
import OrbitAgencyMonthlyReport from "./components/OrbitAgencyMonthlyReport.js";
import OrbitAgencyUserSessions from "./components/OrbitAgencyUserSessions.js";
import OrbitAgencyActiveServices from "./components/OrbitAgencyActiveServices.js";

export default function OrbitAgencyUsersGrid() {
  return (
    <div className="orbit_grid_wrapper">
      <div className="orbit_grid_new orbit_grid_top_panel">
        <OrbitAgencyName />
        <OrbitAgencyNMSRate />
        <OrbitAgencyAvgTime />
        <OrbitAgencyInterventions />
        <OrbitAgencyFollowups />
      </div>
      <div className="orbit_grid_new">
        <OrbitAgencyMonthlyReport />
        <OrbitAgencyUserSessions />
        <OrbitAgencyActiveServices />
      </div>
    </div>
  );
}

export const ClinicalCheckIdColor = Object.freeze({
  1: "orange",

  2: "orange",

  3: "orange",

  4: "orange",

  5: "orange",

  6: "orange",

  7: "orange",

  8: "orange",

  9: "orange",

  10: "orange",

  11: "orange",

  12: "orange",

  13: "orange",
  14: "orange",

  15: "orange",

  16: "orange",

  17: "orange",

  18: "orange",

  19: "orange",

  20: "orange",

  21: "orange",

  22: "orange",

  23: "orange",

  24: "orange",

  25: "orange",

  26: "risk", //red

  27: "risk", //red

  28: "orange",

  29: "orange",

  30: "orange",

  31: "orange",

  32: "risk", //red

  33: "risk", //red

  34: "risk", //red

  35: "orange",

  36: "orange",

  37: "orange",

  39: "orange",

  40: "orange",

  41: "orange",

  42: "orange",

  43: "orange",

  44: "orange",

  45: "orange",

  46: "orange",

  47: "orange",

  48: "orange",

  49: "orange",

  50: "orange",

  51: "orange",

  52: "orange",

  53: "orange",

  54: "orange",

  55: "orange",

  56: "orange",

  57: "orange",

  58: "orange",

  59: "orange",

  60: "orange",

  61: "orange",

  62: "orange",

  63: "orange",

  65: "orange",

  66: "orange",

  1000: "orange",

  1001: "orange",

  1002: "orange",
});

export const ClinicalCheckTitle = Object.freeze({
  1: "Patient full match",

  2: "Patient partial match",

  3: "Patient no match",

  4: "Patient exemption",

  5: "Prescriber endorsement ACBS",

  6: "Prescriber endorsement SLS",

  7: "Prescriber endorsement CC",

  8: "Prescriber endorsement AF",

  9: "Drug match",

  10: "Expired",

  11: "Invalid DMD",

  12: "Patient additional info",

  13: "Patient repeat info",

  14: "Unknown UOM",

  15: "Interaction",

  16: "More information",

  17: "Dentist check",

  18: "Nurse check",

  19: "Addition item info",

  20: "7 Day check",

  21: "Practice employed",

  22: "Pharmacy advisory",

  23: "Drug doubling",

  24: "Drug therapy",

  25: "Drug availability",

  26: "Patient deceased", //red

  27: "NHS Status", //red

  28: "Special container",

  29: "Drugs tag",

  30: "Emergency supply",

  31: "Emergency supply",

  32: "Allergy warning", //red

  33: "Drug tariff", //red

  34: "Repeat Controlled Drugs", //red

  35: "Missing SLS",

  36: "Prescription type retired",

  37: "Excessive quantity",

  39: "Barcode not scanned",

  40: "Pharmacist",

  41: "Pharmacist",

  42: "High risk item",

  43: "Split pack",

  44: "Patient therapy",

  45: "Patient therapy",

  46: "Patient therapy",

  47: "Patient therapy",

  48: "Patient therapy",

  49: "Free-of-charge sexual health medication",

  50: "NMS Asthma and COPD",

  51: "NMS Type 2 Diabetes",

  52: "NMS Antiplatelet/Anticoagulant Therapy",

  53: "NMS Hypertension",

  54: "Erd",

  55: "Additional check",

  56: "Child Check",

  57: "SSP Endorsed Item",

  58: "High risk drug",

  59: "High risk drug",

  60: "NMS",

  61: "High risk drug",

  62: "High risk drug",

  63: "Titan X Major issue",

  65: "Post-dated",

  66: "ERD Setup",

  1000: "Out of dispense window",

  1001: "Delayed Prescription",

  1002: "Invalid signature",
});

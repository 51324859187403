import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useTitanApp from "../../../../../hooks/useTitanApp";
import { ReactComponent as CalendarSVG } from "./../../../../../assets/images/orbitCalendar.svg";
import { ReactComponent as CreditCardSVG } from "./../../../../../assets/images/orbitCreditcard.svg";
import LoadingComponentEarnings from "../../../../../components/LoadingComponentOrbit/LoadingComponentEarnings";
import { filterData } from "../../../../../data/filterActiveServices";
import { useNavigate } from "react-router-dom";
import { stageTypeName } from "../../../../../enum/stageType";
import { getTotalDaysLeftForOrbit } from "../../../../../data/getStartDateForStages";

function OrbitAgencyActiveServices() {
  const [value, setValue] = React.useState("overdue");
  const { orbitState, appState } = useTitanApp();
  const { activeServices, isActiveServicesLoading, isActive } =
    orbitState || {};
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function routeToNMS(clinicalServiceID) {
    if (isActive === true) {
      navigate(`/nmsintervention/${clinicalServiceID}`);
    }
  }
  const currentSessionId = localStorage.getItem("currentSessionId");
  const overdueArray = filterData("overdue", activeServices);
  const todayArray = filterData("today", activeServices);
  const dueLaterArray = filterData("dueLater", activeServices);
  const navigate = useNavigate();
  return (
    <div className="orbit_box orbit_active_services agency_max-height ">
      {isActiveServicesLoading ? (
        <LoadingComponentEarnings />
      ) : (
        <>
          <div className="orbit_label_alt">
            <p>My Active Services</p>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box>
                <TabList onChange={handleChange}>
                  <Tab label="Overdue" value="overdue" />
                  <Tab label="Today" value="today" />
                  <Tab label="Next Week" value="nextweek" />
                </TabList>
              </Box>
              <TabPanel value="overdue">
                {overdueArray?.length > 0 ? (
                  <div className="wrapper">
                    {overdueArray?.map((service, index) => {
                      return (
                        <div
                          key={index}
                          className="active-service-box"
                          onClick={() => routeToNMS(service?.clinicalServiceID)}
                        >
                          <div className="session active-service-session">
                            <div className="d-flex tag-flex">
                              <p className="first">{service?.patientName}</p>
                              <div className="active_tags">
                                {service?.status === 4 ? (
                                  <p className="tag parked">Parked</p>
                                ) : (
                                  <></>
                                )}
                                <p
                                  className={`tag ${
                                    service?.stageType === 3
                                      ? "intervention"
                                      : "followup"
                                  }`}
                                >
                                  {stageTypeName[service?.stageType]}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex sessions-flex">
                              <div className="d-flex-a-center cal-label overdue">
                                <CalendarSVG />
                                <p>
                                  {getTotalDaysLeftForOrbit(
                                    service?.serviceDueDateTime
                                  )}
                                </p>
                              </div>
                              <div className="d-flex-a-center cal-label">
                                <CreditCardSVG />
                                <p>
                                  £{service?.rate === "" ? 0 : service?.rate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <p className="orbit_bottomNote t-center">No Data to Show</p>
                  </div>
                )}
              </TabPanel>
              <TabPanel value="today">
                {todayArray?.length > 0 ? (
                  <div className="wrapper">
                    {todayArray?.map((service, index) => {
                      return (
                        <div
                          key={index}
                          className="active-service-box"
                          onClick={() => routeToNMS(service?.clinicalServiceID)}
                        >
                          <div className="session active-service-session">
                            <div className="d-flex tag-flex">
                              <p className="first">{service?.patientName}</p>
                              <div className="active_tags">
                                {service?.status === 4 ? (
                                  <p className="tag parked">Parked</p>
                                ) : (
                                  <></>
                                )}
                                <p
                                  className={`tag ${
                                    service?.stageType === 3
                                      ? "intervention"
                                      : "followup"
                                  }`}
                                >
                                  {stageTypeName[service?.stageType]}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex sessions-flex">
                              <div className="d-flex-a-center cal-label overdue">
                                <CalendarSVG />
                                <p>
                                  {getTotalDaysLeftForOrbit(
                                    service?.serviceDueDateTime
                                  )}
                                </p>
                              </div>
                              <div className="d-flex-a-center cal-label">
                                <CreditCardSVG />
                                <p>
                                  £{service?.rate === "" ? 0 : service?.rate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <p className="orbit_bottomNote t-center">No Data to Show</p>
                  </div>
                )}
              </TabPanel>
              <TabPanel value="nextweek">
                {dueLaterArray?.length > 0 ? (
                  <div className="wrapper">
                    {dueLaterArray?.map((service, index) => {
                      return (
                        <div
                          key={index}
                          className="active-service-box"
                          onClick={() => routeToNMS(service?.clinicalServiceID)}
                        >
                          <div className="session active-service-session">
                            <div className="d-flex tag-flex">
                              <p className="first">{service?.patientName}</p>
                              <div className="active_tags">
                                {service?.status === 4 ? (
                                  <p className="tag parked">Parked</p>
                                ) : (
                                  <></>
                                )}
                                <p
                                  className={`tag ${
                                    service?.stageType === 3
                                      ? "intervention"
                                      : "followup"
                                  }`}
                                >
                                  {stageTypeName[service?.stageType]}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex sessions-flex">
                              <div className="d-flex-a-center cal-label overdue">
                                <CalendarSVG />
                                <p>
                                  {getTotalDaysLeftForOrbit(
                                    service?.serviceDueDateTime
                                  )}
                                </p>
                              </div>
                              <div className="d-flex-a-center cal-label">
                                <CreditCardSVG />
                                <p>
                                  £{service?.rate === "" ? 0 : service?.rate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <p className="orbit_bottomNote t-center">No Data to Show</p>
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </>
      )}
    </div>
  );
}

export default OrbitAgencyActiveServices;

import React from "react";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { CirclePicker } from "react-color";
import useTitanType from "../../../../../../../../hooks/useTitanType";
import { setCurrentServiceModel } from "../../../../../../../../dispatchers/typeDispatchers";
import { styled } from "@mui/material/styles";

export default function TypesDetail() {
  const { typeState } = useTitanType();
  const { currentServiceModel } = typeState || {};
  const handleisOnlineBookingChange = (event) => {
    const { name, checked } = event?.target || {};
    setCurrentServiceModel({
      ...currentServiceModel,
      [name]: checked || false,
    });
  };

  function handleChangeDetail(event) {
    const { name, value } = event?.target || {};
    setCurrentServiceModel({ ...currentServiceModel, [name]: value });
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <>
      <div className="settings_formFields">
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          name="serviceName"
          fullwidth
          value={currentServiceModel?.serviceName || ""}
          onChange={handleChangeDetail}
        />

        <TextField
          id="outlined-basic"
          label="Price"
          variant="outlined"
          name="serviceCharges"
          fullwidth
          type="number"
          value={currentServiceModel?.serviceCharges || ""}
          onChange={handleChangeDetail}
        />
      </div>
      <div className="userDetails_fields type_fields">
        <div>
          <TextField
            id="outlined-basic"
            label="Duration"
            variant="outlined"
            name="serviceDuration"
            fullwidth
            value={currentServiceModel?.serviceDuration || ""}
            onChange={handleChangeDetail}
          />

          <div className="user_clinician">
            <p>Show in online bookings</p>
            <AntSwitch
              name="isOnlineBooking"
              checked={currentServiceModel?.isOnlineBooking || false}
              onChange={handleisOnlineBookingChange}
            />
          </div>
        </div>
        <div className="color_pick_section color_pick_section_type">
          <div>
            <h5>Service Type Color</h5>
            <p>Please select a color for the theme of your service type.</p>
            {currentServiceModel?.serviceColor && (
              <p className="selected-color-p">
                Selected color is:{" "}
                <span
                  className="selected-color"
                  style={{
                    background: `${currentServiceModel?.serviceColor}`,
                  }}
                >
                  {currentServiceModel?.serviceColor || ""}
                </span>
              </p>
            )}
          </div>
          <div>
            <CirclePicker
              color={currentServiceModel?.serviceColor || ""}
              onChange={(updatedColor) => {
                handleChangeDetail({
                  target: { name: "serviceColor", value: updatedColor?.hex },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="title_field">
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          name="serviceDescription"
          fullwidth
          value={currentServiceModel?.serviceDescription || ""}
          onChange={handleChangeDetail}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import useTitanApp from "../../../../../hooks/useTitanApp";
import LoadingComponentMonthlyReport from "../../../../../components/LoadingComponentOrbit/LoadingComponentMonthlyReport";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import OrbitMonthlyReportSkeleton from "./OrbitMonthlyReportSkeleton";
import DateRange from "../components/MonthlyReportDateRange";

function OrbitAgencyMonthlyReport() {
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState;
  const { orbitAgencyMonthlyReport, isAgencyMonthlyReportLoading } =
    orbitState || {};
  const gridApiRef = useRef(null);

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    // Update rowData whenever orbitAgencyMonthlyReport changes
    if (orbitAgencyMonthlyReport) {
      setRowData(
        orbitAgencyMonthlyReport.map((item) => ({
          pharmacy: { name: item.PharmacyName }, // Adjust this based on actual data structure
          nms: item.NmsCount,
          consented: item.ConsentedRate,
          unconcented: item.UnConsentedRate,
        }))
      );
    }
  }, [orbitAgencyMonthlyReport]);

  // Define column definitions
  const columnDefs = [
    {
      headerName: "Pharmacy",
      field: "pharmacy",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ textDecoration: "underline", margin: 0 }}>
          {params.value.name}
        </p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "NMS",
      field: "nms",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true, // Disables column dragging for this column
    },
    {
      headerName: "Consented",
      field: "consented",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true, // Disables column dragging for this column
    },
    {
      headerName: "Unconcented",
      field: "unconcented",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (
        <p style={{ margin: 0, color: "#999999" }}>{params.value}</p>
      ),
      suppressMovable: true, // Disables column dragging for this column
    },
  ];

  return (
    <div className="orbit_box orbit-ag agency_max-height orbit_mr">
      <div className="orbit_box-container orbitagency-monthly-report">
        <div className="orbit_label_alt">
          <p className="agency-report-heading">Monthly Report</p>
          <div className="date-range-picker-container agency-date-picker">
            <div className="date-range-picker analytics-tab-date-picker">
              <DateRange />
            </div>
          </div>
        </div>

        {isAgencyMonthlyReportLoading ? (
          <OrbitMonthlyReportSkeleton />
        ) : rowData.length > 0 ? (
          <div
            className={`orbit-ag-grid ${
              darkMode
                ? "ag-theme-alpine-dark orbit_agency-ag"
                : "ag-theme-alpine orbit_agency-ag"
            }`}
          >
            <AgGridReact
              ref={gridApiRef}
              rowData={rowData}
              columnDefs={columnDefs}
              scrollbarWidth={3}
            />
          </div>
        ) : (
          <div>
            <p className="orbit-no-data-note t-center">No data available</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrbitAgencyMonthlyReport;

export const bucketTypes = Object.freeze([
  {
    label: "Waiting",
    value: 1,
  },
  {
    label: "Today",
    value: 2,
  },
  {
    label: "Unallocated",
    value: 3,
  }
]);
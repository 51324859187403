import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Switch from "@mui/material/Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { FaCopy } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { BsTrash3 } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import useTitanApp from "../../../../../../../../../hooks/useTitanApp";
import {
  addDayToSelectedService,
  removeDayFromSelectedService,
  updateSlots,
  addSlot,
  deleteSlot,
  copySlot,
} from "../../../../../../../../../dispatchers/pharmacyServiceDispatchers";
import AvailableTimes from "./components/AvailableTimes";

function PharmacyTypesTab() {
  const { pharmacyServiceState } = useTitanApp();
  const { allPharmacies, currentPharmacyServiceId } =
    pharmacyServiceState || {};

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1976d2",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12,
      borderRadius: 6,
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#444",
    },
  }));

  const startTimeArray = [
    { name: "startTime", value: "09:00" },
    { name: "startTime", value: "10:00" },
    { name: "startTime", value: "11:00" },
    { name: "startTime", value: "12:00" },
    { name: "startTime", value: "13:00" },
    { name: "startTime", value: "14:00" },
    { name: "startTime", value: "15:00" },
    { name: "startTime", value: "16:00" },
    { name: "startTime", value: "17:00" },
  ];

  const endTimeArray = [
    { name: "endTime", value: "09:00" },
    { name: "endTime", value: "10:00" },
    { name: "endTime", value: "11:00" },
    { name: "endTime", value: "12:00" },
    { name: "endTime", value: "13:00" },
    { name: "endTime", value: "14:00" },
    { name: "endTime", value: "15:00" },
    { name: "endTime", value: "16:00" },
    { name: "endTime", value: "17:00" },
    { name: "endTime", value: "18:00" },
  ];

  const daysOfWeek = [
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
    { name: "Sunday", value: 7 },
  ];

  const filterEndTimeOptions = (slot) => {
    return endTimeArray.filter((time) => time.value > slot?.startTime);
  };

  const handleSwitchChange = (dayOfWeek, checked, pharmacyId, serviceId) => {
    if (checked) {
      const newDay = {
        dayOfWeek,
        slots: [
          { startTime: "09:00", endTime: "18:00" }, // Initial empty slots
        ],
      };

      addDayToSelectedService(pharmacyId, serviceId, newDay);
    } else {
      removeDayFromSelectedService(pharmacyId, serviceId, dayOfWeek);
    }
  };

  const handleDropdownChange = (
    e,
    dayOfWeek,
    pharmacyId,
    serviceId,
    slotIndex
  ) => {
    const { name, value } = e.target;

    // Find the selected pharmacy and service
    const selectedPharmacy = allPharmacies.find(
      (pharmacy) => pharmacy.pharmacyID === pharmacyId
    );

    const selectedService = selectedPharmacy?.pharmacyServices.find(
      (service) => service._id === serviceId
    );

    // Find the day slot by dayOfWeek
    const selectedDaySlot = selectedService?.availability.find(
      (slot) => slot.dayOfWeek === dayOfWeek
    );

    // Update the relevant slot (either startTime or endTime)
    if (selectedDaySlot) {
      const updatedSlots = [...selectedDaySlot.slots];
      updatedSlots[slotIndex] = {
        ...updatedSlots[slotIndex],
        [name]: value, // Update the field (startTime or endTime)
      };

      // Call dispatcher to update the state
      // This assumes you already have an action like `updateSlotTimes`
      updateSlots(pharmacyId, serviceId, dayOfWeek, updatedSlots);
    }
  };

  const handleIconClick = (
    e,
    dayOfWeek,
    pharmacyId,
    serviceId,
    index,
    actionType
  ) => {
    switch (actionType) {
      case "add":
        addSlot(pharmacyId, serviceId, dayOfWeek);
        break;
      case "delete":
        deleteSlot(pharmacyId, serviceId, dayOfWeek, index);
        break;
      case "copy":
        copySlot(pharmacyId, serviceId, dayOfWeek, index);
        break;
      default:
        break;
    }
  };

  // Function to check if the day is available for the service in the selected pharmacy
  const isDayAvailable = (pharmacyId, serviceId, dayValue, index) => {
    const selectedPharmacy = allPharmacies.find(
      (pharmacy) => pharmacy.pharmacyID === pharmacyId
    );

    if (selectedPharmacy) {
      const selectedService = selectedPharmacy.pharmacyServices.find(
        (service) => service._id === serviceId
      );

      if (selectedService && selectedService.availability) {
        // Check if the day exists in the availability of the service
        const dayAvailability = selectedService.availability.find(
          (day) => day.dayOfWeek === dayValue
        );

        // Return true if the day is available, false otherwise
        return !!dayAvailability;
      }
    }

    return false; // Default return false if no matching pharmacy or service found
  };

  return (
    <div className="inner-panels">
      <div className="AddNew_sectionbox">
        <h4>Company Pharmacies</h4>
        <div className="serviceSchedulesContainer">
          <div className="schedule">
            {allPharmacies?.length > 0 ? (
              allPharmacies.map((pharmacy, index) => (
                <Accordion key={index} className="schedule-accordian">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <p>{pharmacy?.pharmacyName}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="serviceSchedulesContainer">
                      <div className="schedule">
                        {daysOfWeek.map((day) => {
                          const selectedPharmacy = allPharmacies.find(
                            (pharmacy) =>
                              pharmacy.pharmacyServices.some(
                                (service) =>
                                  service._id === currentPharmacyServiceId
                              )
                          );

                          const addedSlots = JSON.parse(
                            JSON.stringify(
                              pharmacy?.pharmacyServices.find(
                                (service) =>
                                  service._id === currentPharmacyServiceId
                              )?.availability || []
                            )
                          );

                          return (
                            <Accordion
                              key={day.value}
                              className="schedule-accordian"
                              expanded={isDayAvailable(
                                pharmacy.pharmacyID,
                                currentPharmacyServiceId,
                                day.value,
                                index
                              )}
                              disabled={day.value === 6 || day.value === 7}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${day.value}-content`}
                                id={`panel${day.value}-header`}
                              >
                                <div className="accordianHeaderContainer membersSchedule_header">
                                  <div>
                                    <div className="switch">
                                      <AntSwitch
                                        onChange={(e) =>
                                          handleSwitchChange(
                                            day.value,
                                            e.target.checked,
                                            pharmacy.pharmacyID,
                                            currentPharmacyServiceId
                                          )
                                        }
                                        checked={isDayAvailable(
                                          pharmacy.pharmacyID,
                                          currentPharmacyServiceId,
                                          day.value,
                                          index
                                        )} // Check if the day is available
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <p className="day">{day.name}</p>
                                    </div>
                                  </div>
                                  <AvailableTimes
                                    addedSlots={addedSlots}
                                    currentDay={day?.value}
                                  />
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="scheduledetails">
                                  {addedSlots
                                    ?.filter(
                                      (item) => item.dayOfWeek === day.value
                                    )
                                    ?.map((daySlot) =>
                                      daySlot.slots.map((slot, index) => (
                                        <div className="duration" key={index}>
                                          <Dropdown
                                            name="startTime"
                                            value={slot.startTime}
                                            options={startTimeArray}
                                            optionLabel="value"
                                            placeholder="Select time"
                                            className="scheduleDropdown"
                                            onChange={(e) =>
                                              handleDropdownChange(
                                                e,
                                                day.value,
                                                pharmacy.pharmacyID,
                                                currentPharmacyServiceId,
                                                index
                                              )
                                            }
                                          />

                                          <Dropdown
                                            name="endTime"
                                            value={slot.endTime}
                                            options={filterEndTimeOptions(slot)}
                                            optionLabel="value"
                                            className="scheduleDropdown"
                                            placeholder="Select time"
                                            onChange={(e) =>
                                              handleDropdownChange(
                                                e,
                                                day.value,
                                                pharmacy.pharmacyID,
                                                currentPharmacyServiceId,
                                                index
                                              )
                                            }
                                          />
                                          <div className="icons-container">
                                            {index ===
                                            daySlot.slots.length - 1 ? (
                                              <>
                                                {daySlot.slots.length < 3 && (
                                                  <BsPlusCircle
                                                    className="addIcon"
                                                    onClick={(e) =>
                                                      handleIconClick(
                                                        e,
                                                        day.value,
                                                        pharmacy.pharmacyID,
                                                        currentPharmacyServiceId,
                                                        index,
                                                        "add"
                                                      )
                                                    }
                                                  />
                                                )}
                                                {daySlot.slots.length < 3 && (
                                                  <FaCopy
                                                    className="copyIcon"
                                                    onClick={(e) =>
                                                      handleIconClick(
                                                        e,
                                                        day.value,
                                                        pharmacy.pharmacyID,
                                                        currentPharmacyServiceId,
                                                        index,
                                                        "copy"
                                                      )
                                                    }
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <BsTrash3
                                                className="trashIcon"
                                                onClick={(e) =>
                                                  handleIconClick(
                                                    e,
                                                    day.value,
                                                    pharmacy.pharmacyID,
                                                    currentPharmacyServiceId,
                                                    index,
                                                    "delete"
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      ))
                                    )}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <p>No pharmacies available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PharmacyTypesTab;

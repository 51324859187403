import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";

import OrbitLiveVolume from "./components/OrbitLiveVolume";
import OrbitPharmanautRate from "./components/OrbitPharmanautRate";
import OrbitTimePerNms from "./components/OrbitTimePerNms";
import OrbitCompleted from "./components/OrbitCompleted";
import OrbitPharmanautList from "./components/OrbitPharmanautList";
import OrbitAgencyMonthlyReport from "./components/OrbitAgencyMonthlyReport";
import OrbitAgencyLiveWorkLoad from "./components/OrbitAgencyLiveWorkLoad";
import OrbitAgencyName from "./components/OrbitAgencyName";
import OrbitAgencyWorkPlanner from "./components/OrbitAgencyWorkPlanner/OrbitAgencyWorkPlanner";

function OrbitAgencyGrid() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className="orbit_grid_wrapper">
      <div className="orbit_grid_new orbit_grid_top_panel">
        <OrbitAgencyName />
        <OrbitLiveVolume />
        <OrbitPharmanautRate />
        <OrbitTimePerNms />
        <OrbitCompleted />
      </div>

      <Box className="orbit-agency-tab">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Orbit Agency Tabs"
        >
          <Tab
            label={<span className={`tabs_text_alt`}>Now</span>}
            className="para-color"
          />
          <Tab
            label={<span className={`tabs_text_alt`}>Work Planner</span>}
            className="para-color"
          />
        </Tabs>
      </Box>

      <TabPanel value={tabIndex} index={0}>
        <div className="orbit-agency-content">
          <div className="agency-reports">
            <OrbitAgencyLiveWorkLoad />
            <OrbitAgencyMonthlyReport />
          </div>
          <OrbitPharmanautList />
        </div>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <div>
          <OrbitAgencyWorkPlanner />
        </div>
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`orbit-tabpanel-${index}`}
      aria-labelledby={`orbit-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default OrbitAgencyGrid;

import React from "react";
import { Tabs, Tab } from "@mui/material";
import ProductTypesTab from "./components/ProductTypesTab/ProductTypesTab";
import OnlineBookingTypesTab from "./components/OnlineBookingTypesTab/OnlineBookingTypesTab";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import { LiaArrowLeftSolid } from "react-icons/lia";
import useTitanType from "../../../../../../../hooks/useTitanType";
import StageContent from "./StageContent";
import CustomButton from "../../../../../../controls/CustomButton"; // Imported CustomButton
import { updateClinicalServiceBuilder } from "../../../../../../../dispatchers/typeDispatchers";
import { useNavigate, useParams } from "react-router-dom";
import PharmacyTypesTab from "./components/ProductTypesTab/PharmacyTypesTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function ServiceTypesTab({ setServiceTabs }) {
  const navigate = useNavigate();
  const { typeState } = useTitanType();
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode, companyId } = appState;
  const [value, setValue] = React.useState(0);
  const { allPharmacies } = pharmacyServiceState || {};
  const {
    clinicalServiceBuilderModel,
    defaultClinicalServiceBuilderModel,
    isTemplateVerified,
    isTypeLoading,
  } = typeState || {};

  const serviceBuilder =
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder;
  // clinicalServiceBuilderResponse?.clinicalServiceBuilder ||
  // clinicalServiceBuilderResponse?.clinicalServiceBuilderDefault;
  const templates = defaultClinicalServiceBuilderModel?.templates || [];
  const stages = serviceBuilder?.stages || [];
  const tabs = [
    { label: "Product", component: <ProductTypesTab /> },
    { label: "Online Booking", component: <OnlineBookingTypesTab /> },
    { label: "Pharmacy", component: <PharmacyTypesTab /> },

    ...stages.map((stage, stageIndex) => ({
      label: stage.stageName,
      component: (
        <StageContent
          stage={stage}
          stageIndex={stageIndex}
          templates={templates}
        />
      ),
      props: a11yProps(stageIndex + 2),
    })),
  ];
  function isSubmitDisable() {
    const areRequiredSectionsValid =
      clinicalServiceBuilderModel?.templates?.every((template) => {
        const requiredSections = template.templateSections.filter(
          (section) => section.isRequired
        );

        return requiredSections.every((section) =>
          section.userControls.some((control) => control.value === "true")
        );
      });
    return areRequiredSectionsValid;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = () => {
    const filteredClinicalServiceBuilder = {
      ...clinicalServiceBuilderModel.clinicalServiceBuilder,
      companyID: companyId,
      stages: clinicalServiceBuilderModel.clinicalServiceBuilder?.stages?.map(
        (stage, index) => {
          return {
            ...stage,
          };
        }
      ),
    };
    const filteredTemplates = clinicalServiceBuilderModel.templates;

    const formattedPharmacies = allPharmacies
      .map((pharmacy) => {
        const validPharmacyServices = pharmacy.pharmacyServices
          ? Object.keys(pharmacy.pharmacyServices)
              .map((serviceKey) => {
                const service = pharmacy.pharmacyServices[serviceKey];
                if (service.availability && service.availability.length > 0) {
                  return {
                    PharmacyID: pharmacy.pharmacyID,
                    PharmacyServices: {
                      _id: service._id,
                      isDefault: service.isDefault,
                      companyID: service.companyID,
                      ServiceType: service.serviceType,
                      ServiceName: service.serviceName,
                      ServiceDescription: service.serviceDescription || "",
                      ServiceCharges: service.serviceCharges || 0, // Default to 0
                      ServiceDuration: service.serviceDuration || 0, // Default to 0
                      ServiceColor: service.serviceColor || "", // Default empty string
                      NotificationTemplates:
                        service.notificationTemplates || [], // Default empty array
                      ServiceCode: service.serviceCode,
                      IsOnlineBooking: service.isOnlineBooking || false, // Default to false
                      Availability: service.availability, // Include the availability array directly
                    },
                  };
                }
                return null;
              })
              .filter((item) => item !== null)
          : [];

        return validPharmacyServices.length > 0 ? validPharmacyServices : null;
      })
      .flat()
      .filter((pharmacy) => pharmacy !== null); // Exclude null values from final result

    const filteredModel = {
      clinicalServiceBuilder: filteredClinicalServiceBuilder,
      templates: filteredTemplates,
      pharmacyAvailabilty: formattedPharmacies, // Pharmacy services with valid availability
    };

    updateClinicalServiceBuilder(filteredModel);
    setServiceTabs(false);
  };

  return (
    isTypeLoading === false && (
      <>
        <div className="settings_heading">
          <h1>{serviceBuilder?.serviceName}</h1>
        </div>
        <div className="tabs_box servicetypes_tabs_box">
          <div className="d-flex">
            <LiaArrowLeftSolid
              color={darkMode ? "#fff" : "#0a0a0a"}
              onClick={() => setServiceTabs(false)}
            />
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="service types tabs"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={<span className={`tabs_text`}>{tab.label}</span>}
                  {...(tab.props || {})}
                />
              ))}
            </Tabs>
          </div>

          {tabs.map((tab, index) => (
            <TabPanel value={value} index={index} key={index}>
              {tab.component}
            </TabPanel>
          ))}
        </div>
        <div className="service-create-btn">
          <CustomButton
            isdisable={!isSubmitDisable}
            action={handleSubmit} // Bind the updated handleSubmit function here
            label={"Submit"}
          />
        </div>
        {/* Footer Section with CustomButton */}
      </>
    )
  );
}

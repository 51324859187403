import React, { useState } from "react";
import PrescriptionHeader from "../../components/PrescriptionHeader";
import PatientProfile from "../../components/PatientProfile";
import PrescribedMedicinesTheraphy from "../../components/PrescribedMedicines/PrescribedMedicines";
import ClinicalWarning from "../../components/PrescriptionClinicalWarning";
import "../../assets/clinicalcheck.css";
import ClinicalActions from "../../components/ClinicalActions";
import ImageSlider from "../../components/ImageSlider";
import NopatientPopup from "../../components/NopatientPopup";
import SeverityModal from "../../components/SeverityModal/SeverityModal";
import PatientModal from "../../components/PatientModal/PatientModal";
import DrugMatchModal from "../../components/DrugMatchModal/DrugMatchModal";
import CheckfoundModal from "../../components/CheckfoundModal/CheckfoundModal";
import { useParams, useLocation } from "react-router-dom";
import BreadCrumbNMS from "../../components/BreadCrumbs/BreadCrumb";
import useTitanApp from "../../hooks/useTitanApp";
import { Dropdown } from "primereact/dropdown";
import AsyncRendererRouter from "../../components/AsyncRendererRouter/AsyncRendererRouter";
import {
  setItemChecks,
  setSeverityCount,
  setMedicationId,
  setPatientImage,
  setNotes,
  setCurrentClinicalChecksNotes,
  getPrescriptionItemCheck,
  getPrescriptionChecks,
  getPatientImage,
  getPrescriptionIncomingIds,
  getPrescriptionByIncomingId,
  getPatientMedication,
  setMedicineWarning,
  setCheckNowModel,
  setSelectedBucketType,
  setIsParked,
  setTheraphyModal,
} from "../../dispatchers/clinicalChecksDispatchers";
import LoadingComponentNMS from "../../components/LoadingComponentNMS/LoadingComponentNMS";
import LoadingComponentCC from "../../components/LoadingComponentCC/LoadingComponentCC";
import setPrescriptionChecks from "../../Model/PrescriptionChecks/setPrescriptionChecks";
import setPrescriptionItemChecks from "../../Model/PrescriptionItemChecks/setPrescriptionItemChecks";
import { useStore } from "react-redux";
import { setAppLoading } from "../../dispatchers/appDispatchers";
import { bucketTypes } from "../../enum/bucketTypes";
import setCheckNow from "../../Model/CheckNow/setCheckNow";
import TherapyModalComponent from "../../components/TherapyModal/TherapyModalComponent";
import FindPatientModal from "../../components/FindPatientModal/FindPatientModal";
import FindPatientComparisonModal from "../../components/FindPatientComparisonModal/FindPatientComparisonModal";

export default function ClinicalChecks() {
  const store = useStore();
  const location = useLocation();
  const serviceType = location.state && location?.state?.serviceType;
  const { appState, clincicalChecksState, getClinicalChecksLoader } =
    useTitanApp();
  const { isAppLoading, darkMode, currentTeamId } = appState || {};
  const {
    isPatient,
    patientName,
    prescriptions,
    clinicalChecksPharmacy,
    patientMedication,
    prescriptionChecks,
    prescriptionCheckGroups,
    checkNowModel,
    selectedBucketType,
    isTherapyModal,
    openFindPatientModal,
    openFindPatientComparisonModal,
  } = clincicalChecksState || {};
  const params = useParams();
  const { pharmacyId } = params || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [
    comingFromFindPatientComparisonModal,
    setComingFromFindPatientComparisonModal,
  ] = useState(false);

  function resetControl() {
    setSeverityCount(0);
    setItemChecks([]);
    setMedicineWarning([]);
    setPatientImage([]);
    setNotes([]);
    setCurrentClinicalChecksNotes([]);
    setIsParked(false);
  }

  const next = async () => {
    try {
      setAppLoading(true);
      resetControl();
      getNextPrescriptionId();
    } catch {
      setAppLoading(false);
    }
  };

  const handleDropdownChange = (e) => {
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      "",
      "",
      0,
      "",
      false,
      "",
      false,
      0,
      false,
      "",
      false,
      "",
      false,
      "",
      "",
      false,
      e.value,
      0,
      null,
      false,
      null
    );
    setCheckNowModel(newCheckNowModel);
    setSelectedBucketType(e.value);
  };

  const getNextPrescriptionId = async () => {
    var prescriptionIds = await getPrescriptionIncomingIds({
      pharmacyId,
      currentTeamId,
    });
    if (prescriptionIds.length > 0) {
      let prescriptionIncomingId = prescriptionIds[0].incomingID;
      let pharmacyID = prescriptionIds[0]?.pharmacyID;
      getNextClinicalCheckResult(prescriptionIncomingId, pharmacyID);
    } else {
      setAppLoading(false);
      return null;
    }
  };

  const getNextClinicalCheckResult = async (
    prescriptionIncomingId,
    pharmacyID
  ) => {
    const prescriptionData = await getPrescriptionDataByIncomingId(
      prescriptionIncomingId,
      pharmacyID
    );
    setPatientImage([]);
    if (prescriptionData && prescriptionIncomingId != null) {
      await getPatientMedicationData();
      await getPrescriptionItemCheckData();
      await getPrescriptionChecksData();
      await getClinicalChecksPatientImageData();
    }
    setAppLoading(false);
  };

  const getPrescriptionDataByIncomingId = async (
    prescriptionIncomingId,
    pharmacyID
  ) => {
    const data = getPrescriptionByIncomingId({
      prescriptionIncomingId,
      pharmacyID,
    });
    return data;
  };
  const getPatientMedicationData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { clinicalChecksPharmacy, patientId } =
      currentClinicalChecksState || {};

    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    getPatientMedication({ patientId, pharmacyID });
  };
  const getPrescriptionItemCheckData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { clinicalChecksPharmacy, prescriptions } =
      currentClinicalChecksState || {};
    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const prescriptionItemChecksBody = new setPrescriptionItemChecks(
      prescriptions,
      0,
      "",
      "",
      false,
      ""
    ).obj;
    getPrescriptionItemCheck({
      prescriptionItemChecksBody,
      pharmacyID,
    });
  };
  const getPrescriptionChecksData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { clinicalChecksPharmacy, prescriptions } =
      currentClinicalChecksState || {};
    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const prescriptionCheckBody = new setPrescriptionChecks(prescriptions, "");
    getPrescriptionChecks({
      prescriptionCheckBody,
      pharmacyID,
    });
  };

  const getClinicalChecksPatientImageData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { prescriptions, clinicalChecksPharmacy } =
      currentClinicalChecksState || {};
    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const prescriptionImages = prescriptions?.prescriptionImages;
    const resultArray = [];
    if (prescriptionImages) {
      for (const imageValue of prescriptionImages) {
        const result = await getPatientImage({
          imageValue,
          pharmacyID,
        });
        resultArray.push(`data:image/png;base64, ${result}`);
      }
      if (resultArray?.length > 0) {
        setPatientImage(resultArray);
      } else {
        setPatientImage([]);
      }
    }
  };

  function isValidateChecks() {
    let allMatch = [];
    const prescriptionCheckActions = checkNowModel?.prescriptionCheckActions;
    const filteredChecks = prescriptionChecks.filter(
      (check) =>
        check?.clinicalCheckId !== 3 &&
        check?.clinicalCheckId !== 4 &&
        check?.clinicalCheckId !== 2
    );

    const matchPrescriptionChecks = filteredChecks.every((check) =>
      prescriptionCheckActions.some(
        (action) =>
          action?.clinicalCheckId === check?.clinicalCheckId &&
          check?.clinicalCheckOptions.some(
            (option) =>
              option?.clinicalCheckOptionId === action?.clinicalCheckOptionId
          )
      )
    );

    allMatch.push(matchPrescriptionChecks);

    prescriptions?.prescriptionItems?.map((drug, index) => {
      const matchingItem = checkNowModel?.prescriptionItems.find(
        (item) => item?.prescribedItemId === drug?.prescribedItemId
      );
      const flattenedCheckGroups = prescriptionCheckGroups.flat();

      const { prescriptionItemCheckActions } = matchingItem;
      if (
        !flattenedCheckGroups.find(
          (item) => item?.prescribedItemId === drug?.prescribedItemId
        )
      ) {
        allMatch.push(true);
      } else if (
        flattenedCheckGroups.find(
          (item) => item?.prescribedItemId === drug?.prescribedItemId
        ) &&
        prescriptionItemCheckActions?.length === 0
      ) {
        allMatch.push(false);
      } else {
        const matchItemChecks = flattenedCheckGroups
          .filter((group) => group?.prescribedItemId === drug?.prescribedItemId)
          .every((group) =>
            prescriptionItemCheckActions.some(
              (action) =>
                action?.clinicalCheckId === group?.clinicalCheckId &&
                group?.clinicalCheckOptions.some(
                  (option) =>
                    option?.clinicalCheckOptionId ===
                    action?.clinicalCheckOptionId
                )
            )
          );

        allMatch.push(matchItemChecks);
      }
    });

    const result = allMatch.every((value) => value === true);
    return !result;
  }

  function onOpen() {
    setTheraphyModal(true);
  }
  return (
    <div>
      {isAppLoading === false ? (
        isPatient === true ? (
          <div>
            <div>
              <div className="page-content clinicalchecks">
                <BreadCrumbNMS serviceType={serviceType} type="Checks" />
                <div className="main-div">
                  <h2 className="main-div-heading">Data Entry</h2>
                  <div className="main-div-inner">
                    <DrugMatchModal submit={next} />
                    <PatientModal submit={next} />
                    <CheckfoundModal submit={next} />
                    {/* <SeverityModal /> */}
                    <TherapyModalComponent />
                    <div className="image-div">
                      <div className="prescription-image-header">
                        <div>
                          <h2 className="img-heading">
                            <svg
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0ZM4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4C10 5.65685 8.65685 7 7 7C5.34315 7 4 5.65685 4 4Z"
                                fill={darkMode ? "#fff" : "#222"}
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.00873 9C0.903151 9 0 9.88687 0 11C0 12.6912 0.83281 13.9663 2.13499 14.7966C3.41697 15.614 5.14526 16 7 16C8.85474 16 10.583 15.614 11.865 14.7966C13.1672 13.9663 14 12.6912 14 11C14 9.89557 13.1045 9.00001 12 9.00001L2.00873 9ZM1 11C1 10.4467 1.44786 10 2.00873 10L12 10C12.5522 10 13 10.4478 13 11C13 12.3088 12.3777 13.2837 11.3274 13.9534C10.2568 14.636 8.73511 15 7 15C5.26489 15 3.74318 14.636 2.67262 13.9534C1.62226 13.2837 1 12.3088 1 11Z"
                                fill={darkMode ? "#fff" : "#222"}
                              />
                            </svg>
                            {patientName}
                          </h2>
                        </div>
                        <div className="bucket-dropdown-class">
                          <Dropdown
                            onChange={(e) => handleDropdownChange(e)}
                            options={bucketTypes}
                            value={selectedBucketType}
                          />
                        </div>
                      </div>

                      <ImageSlider />
                    </div>
                    <div className="content-div">
                      <PrescriptionHeader />
                      <PatientProfile />

                      {prescriptions?.prescriptionItems?.map((item, index) => {
                        if (patientMedication?.patientMedications !== null) {
                          if (
                            patientMedication?.patientMedications?.length !== 0
                          ) {
                            if (
                              item?.prescribedItemId ===
                              patientMedication?.patientMedications[index]
                                ?.prescribedItemId
                            ) {
                              setMedicationId(true);
                            }
                          } else {
                            setMedicationId(false);
                          }
                        } else {
                          setMedicationId(false);
                        }
                        return (
                          <>
                            <PrescribedMedicinesTheraphy
                              isTherapyModal={onOpen}
                              key={index}
                              itemInfo={
                                patientMedication?.patientMedications !== null
                                  ? patientMedication?.patientMedications
                                      ?.length !== 0
                                    ? patientMedication?.patientMedications[
                                        index
                                      ]
                                    : null
                                  : null
                              }
                              medicineValues={item}
                              number={index + 3}
                              PrescribedItemId={item?.prescribedItemId}
                              PrescriptionItemId={item?.prescriptionItemId}
                              prescribedIndex={index}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="action-footer">
                  <ClinicalActions
                    submit={next}
                    isValidateChecks={isValidateChecks}
                  />
                </div>

                {openFindPatientModal && (
                  <FindPatientModal
                    comingFromFindPatientComparisonModal={
                      comingFromFindPatientComparisonModal
                    }
                    selectedPatient={selectedPatient}
                    setSelectedPatient={setSelectedPatient}
                    setComingFromFindPatientComparisonModal={
                      setComingFromFindPatientComparisonModal
                    }
                  />
                )}

                {openFindPatientComparisonModal && (
                  <FindPatientComparisonModal
                    selectedPatient={selectedPatient}
                    setComingFromFindPatientComparisonModal={
                      setComingFromFindPatientComparisonModal
                    }
                  />
                )}
              </div>
              Comparison
            </div>
          </div>
        ) : (
          <NopatientPopup screen="Clinical checks" />
        )
      ) : (
        <LoadingComponentCC />
      )}
    </div>
  );
}

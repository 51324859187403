import React from "react";

export default function OrderingTooltipHeader({ data }) {
  return (
    <div className="ordering-tooltip-header">
      <h5>{data?.itemName}</h5>
      <p>
        ({data?.preferredSupplier}): {data?.packSize} packs
      </p>
    </div>
  );
}

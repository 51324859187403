import React from "react";
import { ReactComponent as BadgeIcon } from "./../../../../../../src/assets/images/badge-icon.svg";
import { Skeleton } from "@mui/material";
import useTitanApp from "../../../../../hooks/useTitanApp";

export default function OrbitAgencyAvgTime() {
  const { appState, orbitState } = useTitanApp();
  const { darkMode } = appState || {};
  const { isOtherDetailsLoading, otherDetails } = orbitState || {};
  const { averageTime } = otherDetails || {};

  function timeCheck(timeVal) {
    if (timeVal === "NaN minutes") {
      return "0 mins";
    } else {
      return timeVal;
    }
  }
  return (
    <div className="orbit_box">
      <div className="orbit_label">
        <BadgeIcon />
        <p>Avg Time</p>
      </div>
      {isOtherDetailsLoading ? (
        <Skeleton
          sx={{ bgcolor: darkMode ? "grey.800" : "" }}
          height={30}
          width={160}
          animation="wave"
          variant="rounded"
          style={{ marginTop: "10px" }}
        />
      ) : (
        <p className="orbit_value">{timeCheck(averageTime)}</p>
      )}
    </div>
  );
}

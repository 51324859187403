import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import { setConfirmationCancellationDetails } from "../../../../../../../../../../dispatchers/typeDispatchers";

export default function ProductTypesConfirmCancellation() {
  const { typeState } = useTitanType();
  const {
    clinicalServiceBuilderModel,
    defaultClinicalServiceBuilderModel,
    messageTemplates,
  } = typeState;

  const productDetails =
    clinicalServiceBuilderModel?.clinicalServiceBuilder?.product ??
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder?.product ??
    {};
  const { confirmationCancellation } = productDetails;
  const {
    emailCancellation,
    emailConfirmation,
    smsCancellation,
    smsConfirmation,
  } = confirmationCancellation;
  // Default to an empty array if messageTemplates is undefined or null

  const [emailConfirmationValue, setEmailConfirmation] = useState(
    emailConfirmation ?? ""
  );
  const [emailCancellationValue, setEmailCancellation] = useState(
    emailCancellation ?? ""
  );
  const [smsConfirmationValue, setSmsConfirmation] = useState(
    smsConfirmation ?? ""
  );
  const [smsCancellationValue, setSmsCancellation] = useState(
    smsCancellation ?? ""
  );

  const handleChange = (setter, field) => (event) => {
    const value = event.target.value;
    setter(value);
    // Prepare the payload for confirmationCancellation update
    const payload = {
      emailConfirmation:
        field === "emailConfirmation" ? value : emailConfirmationValue,
      emailCancellation:
        field === "emailCancellation" ? value : emailCancellationValue,
      smsConfirmation:
        field === "smsConfirmation" ? value : smsConfirmationValue,
      smsCancellation:
        field === "smsCancellation" ? value : smsCancellationValue,
    };
    // Dispatch the action to update the Redux store
    setConfirmationCancellationDetails(payload);
  };

  // Safely filter templates by actionType
  const emailConfirmationTemplates =
    messageTemplates?.filter((template) => template?.actionType === 0) ?? [];
  const emailCancellationTemplates =
    messageTemplates?.filter((template) => template?.actionType === 2) ?? [];
  const smsConfirmationTemplates =
    messageTemplates?.filter((template) => template?.actionType === 3) ?? [];
  const smsCancellationTemplates =
    messageTemplates?.filter((template) => template?.actionType === 1) ?? [];

  return (
    <div className="privateservices_two_layout">
      <div className="d-flex">
        <div className="membersAddNew_box">
          <FormControl fullWidth>
            <InputLabel id="email-confirmation-label">
              Email Confirmation
            </InputLabel>
            <Select
              labelId="email-confirmation-label"
              id="email-confirmation-select"
              value={emailConfirmationValue}
              onChange={handleChange(setEmailConfirmation, "emailConfirmation")}
              className="role-select"
            >
              {emailConfirmationTemplates.map((template) => (
                <MenuItem key={template?._id} value={template?.templateName}>
                  {template?.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="membersAddNew_box">
          <FormControl fullWidth>
            <InputLabel id="email-cancellation-label">
              Email Cancellation
            </InputLabel>
            <Select
              labelId="email-cancellation-label"
              id="email-cancellation-select"
              value={emailCancellationValue}
              onChange={handleChange(setEmailCancellation, "emailCancellation")}
              className="role-select"
            >
              {emailCancellationTemplates.map((template) => (
                <MenuItem key={template?._id} value={template?.templateName}>
                  {template?.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="d-flex">
        <div className="membersAddNew_box">
          <FormControl fullWidth>
            <InputLabel id="sms-confirmation-label">
              SMS Confirmation
            </InputLabel>
            <Select
              labelId="sms-confirmation-label"
              id="sms-confirmation-select"
              value={smsConfirmationValue}
              onChange={handleChange(setSmsConfirmation, "smsConfirmation")}
              className="role-select"
            >
              {smsConfirmationTemplates.map((template) => (
                <MenuItem key={template?._id} value={template?.templateName}>
                  {template?.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="membersAddNew_box">
          <FormControl fullWidth>
            <InputLabel id="sms-cancellation-label">
              SMS Cancellation
            </InputLabel>
            <Select
              labelId="sms-cancellation-label"
              id="sms-cancellation-select"
              value={smsCancellationValue}
              onChange={handleChange(setSmsCancellation, "smsCancellation")}
              className="role-select"
            >
              {smsCancellationTemplates.map((template) => (
                <MenuItem key={template?._id} value={template?.templateName}>
                  {template?.templateName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

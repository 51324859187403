import React from "react";
import "./../../assets/css/FindPatientComparisonModal.scss";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../controls/CustomButton";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import useTitanApp from "../../hooks/useTitanApp";
import { PiWarningBold } from "react-icons/pi";
import setPrescriptionChecks from "../../Model/PrescriptionChecks/setPrescriptionChecks";
import setPrescriptionItemChecks from "../../Model/PrescriptionItemChecks/setPrescriptionItemChecks";
import {
  getPrescriptionChecks,
  getPrescriptionItemCheck,
  setCheckNowModel,
  setOpenFindPatientComparisonModal,
  setOpenFindPatientModal,
} from "../../dispatchers/clinicalChecksDispatchers";
import setAddPatient from "../../Model/AddPatient/setAddPatient";
import setCheckNow from "../../Model/CheckNow/setCheckNow";

export default function FindPatientComparisonModal({
  selectedPatient,
  setComingFromFindPatientComparisonModal,
}) {
  const { clincicalChecksState } = useTitanApp();
  const {
    openFindPatientComparisonModal,
    prescriptions,
    clinicalChecksPharmacy,
    checkNowModel,
    selectedBucketType,
  } = clincicalChecksState || {};
  const { patient } = prescriptions || {};
  const { pharmacyID } = clinicalChecksPharmacy || {};

  const handleCloseFindPatientComparisonModal = () => {
    setOpenFindPatientComparisonModal(false);
  };

  const handleGoBackToFindPatientModal = () => {
    setOpenFindPatientComparisonModal(false);
    setOpenFindPatientModal(true);
    setComingFromFindPatientComparisonModal(true);
  };

  const patientAddress =
    patient?.preferredAddress?.address?.houseName +
    "," +
    patient?.preferredAddress?.address?.streetName +
    "," +
    patient?.preferredAddress?.address?.postcode;

  const patientStreetName = patient?.preferredAddress?.address?.streetName;
  const patientPostcode = patient?.preferredAddress?.address?.postcode;

  const handleYesAction = () => {
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      "",
      "",
      0,
      "",
      false,
      "",
      false,
      0,
      false,
      "",
      false,
      "",
      false,
      "",
      "",
      false,
      selectedBucketType,
      0,
      null,
      false,
      selectedPatient?.patientId
    );
    setCheckNowModel(newCheckNowModel);
    const prescriptionCheckBody = new setPrescriptionChecks(
      prescriptions,
      selectedPatient?.patientId
    );
    const prescriptionItemChecksBody = new setPrescriptionItemChecks(
      prescriptions,
      0,
      "",
      "",
      false,
      selectedPatient?.patientId
    ).obj;

    try {
      getPrescriptionChecks({
        prescriptionCheckBody,
        pharmacyID,
      });
      getPrescriptionItemCheck({
        prescriptionItemChecksBody,
        pharmacyID,
      });

      setOpenFindPatientModal(false);
      setOpenFindPatientComparisonModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      size="lg"
      show={openFindPatientComparisonModal}
      onHide={() => handleCloseFindPatientComparisonModal()}
      backdrop="static"
      centered
      dialogClassName="cc-find-patient-comparison-modal"
      contentClassName="cc-find-patient-comparison-modal-content"
      animation={false}
    >
      <Modal.Header>
        <p className="heading">
          <PiWarningBold size={22} /> Warning
        </p>
        <p className="sub-desc">
          You have selected a patient that does not fully match the details on
          the prescription. <br /> Do you want to link this prescripton to this
          local record and update their details to match the prescription?
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className="comparison-table">
          <div className="header-row">
            <h5>Patient on prescription</h5>
            <h5>Titan Patient</h5>
          </div>
          <div className="body-rows">
            <div className="comp-row">
              <div>
                <label>Title</label>
                <p>{patient.title ? patient.title : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.title === patient.title
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.title === patient.title ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Title</label>
                <p>{selectedPatient.title ? selectedPatient.title : ""}</p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Forename</label>
                <p>{patient.forename ? patient.forename : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.forename === patient.forename
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.forename === patient.forename ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Forename</label>
                <p>
                  {selectedPatient.forename ? selectedPatient.forename : ""}
                </p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Surname</label>
                <p>{patient.surname ? patient.surname : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.surname === patient.surname
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.surname === patient.surname ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Surname</label>
                <p>{selectedPatient.surname ? selectedPatient.surname : ""}</p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Date of birth</label>
                <p>
                  {patient.dob
                    ? new Date(patient.dob).toLocaleDateString("en-GB")
                    : ""}
                </p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    new Date(selectedPatient.dob).toLocaleDateString(
                      "en-GB"
                    ) === new Date(patient.dob).toLocaleDateString("en-GB")
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {new Date(selectedPatient.dob).toLocaleDateString("en-GB") ===
                  new Date(patient.dob).toLocaleDateString("en-GB") ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Date of birth</label>
                <p>
                  {selectedPatient.dob
                    ? new Date(selectedPatient.dob).toLocaleDateString("en-GB")
                    : ""}
                </p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>NHS Number</label>
                <p>{patient.nhsNumber ? patient.nhsNumber : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.nhsNumber === patient.nhsNumber
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.nhsNumber === patient.nhsNumber ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>NHS Number</label>
                <p>
                  {selectedPatient.nhsNumber ? selectedPatient.nhsNumber : ""}
                </p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Sex</label>
                <p>
                  {patient.sex ? (patient.sex === 1 ? "Male" : "Female") : "-"}
                </p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.sex === patient.sex
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.sex === patient.sex ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Sex</label>
                <p>
                  {selectedPatient.sex
                    ? selectedPatient.sex === 1
                      ? "Male"
                      : "Female"
                    : ""}
                </p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Address</label>
                <p>{patientAddress ? patientAddress : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.address.replace(/\s*,\s*/g, ",") ===
                    patientAddress
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.address.replace(/\s*,\s*/g, ",") ===
                  patientAddress ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Address</label>
                <p>
                  {selectedPatient.address
                    ? selectedPatient.address.replace(/\s*,\s*/g, ",")
                    : ""}
                </p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Street Name</label>
                <p>{patientStreetName ? patientStreetName : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.streetName === patientStreetName
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.streetName === patientStreetName ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Street Name</label>
                <p>
                  {selectedPatient.streetName ? selectedPatient.streetName : ""}
                </p>
              </div>
            </div>
            <div className="comp-row">
              <div>
                <label>Postcode</label>
                <p>{patientPostcode ? patientPostcode : ""}</p>
              </div>
              <div>
                <div
                  className={`decision-box ${
                    selectedPatient.postcode === patientPostcode
                      ? "matched"
                      : "not-matched"
                  }`}
                >
                  {selectedPatient.postcode === patientPostcode ? (
                    <IoCheckmarkOutline size={20} />
                  ) : (
                    <IoCloseOutline size={20} />
                  )}
                </div>
              </div>
              <div>
                <label>Postcode</label>
                <p>
                  {selectedPatient.postcode ? selectedPatient.postcode : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton label="No" action={handleGoBackToFindPatientModal} />
        <CustomButton label="Yes" action={handleYesAction} />
      </Modal.Footer>
    </Modal>
  );
}

import React from "react";
import { GoArrowLeft } from "react-icons/go";
import CustomButton from "./../../../../../../controls/CustomButton";
import TypesDetail from "../TypesAddNew/components/TypesDetail";
import TypesStages from "../TypesAddNew/components/TypesStages";
import TypesConfirmCancellation from "../TypesAddNew/components/TypesConfirmCancellation";
import TypesLocations from "../TypesAddNew/components/TypesLocations";
import { showError } from "../../../../../../ToastedMessage/ToastedMessage";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import AsyncRendererRouter from "../../../../../../AsyncRendererRouter/AsyncRendererRouter";
import useTitanType from "../../../../../../../hooks/useTitanType";
import { useLoaderData, useNavigate } from "react-router";
import SettingsSidebar from "../../../../../../SettingsSidebar/SettingsSidebar";
import CustomLoader from "../../../../../../controls/CustomLoader";
import { updateServiceType } from "../../../../../../../dispatchers/typeDispatchers";

export default function TypesUpdate() {
  const navigate = useNavigate();
  const { typeState } = useTitanType();
  const {
    isTypeLoading,
    currentServiceType,
    currentStages,
    currentPharmacyIds,
  } = typeState || {};
  const { appState } = useTitanApp();
  const { companyId } = appState || {
    companyId: "00000000-0000-0000-0000-000000000000",
  };
  async function handleSaveService() {
    const differenceArray = currentServiceType?.pharmacyIds?.filter(
      (id) => !currentPharmacyIds.includes(id)
    );
    const saveObj = {
      pharmaciesToUpdate: currentPharmacyIds,
      pharmaciesToRemove: differenceArray,
      serviceModel: currentServiceType?.serviceModel,
      clinicalServiceBuilder: {
        serviceName: currentServiceType?.serviceModel?.serviceName,
        stages: currentStages,
        numberOfStages: currentStages?.length || 0,
        serviceType: 2,
        companyID: companyId,
        isDefault: false,
        serviceID: "",
        serviceCode: 0,
      },
    };
    const savedType = updateServiceType(saveObj);
    savedType.then((result) => {
      if (result?.success === true) {
        navigate("/settings/services/types");
      } else if (result?.success === false) {
        showError("Service could not be saved.", true, false);
      }
    });
  }

  const { appData } = useLoaderData() || {};

  return (
    <AsyncRendererRouter
      isLoading={isTypeLoading}
      resolve={appData}
      loadingElement={
        <div className="settings_page">
          <div className="settings_wrapper">
            <SettingsSidebar />
            <CustomLoader />
          </div>
        </div>
      }
    >
      <div className="settings_page">
        <div className="settings_wrapper">
          <SettingsSidebar />
          <div className="settings_content_wrapper">
            <div className="settings_heading">
              <h1>Services</h1>
            </div>
            <div>
              <div>
                <div
                  className="back_to_SettingsListView"
                  onClick={() => {
                    navigate("/settings/services/types");
                  }}
                >
                  <GoArrowLeft className="icons-color" />
                  <p>Back</p>
                </div>
                {currentServiceType ? (
                  <>
                    <div className="addNewMember_wrapper"></div>
                    <div className="addMember_formFields">
                      <div className="inner-panels">
                        <div className="AddNew_sectionbox">
                          <h4>Detail</h4>
                          <TypesDetail />
                        </div>
                        <div className="AddNew_sectionbox">
                          <h4>Stages</h4>
                          <TypesStages />
                        </div>
                        <div className="AddNew_sectionbox">
                          <h4>Confirmation & Cancellation Messages</h4>
                          <TypesConfirmCancellation />
                        </div>
                        <div className="AddNew_sectionbox">
                          <h4>Locations</h4>
                          <TypesLocations />
                        </div>
                        <div className="footer_btn">
                          <CustomButton
                            label="Save Changes"
                            action={handleSaveService}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>No services existing with this Id</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsyncRendererRouter>
  );
}

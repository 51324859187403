import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import TextField from "@mui/material/TextField";
import {
  savePatientContact,
  setCurrentPatientContact,
  setEligibilityStageScrollPosition,
} from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import { isMobileNumberValid } from "../../utils/validMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCurrentEditedPreferredContact } from "../../dispatchers/nmsDispatchers";
import { showError } from "../ToastedMessage/ToastedMessage";
import { getPharmacyServices } from "../../dispatchers/pharmacyServiceDispatchers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";

function PatientContactModal({
  view,
  setShowPatientContactModal,
  setEligibilityScroll,
  contactServiceID,
}) {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { isOrbitUser, darkMode, currentPharmacyId } = appState || {};
  const { currentServiceId, eligibilityStageScrollPosition } =
    pharmacyServiceState || {};
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  // Toast for success messages
  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };

  // Formik with Yup schema
  const formik = useFormik({
    initialValues: {
      telephoneNo: "",
    },
    validationSchema: Yup.object({
      telephoneNo: Yup.string()
        .required("Telephone number is required")
        .test("is-valid-mobile", "Invalid mobile number", (value) =>
          isMobileNumberValid(value)
        ),
    }),
    onSubmit: (values) => {
      setCurrentPatientContact(false);
      savePatientContact(contactServiceID, values.telephoneNo)
        .then((data) => {
          setCurrentEditedPreferredContact(data?.telephoneNumber);
          if (isOrbitUser === false) {
            getPharmacyServices(currentPharmacyId, 0, currentServiceId).then(
              () => {
                showToast("Contact has been updated successfully!");
              }
            );
          }
        })
        .catch((error) => {
          console.error("Error updating contact:", error);
          showError(
            "An error occurred while updating the contact. Please try again."
          );
        });
    },
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      className="max-z"
    >
      <div className="clinical_modal">
        <Modal.Header>
          <ImWarning size={18} color={"red"} />
          <Modal.Title>{view === "nms" ? "Edit" : "Add"} Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="progressmodal_body">
              <TextField
                className="telephone_text"
                error={
                  formik.touched.telephoneNo && !!formik.errors.telephoneNo
                }
                helperText={
                  formik.touched.telephoneNo && formik.errors.telephoneNo
                }
                required
                id="outlined-required"
                label="Telephone"
                value={formik.values.telephoneNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="telephoneNo"
              />
            </div>
          </form>
        </Modal.Body>
        <div className="progressmodal_footer">
          <CustomButton
            label="Cancel"
            action={() => {
              const stageEligible = document.querySelector(
                ".infinite-scroll-Eligible"
              );
              setEligibilityScroll(stageEligible.scrollTop);
              setShowPatientContactModal(false);
              handleClose();
            }}
          />
          <CustomButton label="Save" action={formik.handleSubmit} />
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(PatientContactModal);

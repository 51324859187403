import * as React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import TextField from "@mui/material/TextField";
import {
  setCurrentDeclineModal,
} from "../../dispatchers/pharmacyServiceDispatchers";
import useTitanApp from "../../hooks/useTitanApp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Typography,
} from "@mui/material";
import CustomLoader from "../controls/CustomLoader";

export default function DeclineModal({ handleActions }) {
    const { pharmacyServiceState } = useTitanApp();
    const { isDeclineServiceLoading, isDeclineModal } = pharmacyServiceState || {};
    const [declineReason, setDeclineReason] = useState("");
  
    const handleClose = () => {
      setCurrentDeclineModal(false);
    };
  
    const handleSave = async () => {
      if (!declineReason.trim()) {
        toast.error("Please provide a reason for declining.");
        return;
      }
      await handleActions(declineReason);
      setCurrentDeclineModal(false);
    };
  
    return (
      <>
        <Modal
          show={isDeclineModal}
          onHide={handleClose}
          backdrop="static"
          centered
          className="max-z"
        >
          <div className="clinical_modal send_to_orbit_modal_wrapper">
            <Modal.Body>
              {isDeclineServiceLoading ? (
                <div className="pharmacy-loader">
                  <CustomLoader />
                </div>
              ) : (
                <Box className="modal-s-orbit">
                  <Typography
                    id="modal-title"
                    variant="h6"
                    sx={{ fontSize: "14px", marginBottom: "15px" }}
                  >
                    Are you sure you want to Decline?
                  </Typography>
                  <div>
                    <TextField
                      label="Reason for Decline"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={declineReason}
                      onChange={(e) => setDeclineReason(e.target.value)}
                      sx={{ marginBottom: "15px" }}
                    />
                  </div>
                </Box>
              )}
            </Modal.Body>
            {!isDeclineServiceLoading && (
            <div className="progressmodal_footer">
              <CustomButton label="Cancel" action={handleClose} />
              <CustomButton label="Save" action={handleSave} />
            </div>
          )}
          </div>
        </Modal>
      </>
    );
  }
  

const config = {
  localurl: process.env.REACT_APP_LOCAL_URL,
  voiceJwtServerUrl: process.env.REACT_APP_VOICE_JWT_Server_URL,
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  audience: process.env.REACT_APP_AUDIENCE,
  chatAppId: process.env.REACT_APP_CHAT_APP_ID,
  environmentName: process.env.NODE_ENV,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  yotiUrl: process.env.REACT_APP_YOTI_URL,
  externalurl: process.env.REACT_APP_EXTERNAL_URL,
  hotjarId: process.env.REACT_APP_HOTJAR_ID,
  hotjarVersion: process.env.REACT_APP_HOTJAR_VERSION,
  intercomId: process.env.REACT_APP_INTERCOM,
  chameleonKey: process.env.REACT_APP_CHAMELEON,
  idleTime: process.env.REACT_APP_IDLETIME,
  muiKey: process.env.REACT_APP_MUI_KEY,
  autoCutCallTimeout: process.env.REACT_APP_AUTO_CALL_TIMEOUT,
  autoCutCallShowWarningTimeout:
    process.env.REACT_APP_AUTO_CALL_SHOW_WARNING_TIMEOUT,
  userName: process.env.REACT_APP_USER_NAME,
  firstName: process.env.REACT_APP_USER_FIRST_NAME,
  lastName: process.env.REACT_APP_USER_LAST_NAME,
  emailAddress: process.env.REACT_APP_EMAIL_ADDRESS,
  bookingApplication: process.env.REACT_APP_BOOKING_APP_URL,
};
export default config;

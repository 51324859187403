import React from "react";
import OrbitAgencyWorkPlannerInfo from "./components/OrbitAgencyWorkPlannerInfo";
import OrbitAgencyWorkPlannerCalendar from "./components/OrbitAgencyWorkPlannerCalendar";

export default function OrbitAgencyWorkPlanner() {
  return (
    <div className="orbit-agency-work-planner-wrapper">
      <OrbitAgencyWorkPlannerInfo />
      <OrbitAgencyWorkPlannerCalendar />
    </div>
  );
}

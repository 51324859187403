import React, { useEffect, useState, useRef, useMemo } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import useTitanApp from "../../../../../hooks/useTitanApp";
import LoadingComponentEarnings from "../../../../../components/LoadingComponentOrbit/LoadingComponentEarnings";
import OrbitLiveWorkLoadSkeleton from "./OrbitLiveWorkLoadSkeleton";
import Checkbox from "@mui/material/Checkbox";
import {
  addExclusivePharmacy,
  getOthersAvailable,
  getOrbitAgencyPharmacies,
} from "../../../../../dispatchers/orbitDispatchers";
import CustomButton from "../../../../../components/controls/CustomButton";

// Utility functions for data formatting
const formatPharmacyData = (pharmacies) =>
  pharmacies?.map((item) => ({
    pharmacyID: item.pharmacyID,
    pharmacyName: item.pharmacyName,
    nms: item.nmsCount,
    consented: `£${item.consentedRate}`,
    unconsented: `£${item.unConsentedRate}`,
  })) || [];

function OrbitAgencyLiveWorkLoad() {
  const gridApiRef = useRef(null);
  const { appState, orbitState } = useTitanApp();
  const { darkMode, currentTeam } = appState;
  const { companyID } = currentTeam;
  const {
    orbitAgencyPharmacies,
    orbitAgencyPharmaciesLoader,
    othersAvailable,
    othersAvailableLoader,
  } = orbitState;

  const [tabValue, setTabValue] = useState("exclusiveyours");
  const [pharmacyIds, setpharmacyIds] = useState([]);

  const exclusiveYoursData = useMemo(
    () => formatPharmacyData(orbitAgencyPharmacies),
    [orbitAgencyPharmacies]
  );

  const othersAvailableData = useMemo(
    () => formatPharmacyData(othersAvailable),
    [othersAvailable]
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCheckboxChange = (pharmacyId) => {
    setpharmacyIds((prevSelected) =>
      prevSelected.includes(pharmacyId)
        ? prevSelected.filter((id) => id !== pharmacyId)
        : [...prevSelected, pharmacyId]
    );
  };

  const handleSave = () => {
    addExclusivePharmacy({ companyID, pharmacyIds })
      .then((result) => {
        if (result) {
          getOthersAvailable();
          getOrbitAgencyPharmacies({
            companyId: companyID,
            workLoadType: "1",
          });
          setpharmacyIds([]);
        }
      })
      .catch((error) => {
        console.error("Error adding exclusive pharmacy:", error);
      });
  };

  const columnDefs = [
    {
      headerName: "Pharmacy",
      field: "pharmacyName",
      minWidth: 120,
      flex: 1,
      cellRenderer: ({ data }) => {
        const { pharmacyID, pharmacyName } = data;
        if (tabValue === "othersavailable") {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={pharmacyIds.includes(pharmacyID)}
                  onChange={() => handleCheckboxChange(pharmacyID)}
                  size="small"
                />
                <span
                  style={{ textDecoration: "underline", marginLeft: "10px" }}
                >
                  {pharmacyName}
                </span>
              </div>
            </div>
          );
        }

        return (
          <span style={{ textDecoration: "underline" }}>{pharmacyName}</span>
        );
      },
      suppressMovable: true,
    },
    {
      headerName: "NMS",
      field: "nms",
      minWidth: 120,
      flex: 1,
      cellRenderer: ({ value }) => (
        <p style={{ margin: 0, color: "#999999" }}>{value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Consented",
      field: "consented",
      minWidth: 120,
      flex: 1,
      cellRenderer: ({ value }) => (
        <p style={{ margin: 0, color: "#999999" }}>{value}</p>
      ),
      suppressMovable: true,
    },
    {
      headerName: "Unconsented",
      field: "unconsented",
      minWidth: 120,
      flex: 1,
      cellRenderer: ({ value }) => (
        <p style={{ margin: 0, color: "#999999" }}>{value}</p>
      ),
      suppressMovable: true,
    },
  ];
  const rowData =
    tabValue === "exclusiveyours" ? exclusiveYoursData : othersAvailableData;

  return (
    <div className="orbit_box agency_max-height orbit_workload ">
      <div className="orbit_label_alt">
        <p className="agency-report-heading">Live WorkLoad</p>
      </div>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="orbit-agency-tab"
      >
        <TabContext value={tabValue}>
          <Box sx={{ marginBottom: "20px" }}>
            <TabList onChange={handleChange}>
              <Tab
                label={
                  <span className={`tabs_text_alt`}>Exclusively Yours</span>
                }
                value="exclusiveyours"
                className="para-color"
              />
              <Tab
                label={
                  <span className={`tabs_text_alt`}>Others Available</span>
                }
                value="othersavailable"
                className="para-color"
              />
            </TabList>
          </Box>
          {orbitAgencyPharmaciesLoader ? (
            <OrbitLiveWorkLoadSkeleton />
          ) : rowData.length > 0 ? (
            <div
              className={`orbit-ag-grid ${
                darkMode
                  ? "ag-theme-alpine-dark orbit_agency-ag"
                  : "ag-theme-alpine orbit_agency-ag"
              }`}
            >
              <AgGridReact
                ref={gridApiRef}
                scrollbarWidth={3}
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={(params) => {
                  gridApiRef.current = params.api;
                  params.api[
                    orbitAgencyPharmaciesLoader
                      ? "showLoadingOverlay"
                      : "hideOverlay"
                  ]();
                }}
              />
            </div>
          ) : (
            <div>
              <p className="orbit-no-data-note t-center">No data available</p>
            </div>
          )}
        </TabContext>
        {tabValue === "othersavailable" && !othersAvailableLoader && (
          <>
            {pharmacyIds.length > 0 && (
              <div className="workload-save">
                <CustomButton label={"Save"} action={handleSave} />
              </div>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default OrbitAgencyLiveWorkLoad;

export const changeTemplateNameHandler = (e, setTemplateName, setIsTemplateNameLengthExceeded) => {
    setIsTemplateNameLengthExceeded(false);
    const { value } = e.target;
    if (value.length <= 50) {
      setTemplateName(value);
    } else {
      setIsTemplateNameLengthExceeded(true);
    }
  };
export const changeSubjectHandler = (e, setEmailSubject, setIsSubjectLengthExceeded) => {
    setIsSubjectLengthExceeded(false);
    const { value } = e.target;
    if (value.length <= 50) {
        setEmailSubject(value);
    } else {
        setIsSubjectLengthExceeded(true);
    }
  };
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { CustomAntSwitch } from "../../../../../../../../../controls/CustomAntSwitch";
import useTitanApp from "../../../../../../../../../../hooks/useTitanApp";
import useTitanType from "../../../../../../../../../../hooks/useTitanType";
import { setProductDetails } from "../../../../../../../../../../dispatchers/typeDispatchers";

export default function ProductTypesDetail() {
  const { appState } = useTitanApp();
  const { typeState } = useTitanType();
  const { clinicalServiceBuilderModel, defaultClinicalServiceBuilderModel } =
    typeState;

  const productDetails =
    clinicalServiceBuilderModel?.clinicalServiceBuilder?.product?.detail ??
    defaultClinicalServiceBuilderModel?.clinicalServiceBuilder?.product
      ?.detail ??
    {};

  const [serviceName, setServiceName] = useState(productDetails?.name ?? "");
  const [serviceCharges, setServiceCharges] = useState(
    productDetails?.price ?? ""
  );
  const [isOnlineBooking, setIsOnlineBooking] = useState(
    productDetails?.isPublicCalendar ?? true
  );
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === "serviceName") {
      setServiceName(value);
    } else if (name === "serviceCharges") {
      setServiceCharges(value);
    }

    // Update the product detail when either field changes
    setProductDetails({
      name: name === "serviceName" ? value : serviceName,
      price: name === "serviceCharges" ? value : serviceCharges,
      isPublicCalendar: isOnlineBooking,
    });
  };

  const handleSwitchChange = (event) => {
    const checked = event.target.checked;
    setIsOnlineBooking(checked);

    // Update the product detail when the switch changes
    setProductDetails({
      name: serviceName,
      price: serviceCharges,
      isPublicCalendar: checked,
    });
  };

  return (
    <div className="privateservices_two_layout">
      <div className="settings_formFields">
        <TextField
          id="service-name"
          label="Name"
          variant="outlined"
          name="serviceName"
          fullWidth
          value={serviceName || ""}
          onChange={handleTextFieldChange}
        />

        <TextField
          id="service-charges"
          label="Price"
          variant="outlined"
          name="serviceCharges"
          fullWidth
          type="number"
          value={serviceCharges}
          onChange={handleTextFieldChange}
        />
      </div>
      <div className="d-flex">
        <p>Show in public calendar</p>
        <CustomAntSwitch
          name="isOnlineBooking"
          checked={isOnlineBooking ?? true}
          onChange={handleSwitchChange}
        />
      </div>
    </div>
  );
}

import * as React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomButton from "../controls/CustomButton";
import "./../../assets/css/PatientModal.css";
import { ImWarning } from "react-icons/im";
import useTitanApp from "../../hooks/useTitanApp";

export default function EmergencySupplyModal({
  isEmergency,
  setIsEmergency,
  handleSubmit,
  currentCheckActionType,
  currentCheckOptionId,
}) {
  const handleClose = () => setIsEmergency(false);

  return (
    <>
      <Modal
        show={isEmergency}
        onHide={handleClose}
        backdrop="static"
        centered
        className="NMS-modal"
      >
        <Modal.Header>
          <ImWarning size={18} color={"red"} />
          <Modal.Title>Reconcile emergency supplies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            There is an outstanding emergency prescription record at the picking
            stage or before with this item on. Do you wish to continue?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton label="No" action={handleClose} />
          <CustomButton
            label="Yes"
            action={() => {
              handleSubmit(currentCheckOptionId, currentCheckActionType);
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

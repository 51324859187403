import React, { useEffect, useState } from "react";
import CustomLabel from "../../../../../../controls/CustomLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import useTitanType from "../../../../../../../hooks/useTitanType";
import {
  updateServiceTemplateControlValue,
  verifyServiceTemplateChecks,
} from "../../../../../../../dispatchers/typeDispatchers";
import { ReactComponent as PositiveCheck } from "../../../../../../../assets/images/positiveTick.svg";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-thumb": {
    width: 15,
    backgroundColor: "yellow",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(9px)",
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: "grey",
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,0.05)",
    boxSizing: "border-box",
  },
}));

const StageContent = ({ stage, stageIndex, templates }) => {
  const [switchStates, setSwitchStates] = useState({});
  const { appState } = useTitanApp();
  const { typeState } = useTitanType();
  const {
    clinicalServiceBuilderModel,
    defaultClinicalServiceBuilderModel,
    isTemplateVerified,
  } = typeState;
  const existingTemplates = clinicalServiceBuilderModel?.templates;
  const handleSwitchChange = (control, sectionIndex, controlIndex, event) => {
    const templateIndex = stageIndex;
    const value = event.target.checked;

    updateServiceTemplateControlValue(
      templateIndex,
      sectionIndex,
      controlIndex,
      value,
      control
    );
  };

  const matchedTemplates = templates.filter(
    (template) => template._id === stage.templateId
  );
  function isTemplateRequired(title) {
    const areRequiredSectionsValid =
      clinicalServiceBuilderModel?.templates?.every((template) => {
        const requiredSections = template.templateSections.filter(
          (section) => section?.isRequired && section?.sectionTitle === title
        );

        return requiredSections.every((section) =>
          section.userControls.some((control) => control?.value === "true")
        );
      });
    return areRequiredSectionsValid;
  }

  return (
    <div className="inner-panels">
      <div className="AddNew_sectionbox">
        {matchedTemplates.map((template, templateIndex) => {
          return (
            <div key={template._id}>
              <p className="service-detail-template-name">
                {template.templateName}
              </p>
              <div className="service-template-sections">
                {template.templateSections?.map((section, sectionIndex) => {
                  // const hasActiveControl = section.userControls?.some(
                  //   (control) => control.value === true
                  // );
                  const hasActiveControl = existingTemplates?.some((template) =>
                    template.templateSections?.some((section) =>
                      section.userControls?.some(
                        (existingControl) => existingControl.value === "true"
                      )
                    )
                  );
                  return (
                    <>
                      {section?.userControls.length > 0 ? (
                        <>
                          {" "}
                          <div
                            className="temp-section"
                            key={`${templateIndex}-${sectionIndex}`}
                            style={{
                              border: !isTemplateRequired(section?.sectionTitle)
                                ? "1px solid red"
                                : "none",
                            }}
                          >
                            <p className="cus-template-title">
                              {section.sectionTitle}
                            </p>

                            {section.userControls?.map(
                              (control, controlIndex) => {
                                const controlExists = existingTemplates?.some(
                                  (template) =>
                                    template.templateSections?.some((section) =>
                                      section.userControls?.some(
                                        (existingControl) =>
                                          existingControl.name ===
                                            control.name &&
                                          existingControl.value === "true"
                                      )
                                    )
                                );
                                return (
                                  <div
                                    className="user-control"
                                    key={controlIndex}
                                  >
                                    <div
                                      className={
                                        control.inline ? "inline-not" : "inline"
                                      }
                                    >
                                      <div className="switch-content-reports">
                                        <CustomLabel name={control.label} />
                                        <div
                                          key={`${templateIndex}-${sectionIndex}-${controlIndex}`}
                                        >
                                          <AntSwitch
                                            disabled={false}
                                            inputProps={{
                                              "aria-label": control.label,
                                            }}
                                            checked={controlExists}
                                            onChange={(event) =>
                                              handleSwitchChange(
                                                control,
                                                sectionIndex,
                                                controlIndex,
                                                event
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StageContent;

import React, { useState, useEffect } from "react";
import "../assets/clinicalwarning.css";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ClinicalCheckActionTypes } from "../Model/ClinicalActions/ClinicalCheckActionTypes";
import { ClinicalCheckIdColor } from "../Model/ClinicalActions/ClinicalCheckIdColor";
import setCheckNow from "../Model/CheckNow/setCheckNow";
import setAddMedication from "../Model/AddMedication/setAddMedication";

import {
  DefaultNotDispensePatientDeceasedCode,
  DefaultNotDispenseOutOfScopeCode,
  DefaultNotDispenseExpiredCode,
  DefaultNotDispenseCancelledCode,
  DefaultClinicallyNotSuitableCode,
} from "../data/constantStrings";
import useTitanApp from "../hooks/useTitanApp";
import {
  setCheckNowModel,
  setSeverityCount,
  setItemReturnDose,
  setEditable,
  setButtonclass,
  setMedicationTime,
  setDispenseArray,
  getPostMedication,
  setMedicineWarning,
} from "../dispatchers/clinicalChecksDispatchers";
import NmsPrescriptionToolTip from "./NmsPrescriptionToolTip/NmsPrescriptionToolTip";
let ErdType = 0;
let itemCrossedOut = false,
  notDispensedReasonCode = "";

function GroupMedicineWarning(props) {
  const [checked, setChecked] = useState("");
  const [isdisabled, setisdisabled] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  let checkDispenseArray = [6, 12, 14, 16, 20];
  const { appState, clincicalChecksState } = useTitanApp();
  const {
    clinicalChecksPharmacy,
    isSkip,
    patientId,
    checkNowModel,
    severityCount,
    itemReturnDose,
    returnQuantity,
    returnDose,
    dispenseArray,
    returnTherapy,
    returnStartDate,
    returnStopDate,
    returnArchive,
    returnPreparation,
    returnMarking,
    returnColor,
    returnReport,
    medicationTime,
    medicineWarning,
    selectedBucketType,
  } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };

  const {
    group,
    PrescriptionItemId,
    medicineValues,
    itemInfo,
    PrescribedItemId,
    autoCheckInfo,
    patientMedicationTherapy,
  } = props || {};
  useEffect(() => {
    group?.map((item) => {
      if (!medicineWarning?.includes(Number(item?.clinicalCheckId))) {
        setChecked("");
      }
    });
  }, [group]);

  async function addTherapy(isInterim) {
    if (returnQuantity !== "" && returnDose !== "") {
      const prescriptionCheckBody = new setAddMedication(
        itemInfo,
        patientMedicationTherapy,
        patientMedicationTherapy?.patientMedicationTime,
        isInterim
      ).obj;

      await getPostMedication({ prescriptionCheckBody, pharmacyID });
    }
  }

  async function handleAction(
    clinicalCheckOptionId,
    clinicalCheckActionType,
    allItems
  ) {
    allItems?.map(async (items) => {
      const { description, checkSeverity, clinicalCheckId, botDetails } =
        items || {};
      setCheckAction(clinicalCheckId, clinicalCheckActionType);

      const PrescriptionItemCheckActions = {
        clinicalCheckId: clinicalCheckId,
        clinicalCheckOptionId: clinicalCheckOptionId,
        prescriptionItemId: PrescriptionItemId,
        fullCheckText: description,
        patientMedicationCheckLearningId: "",
        botDetails: botDetails === null ? "" : botDetails,
        IsClinicalBotDecision: false,
      };
      const newCheckNowModel = new setCheckNow(
        checkNowModel,
        PrescriptionItemCheckActions,
        PrescriptionItemId,
        "",
        0,
        "",
        false,
        "",
        true,
        ErdType,
        itemCrossedOut,
        notDispensedReasonCode,
        false,
        "",
        false,
        "",
        "",
        false,
        selectedBucketType,
        0,
        null,
        false,
        null
      );

      setCSSValues(clinicalCheckOptionId, clinicalCheckId);
      setCheckNowModel(newCheckNowModel);

      checkDispenseArray?.includes(clinicalCheckActionType)
        ? setDispenseArray([...dispenseArray, PrescriptionItemId])
        : setDispenseArray([...dispenseArray, 0]);
      ErdType = 0;
      itemCrossedOut = false;
      notDispensedReasonCode = "";
      if (checkSeverity == 2) {
        // Check If the checks are mandatory
        setSeverityCount(severityCount - allItems.length);
        // handleCallbackRemove();
      }
      switch (clinicalCheckActionType) {
        case 19:
          addTherapy(true);
          break;
        case 24:
          addTherapy(false);
          break;
        case 21:
          addTherapy("");
          break;
        case 22:
          addTherapy("");
          break;
      }
    });
  }
  function setCSSValues(clinicalCheckOptionId, clinicalCheckId) {
    setActiveButton(clinicalCheckOptionId);
    setChecked("-checked");
    setisdisabled(true);
    setMedicineWarning(clinicalCheckId);
  }

  function setCheckAction(CheckId, ActionType) {
    switch (ActionType) {
      case 29: // Add Erd
        ErdType = 1;
        break;
      case 30: // Add MdsErd
        ErdType = 2;
        break;
      case 5: // Accept
      case 28: // Ignore
        switch (CheckId) {
          case 2: //PatientPartialMatch
            // nothing
            // code block
            break;
          case 44: // RepeatTheraphyExist
            //nothing
            // code block
            break;
          case 54: // EpsErd
            ErdType = 3;
            break;
          default:
            break;
        }
        break;
      case 11: // SearchDrug
        // nothing
        // code block
        break;
      case 6: // NotDispenseOutOfScope
      case 12: //NotDispenseExpired
      case 14: //ClinicallyUnsuitable
      case 20: //NotDispenseDrugNotFound
      case 16: //NotDispenseDentist
        switch (CheckId) {
          case 26: //PatientDeceased
            itemCrossedOut = true;
            notDispensedReasonCode = DefaultNotDispensePatientDeceasedCode;
            break;
          default:
            switch (ActionType) {
              case 20: // NotDispenseDrugNotFound
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultNotDispenseCancelledCode;
                break;
              case 6: //NotDispenseOutOfScope
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultNotDispenseOutOfScopeCode;
                break;

              case 12: //NotDispenseExpired
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultNotDispenseExpiredCode;
                break;

              case 14: //ClinicallyUnsuitable
              case 16: //NotDispenseDentist
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultClinicallyNotSuitableCode;
                break;
            }
            break;
        }
        break;
      case 7: //AddPatient
        // nothing
        break;
      case 8: //EditPatient
        // nothing
        break;
      case 9: //SearchPatient
        // nothing
        break;
      case 4: //Remove
        // nothing
        break;
      case 13: //PrescriptionItemValidationCheck
        // code block
        break;
      case 15: //View
        //nothing
        switch (CheckId) {
          case 45:
            //
            break;
          case 44:
            //
            break;
          default:
        }
        break;
      case 17: // AddtoDosage
        setItemReturnDose(
          `${itemReturnDose}. Additonal Info: ${medicineValues?.additionalDosage}`
        );
        // code block
        break;
      case 3: //ChangeQuantity
        setButtonclass(true);
        setEditable(true);
        break;
      case 18: //ReconcileEmergencySupply
        switch (CheckId) {
          case 30: //EmergencySupply
            //
            break;
          case 31: //EmergencySupplyMoreThanRx
            //
            break;
        }
        break;
      case 26: //TreatAsNewPrescription
        // code block
        break;
      case 1000: //ReturnToSpine
        // code block
        break;
      case 21: //UpdateRepeatTherapy
      case 25: //UpdateRepeatQuantity
      case 23: //Restart
        // code block
        // stop date empty in therapy checks
        break;
      case 22: //UseExistingDose
        setItemReturnDose(returnDose);
        break;
      case 24: //AddAsRegular
        // code block
        break;
      case 27: //CreateNMSAlert
        // code block
        break;
      case 19: //AddAsInterim
        switch (CheckId) {
          case 46:
            //
            break;
          default:
        }
        break;
      default:
      // code block
    }
  }
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(panel);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleChange(!expanded)}
        style={{
          marginBottom: "15px",
          marginTop: "15px",
          borderRadius: "15px",
        }}
        className={`p-group-warn-${
          ClinicalCheckIdColor[group[0]?.clinicalCheckId]
        }${checked}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="card-accordion-cont"
        >
          <div className="group_check">
            <div className="group_count">
              <button
                className={`warn-button-${
                  ClinicalCheckIdColor[group[0]?.clinicalCheckId]
                }${checked}`}
              >
                {group[0]?.title}
              </button>
              <button
                className={`warn-button-${
                  ClinicalCheckIdColor[group[0]?.clinicalCheckId]
                }${checked}`}
              >
                {group?.length || 0}
              </button>
            </div>

            <div className="p-message-inner">
              {isSkip ? (
                <></>
              ) : (
                <span>
                  {autoCheckInfo !== null ? (
                    <NmsPrescriptionToolTip autoCheckInfo={autoCheckInfo} />
                  ) : (
                    <></>
                  )}

                  {group[0]?.clinicalCheckOptions?.map((key) => {
                    const isButtonActive =
                      key?.clinicalCheckOptionId === activeButton;
                    return (
                      <button
                        className={`issue-button${
                          isButtonActive ? "-active" : ""
                        }`}
                        onClick={() =>
                          handleAction(
                            key.clinicalCheckOptionId,
                            key.clinicalCheckActionType,
                            group
                          )
                        }
                      >
                        {ClinicalCheckActionTypes[key.clinicalCheckActionType]}
                      </button>
                    );
                  })}
                </span>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {group?.map((items) => {
            return items?.description ? (
              <div className="group_des">
                <p
                  className={`group-des-p-${
                    ClinicalCheckIdColor[items?.clinicalCheckId]
                  }`}
                >
                  {items?.description}
                </p>
              </div>
            ) : (
              <></>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GroupMedicineWarning;

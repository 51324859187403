import React from "react";
import useTitanApp from "../../hooks/useTitanApp";

export const MonthlyReportShape = () => {
  const { appState } = useTitanApp();
  const { darkMode } = appState;

  return (
    <svg viewBox="0 0 351 125" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M176.216 13.5234C166.512 13.5234 135.923 30.3363 122.85 30.3363C109.777 30.3363 91.5107 77.8509 74.1398 77.8509C56.7689 77.8509 34.0255 27.4123 22.3852 27.4123C10.7449 27.4123 0 50.8041 0 50.8041V125H351V15.7164C341.296 15.7164 334.321 0 324.854 0C315.387 0 287.426 70.5409 274.89 70.5409C262.355 70.5409 246.495 15.9132 231.954 30.3363C217.413 44.7593 185.92 13.5234 176.216 13.5234Z"
        fill="url(#paint0_linear_8074_20950)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8074_20950"
          x1="175.5"
          y1="-2.95776"
          x2="175.5"
          y2="125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={darkMode ? "#6C5E38" : "#ddd"} />
          <stop offset="1" stop-color={darkMode ? "#161819" : "#f2f2f2"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React, { useState } from "react";
import { Tabs, Tab, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Claimed from "./components/Claimed";
import TobeClaimed from "./components/TobeClaimed";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import Declined from "./components/Declined";
import ServiceDetails from "./components/ServiceDetails";
import {
  setSidebarVisible,
  setSelectedRowData,
  setCompletedServiceTabValue,
  setServiceType,
} from "../../../../../../../dispatchers/pharmacyServiceDispatchers";
import {
  getPrescriptionByIncomingId,
  getPatientImage,
} from "../../../../../../../dispatchers/clinicalChecksDispatchers";
import {
  getTitanPatient,
  setCurrentPatientName,
  setCurrentIsNmsPatientLoading,
  setCurrentIsNmsPrescriptionLoading,
  setCurrentArrayImg,
  getTimeline,
  getTravelInformation,
  getCompletedClinicalService,
  getCompletedTravelInformation,
} from "../../../../../../../dispatchers/nmsDispatchers";
import CustomBackdrop from "../../../../../../../components/controls/CustomBackdrop";

export default function ServiceClaimStatus() {
  const { appState, pharmacyServiceState } = useTitanApp();
  const { darkMode, currentPharmacyId } = appState || {};
  const {
    isPharmacyAnalyticsLoading,
    sidebarVisible,
    completedServiceTabValue,
    isPharmacyServiceCompletedClaimedLoading,
    isPharmacyServiceCompletedTobeClaimedLoading,
    isPharmacyServiceCompletedDeclinedLoading,
    serviceAnalyticsCompletedClaimed,
    serviceAnalyticsCompletedTobeClaimed,
    serviceAnalyticsCompletedDeclined,
  } = pharmacyServiceState || {};

  const claimedServices = serviceAnalyticsCompletedClaimed || [];
  const claimedServicesCount = claimedServices?.length || 0;

  const unclaimedServices = serviceAnalyticsCompletedTobeClaimed || [];
  const unclaimedServicesCount = unclaimedServices?.length || 0;

  const declinedServices = serviceAnalyticsCompletedDeclined || [];
  const declinedServicesCount = declinedServices?.length || 0;
  const pharmacyID = currentPharmacyId;

  function TabPanel(props) {
    const { children, value, index, ...other } = props || {};
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setCompletedServiceTabValue(newValue);
  };

  const handleRowClick = async (rowData) => {
    setServiceType(rowData?.serviceType);
    const clinicalServiceId = rowData?.clinicalServiceID;
    if (rowData?.serviceType === 2) {
      setSelectedRowData(rowData);

      setSidebarVisible(true);
      try {
        const timelinePromise = getTimeline(clinicalServiceId);
        const travelPromise = getCompletedTravelInformation(clinicalServiceId);
        const clinicalServicePromise =
          getCompletedClinicalService(clinicalServiceId);
      } catch (error) {
        console.error("Error during API calls:", error);
      } finally {
      }
    } else {
      const prescriptionIncomingId = rowData?.prescriptionIncomingId;
      const patientId = rowData?.patientId;
      setSelectedRowData(rowData);

      setSidebarVisible(true);

      try {
        const timelinePromise = getTimeline(clinicalServiceId);
        const prescriptionPromise =
          prescriptionIncomingId !== null
            ? getPrescriptionByIncomingId({
                prescriptionIncomingId,
                pharmacyID,
              })
            : Promise.resolve(null);

        const titanPatientPromise = getTitanPatient({
          pharmacyID,
          patientId,
          clinicalServiceId,
        });

        const [timelineData, prescriptionData, titanPatientData] =
          await Promise.all([
            timelinePromise,
            prescriptionPromise,
            titanPatientPromise,
          ]);

        if (prescriptionData) {
          prescriptionImageData(prescriptionData, pharmacyID);
        }
        prescriptionPatientImage(titanPatientData);
      } catch (error) {
        console.error("Error during API calls:", error);
      } finally {
      }
    }
  };

  const prescriptionPatientImage = async (patientData) => {
    if (patientData) {
      const { patientGetModel } = patientData || {};
      const patientName = `${patientGetModel.title} ${patientGetModel.forename} ${patientGetModel.middleName} ${patientGetModel.surname}`;
      setCurrentPatientName(patientName);
    }
    setCurrentIsNmsPatientLoading(false);
  };

  const prescriptionImageData = async (data, pharmacyID) => {
    if (data) {
      const { prescriptionGetModel } = data || {};
      const { prescriptionImages } = prescriptionGetModel ?? [];
      const resultArray = [];

      if (prescriptionImages) {
        for (const imageValue of prescriptionImages) {
          const result = await getPatientImage({
            imageValue,
            pharmacyID,
          });
          resultArray.push(`data:image/png;base64, ${result}`);
        }
        if (resultArray?.length > 0) {
          setCurrentArrayImg(resultArray);
        } else {
          setCurrentArrayImg([]);
        }
      }
    }
    setCurrentIsNmsPrescriptionLoading(false);
  };

  return (
    <div className="serviceClaimStatus">
      <div className="serviceClaimStatus_wrapper">
        {isPharmacyServiceCompletedClaimedLoading ? (
          <Skeleton
            sx={{
              bgcolor: darkMode ? "grey.900" : "",
            }}
            height={50}
            width={120}
            animation="wave"
          />
        ) : (
          <div className="chart-heading">
            <span className="title-heading">Completed Services</span>
          </div>
        )}
        {isPharmacyServiceCompletedClaimedLoading ? (
          <Skeleton
            sx={{
              bgcolor: darkMode ? "grey.900" : "",
            }}
            animation="wave"
            height={200}
          />
        ) : (
          <>
            <div className="servicesAnalytics_tab-labels">
              <Tabs
                value={completedServiceTabValue}
                onChange={handleChange}
                aria-label="Tabs"
                TabIndicatorProps={{
                  className: "tab_indicator_line",
                  style: {
                    backgroundColor: "black",
                  },
                }}
              >
                <Tab
                  iconPosition="start"
                  label={
                    <span
                      className={`tabs_text notification-serv ${
                        completedServiceTabValue === 0 ? "selected" : ""
                      }`}
                    >
                      Claimed
                      <div className="notification-count">
                        <p>{claimedServicesCount}</p>
                      </div>
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  iconPosition="start"
                  label={
                    <span
                      className={`tabs_text notification-serv ${
                        completedServiceTabValue === 1 ? "selected" : ""
                      }`}
                    >
                      To be claimed
                      <div className="notification-count">
                        <p>{unclaimedServicesCount}</p>
                      </div>
                    </span>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  iconPosition="start"
                  label={
                    <span
                      className={`tabs_text notification-serv ${
                        completedServiceTabValue === 1 ? "selected" : ""
                      }`}
                    >
                      Declined
                      <div className="notification-count">
                        <p>{declinedServicesCount}</p>
                      </div>
                    </span>
                  }
                  {...a11yProps(2)}
                />
              </Tabs>
            </div>
            <div className="servicesAnalytics_tab-content">
              <TabPanel value={completedServiceTabValue} index={0}>
                <Claimed
                  claimedServices={claimedServices}
                  darkMode={darkMode}
                  onRowClick={handleRowClick}
                />
              </TabPanel>
              <TabPanel value={completedServiceTabValue} index={1}>
                <TobeClaimed
                  unclaimedServices={unclaimedServices}
                  darkMode={darkMode}
                  onRowClick={handleRowClick}
                />
              </TabPanel>
              <TabPanel value={completedServiceTabValue} index={2}>
                <Declined
                  claimedServices={declinedServices}
                  darkMode={darkMode}
                  onRowClick={handleRowClick}
                />
              </TabPanel>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

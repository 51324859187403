import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Skeleton } from "@mui/material";
import "@mui/icons-material/QueryBuilder";
import useTitanApp from "../../hooks/useTitanApp";
function DrugSkeleton() {
  const { appState } = useTitanApp();
  const { darkMode } = appState || {};

  return (
    <>
      <div className="about-presc">
        <div className="about">
          <div className="d-flex">
            <Skeleton
              animation="wave"
              width={"100%"}
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            />
          </div>
        </div>
        <div className="about">
          <div className="d-flex">
            <Skeleton
              animation="wave"
              width={"100%"}
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            />
          </div>
        </div>
        <div className="about">
          <div className="d-flex">
            <Skeleton
              animation="wave"
              width={"100%"}
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            />
          </div>
        </div>
        <div className="about">
          <div className="d-flex">
            <Skeleton
              animation="wave"
              width={"100%"}
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            />
          </div>
        </div>
        <div className="about">
          <div className="d-flex">
            <Skeleton
              animation="wave"
              width={"100%"}
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            />
          </div>
        </div>
        <div className="about">
          <div className="d-flex">
            <Skeleton
              animation="wave"
              width={"100%"}
              sx={{ bgcolor: darkMode ? "grey.800" : "" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DrugSkeleton;

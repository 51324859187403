import React, { useState } from "react";
import "../assets/clinicalwarning.css";
import PropTypes from "prop-types";
import { ClinicalCheckActionTypes } from "../Model/ClinicalActions/ClinicalCheckActionTypes";
import { ClinicalCheckIdColor } from "../Model/ClinicalActions/ClinicalCheckIdColor";
import setCheckNow from "../Model/CheckNow/setCheckNow";
import useTitanApp from "../hooks/useTitanApp";
import {
  addpatientInTitan,
  getPrescriptionChecks,
  getPrescriptionItemCheck,
  getTitanPatientSearchItems,
  setCheckNowModel,
  setOpenFindPatientModal,
  setSeverityCount,
} from "../dispatchers/clinicalChecksDispatchers";
import setPrescriptionChecks from "../Model/PrescriptionChecks/setPrescriptionChecks";
import setAddPatient from "../Model/AddPatient/setAddPatient";
import setPrescriptionItemChecks from "../Model/PrescriptionItemChecks/setPrescriptionItemChecks";
import setFindPatient from "../Model/FindPatient/setFindPatient";

function PrescriptionClinicalWarning(props) {
  const { clincicalChecksState } = useTitanApp();

  const {
    isSkip,
    prescriptions,
    checkNowModel,
    severityCount,
    selectedBucketType,
    clinicalChecksPharmacy,
    patientSearchItems,
  } = clincicalChecksState || {};
  const [checked, setChecked] = useState("");
  const [isdisabled, setisdisabled] = useState(false);

  const [activeButton, setActiveButton] = useState("");
  const { item } = props || {};
  const {
    title,
    description,
    checkSeverity,
    clinicalCheckId,
    clinicalCheckOptions,
  } = item || {};
  const { prescriptionId, patient } = prescriptions || {
    prescriptionId: "00000000-0000-0000-0000-000000000000",
  };
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  function handleAction(clinicalCheckOptionId, clinicalCheckActionType) {
    switch (clinicalCheckActionType) {
      case 7:
        const patientAddBody = new setAddPatient(patient);
        const prescriptionCheckBody = new setPrescriptionChecks(
          prescriptions,
          patientAddBody?.patientId
        );

        const prescriptionItemChecksBody = new setPrescriptionItemChecks(
          prescriptions,
          0,
          "",
          "",
          false,
          patientAddBody?.patientId
        ).obj;
        const newCheckNowModelPatient = new setCheckNow(
          checkNowModel,
          "",
          "",
          "",
          0,
          "",
          false,
          "",
          false,
          0,
          false,
          "",
          false,
          "",
          false,
          "",
          "",
          false,
          selectedBucketType,
          0,
          null,
          false,
          patientAddBody?.patientId
        );
        setCheckNowModel(newCheckNowModelPatient);
        try {
          addpatientInTitan({
            pharmacyID,
            patientAddBody,
          }).then((data) => {
            if (data) {
              getPrescriptionChecks({
                prescriptionCheckBody,
                pharmacyID,
              });
              getPrescriptionItemCheck({
                prescriptionItemChecksBody,
                pharmacyID,
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
        break;

      case 9:
        setOpenFindPatientModal(true);
        const patientFindBody = new setFindPatient(patient);
        try {
          if (patientSearchItems.length > 0) return;
          getTitanPatientSearchItems({ pharmacyID, patientFindBody });
        } catch (error) {
          console.log(error);
        }

        break;

      default:
        const prescriptionCheckActions = {
          clinicalCheckId: clinicalCheckId,
          clinicalCheckOptionId: clinicalCheckOptionId,
          prescriptionId: prescriptionId,
        };

        const newCheckNowModel = new setCheckNow(
          checkNowModel,
          "",
          "",
          prescriptionCheckActions,
          0,
          "",
          false,
          "",
          false,
          0,
          false,
          "",
          false,
          "",
          false,
          "",
          "",
          false,
          selectedBucketType,
          0,
          null,
          false,
          null
        );
        setCheckNowModel(newCheckNowModel);
        setCSSValues(clinicalCheckOptionId);

        if (checkSeverity === 2) {
          // Check if the checks are mandatory
          setSeverityCount(severityCount - 1);
        }
        break;
    }
  }

  function setCSSValues(clinicalCheckOptionId) {
    setActiveButton(clinicalCheckOptionId);
    setChecked("-checked");
    setisdisabled(true);
  }

  const ResultComponent = () => {
    return (
      <>
        {description ? (
          <div
            className={`p-message-warn-${ClinicalCheckIdColor[clinicalCheckId]}${checked}`}
          >
            <div className="p-message-inner">
              <button
                className={`warn-button-${ClinicalCheckIdColor[clinicalCheckId]}${checked}`}
              >
                {title}
              </button>
              {isSkip ? (
                <></>
              ) : (
                <span>
                  {clinicalCheckOptions?.map((key) => {
                    const isButtonActive =
                      key?.clinicalCheckOptionId === activeButton;
                    return (
                      <button
                        disabled={isdisabled}
                        className={`issue-button${
                          isButtonActive ? "-active" : ""
                        }`}
                        onClick={() =>
                          handleAction(
                            key?.clinicalCheckOptionId,
                            key?.clinicalCheckActionType
                          )
                        }
                      >
                        {ClinicalCheckActionTypes[key?.clinicalCheckActionType]}
                      </button>
                    );
                  })}
                </span>
              )}
            </div>
            <p>{description}</p>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return <ResultComponent />;
}
PrescriptionClinicalWarning.propTypes = {
  item: PropTypes.any.isRequired,
};
export default PrescriptionClinicalWarning;

import React from "react";
import CustomButton from "../../../../components/controls/CustomButton";
import CustomLabel from "../../../../components/controls/CustomLabel";
import useTitanApp from "../../../../hooks/useTitanApp";
import LoadingComponentNMSPatient from "../../../../components/LoadingComponentNMS/LoadingComponentNMSPatient";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
export default function PrivateServicePreBooking() {
  const { nmsServiceState } = useTitanApp();
  const { isNmsLoading, responseDTOStagesData, service } =
    nmsServiceState || {};
  const stage = service?.stages[0];
  const getValueOrPlaceholder = (value) => {
    return value ?? "-";
  };
  const processedData = responseDTOStagesData.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {});
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return isNmsLoading ? (
    <LoadingComponentNMSPatient />
  ) : (
    <div className="content-div patient-info-container private-service-activity">
      <div className="general-inner-boxes">
        {responseDTOStagesData?.clinicalServiceStageStatus !== 2 ? (
          <>
            <div className="genaral_pds">
              <h2 className="card-heading">General</h2>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Name" />
                  <p className="card-inner-value name">
                    {[
                      getValueOrPlaceholder(processedData?.Title),
                      getValueOrPlaceholder(processedData?.FirstName),
                      getValueOrPlaceholder(processedData?.LastName),
                    ]
                      .filter((namePart) => namePart !== "-")
                      .join(" ") || "-"}
                  </p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Date of birth" />
                  <p className="card-inner-value">
                    {processedData.DOB
                      ? new Date(processedData?.DOB).toLocaleDateString()
                      : "-"}
                  </p>
                </span>
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <CustomLabel name="Email" />
                  <p className="card-inner-value">
                    {getValueOrPlaceholder(processedData?.Email)}
                  </p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Gender" />
                  <p className="card-inner-value">
                    {getValueOrPlaceholder(processedData?.Gender)}
                  </p>
                </span>
              </div>
            </div>
            <div className="general-info">
              <div className="info-box">
                <span>
                  <div className="d-flex edit-contact">
                    <CustomLabel name="Preferred Contact" />
                  </div>
                  <p className="card-inner-value phone-field">
                    {getValueOrPlaceholder(
                      processedData?.PatientTelephoneNumber
                    )}
                  </p>
                </span>
              </div>
              <div className="info-box">
                <span>
                  <CustomLabel name="Address" />
                  <p className="card-inner-value">
                    {getValueOrPlaceholder(processedData?.PatientAddress)}
                  </p>
                </span>
              </div>
            </div>

            <div className="general-info grids-2">
              <div className="info-box">
                <span>
                  <CustomLabel name="Reason" />
                  <p className="card-inner-value">
                    {getValueOrPlaceholder(processedData?.Reason)}
                  </p>
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="error-msg">
            <p>Patient Details are not available!</p>
          </div>
        )}
        {stage?.clinicalServiceStageStatus === 2 ? (
          <>
            {stage?.template?.templateSections.map((section, index) => {
              if (section?.sectionTitle !== "Patient Details") {
                return (
                  <div key={index}>
                    <div className="genaral_pds">
                      <h2 className="card-heading">{section?.sectionTitle}</h2>
                    </div>

                    {section?.userControls?.map((control, index) => {
                      if (control?.type === 5) {
                        return (
                          <div className="general-info grids-2">
                            <div className="travel-info-switch">
                              <CustomLabel name={control?.label} />
                              <AntSwitch
                                className="card-inner-value"
                                disabled={true}
                                inputProps={{ "aria-label": "ant design" }}
                                checked={
                                  control.value === ""
                                    ? false
                                    : JSON.parse(control?.value)
                                }
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="general-info grids-2">
                            <div className="info-box">
                              <span>
                                <CustomLabel name={control?.label} />
                                <p className="card-inner-value">
                                  {control?.type === 3
                                    ? new Date(
                                        control?.value
                                      ).toLocaleDateString()
                                    : control?.value}
                                </p>
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              }
            })}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

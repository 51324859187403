import { getObject } from "../getObjectValue";
import { v4 as uuidv4 } from "uuid";
class setAddPatient {
  constructor(patient) {
    this.patientId = uuidv4();
    this.externalPatientId = null;
    this.title = patient?.title;
    this.forename = patient?.forename;
    this.middleName = patient?.middleName;
    this.surname = patient?.surname;
    this.dob = patient?.dob;
    this.sex = patient?.sex;
    this.ethnicity = patient?.ethnicity;
    this.nhsNumber = patient?.nhsNumber;
    this.isNhsNumberValid = patient?.isNHSNumberValid;
    this.archive = false;
    this.deathDate = null;
    this.deathNotification = 1;
    this.ownerPatientId = null;
    this.patientType = 1;
    this.species = 1;
    this.weight = null;
    this.animalReference = null;
    this.patientImage = patient?.patientImage;
    this.preferredAddress = patient?.preferredAddress;
    this.preferredContact = patient?.preferredContact;
    this.surgery = null;
    this.confidentialityCode = null;
    this.patientTags = patient?.patientTags.map((tag) => ({
      tagId: tag.tagId,
      tagName: tag.tagName,
      tagDescription: tag.tagDescription,
      tagColour: tag.tagColour,
    }));
    this.lastStartDate = null;
    this.lastExemptionStatus = null;
    this.lastExemptionDate = null;
    this.roomNumber = null;
    this.notes = patient.notes;
    this.patientAnimals = null;
    this.surgery = patient?.surgery;
  }
}

export default setAddPatient;

import React from "react";
import "../../assets/ControlsStyles/customInput.css";

function CustomEndorsementInput(props) {
  return (
    <input
      type={props?.type}
      className="custom-endorsement-input"
      placeholder={props?.placeholder}
    ></input>
  );
}

export default CustomEndorsementInput;

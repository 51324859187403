import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import { RiShoppingBasketLine } from "react-icons/ri";
import OrderingTooltipHeader from "./components/OrderingTooltipHeader";
import useTitanApp from "../../hooks/useTitanApp";
import OrderingTooltipOrderDetails from "./components/OrderingTooltipOrderDetails";
import OrderingTooltipBrandDetails from "./components/OrderingTooltipBrandDetails";
import { useState } from "react";
import setCCToolTipData from "../../Model/ClinicalChecksToolTip/setCCToolTipData";
import {
  setCCOrderItemToolTip,
  updateCCOrderItemToolTip,
} from "../../dispatchers/clinicalChecksDispatchers";
import CustomLoader from "../controls/CustomLoader";

export default function OrderingTooltip({ PrescribedItemId }) {
  const { clincicalChecksState } = useTitanApp();
  const {
    prescriptions,
    clinicalChecksPharmacy,
    isCCOrderTooltipLoading,
    CCOrderTooltipData,
    toolTipSummaryData,
    updateCCOrderTooltipLoading,
  } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const [brand, setBrand] = useState(null);
  const [brandChecked, setBrandChecked] = useState(false);
  const [lastOrderedValue, setLastOrderedValue] = useState(0);
  const [orderPad, setOrderPad] = useState(null);

  const handleBrandCheckboxChange = (e) => {
    setBrandChecked(e.checked);
  };

  const handleLastOrderedValue = (e) => {
    setLastOrderedValue(e.target.value);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleTooltipOpen = () => {
    setShowTooltip(true);
    const matchedPrescriptionItem = prescriptions.prescriptionItems.find(
      (item) => item.prescribedItemId === PrescribedItemId
    );

    const CCOrderTooltipBody = new setCCToolTipData({
      patientId: prescriptions?.patientId,
      prescriptionId: prescriptions?.prescriptionId,
      prescriptionIncomingId: prescriptions?.prescriptionIncomingId,
      prescribedItemId: matchedPrescriptionItem?.prescribedItemId,
      prescriptionItemId: matchedPrescriptionItem?.prescriptionItemId,
      epsLineItem: matchedPrescriptionItem?.epsLineItem,
      quantity: matchedPrescriptionItem?.quantity,
      hasFlavours: false,
      dosageText: matchedPrescriptionItem?.dosageText,
    });

    try {
      setCCOrderItemToolTip({
        CCOrderTooltipBody,
        pharmacyID,
      }).then((data) => {
        setLastOrderedValue(data.stockItemTooltipSummary.lastOrderedQuantity);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateClick = () => {
    const matchedPrescriptionItem = prescriptions.prescriptionItems.find(
      (item) => item.prescribedItemId === PrescribedItemId
    );
    const CCUpdateOrderTooltipBody = {
      stockItemId: CCOrderTooltipData?.stockItemId,
      originalQuantity: CCOrderTooltipData?.lastOrderedQuantity,
      newQuantity: parseInt(lastOrderedValue),
      originalOrderTrolleyProfileId:
        CCOrderTooltipData?.currentOrderPad?.orderTrolleyProfileId,
      updateDefaultProfile: true,
      newOrderTrolleyProfileId: orderPad?.orderTrolleyProfileId,
      prescriptionItemId: matchedPrescriptionItem?.prescriptionItemId,
      prescriptionIncomingId: prescriptions?.prescriptionIncomingId,
      epsLineItem: matchedPrescriptionItem?.epsLineItem,
      prescriptionId: prescriptions?.prescriptionId,
      patientId: prescriptions?.patientId,
      selectedProductId: brand?.productId,
      updateDefaultProduct: brandChecked,
      prescribedItemId: matchedPrescriptionItem?.prescribedItemId,
      dosageText: matchedPrescriptionItem?.dosageText,
    };

    try {
      updateCCOrderItemToolTip({
        CCUpdateOrderTooltipBody,
        pharmacyID,
      }).then(() => {
        setBrand(null);
        setBrandChecked(false);
        setOrderPad(null);
        setLastOrderedValue("");
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ position: "relative" }}>
        <span onClick={handleTooltipOpen}>
          <RiShoppingBasketLine className="ordering-tooltip-icon" size={18} />
        </span>

        {showTooltip && (
          <div>
            {isCCOrderTooltipLoading || updateCCOrderTooltipLoading ? (
              <div className="ordering-tooltip-loader">
                <CustomLoader />
              </div>
            ) : (
              <div className="ordering-tooltip-wrapper">
                <OrderingTooltipHeader data={CCOrderTooltipData} />
                <hr />
                <OrderingTooltipOrderDetails data={CCOrderTooltipData} />
                <hr />
                <OrderingTooltipBrandDetails
                  data={CCOrderTooltipData}
                  padData={toolTipSummaryData}
                  handleBrandCheckboxChange={handleBrandCheckboxChange}
                  brandChecked={brandChecked}
                  handleLastOrderedValue={handleLastOrderedValue}
                  setLastOrderedValue={setLastOrderedValue}
                  lastOrderedValue={lastOrderedValue}
                  brand={brand}
                  setBrand={setBrand}
                  setOrderPad={setOrderPad}
                  orderPad={orderPad}
                />
                <button
                  onClick={handleUpdateClick}
                  className="ordering-tooltip-action-btn"
                >
                  Update
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

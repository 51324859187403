import { getObject } from "../getObjectValue";

class setCCToolTipData {
  constructor(response) {
    this.patientId = response.patientId ? response.patientId : null;
    this.prescribedItemId = response.prescribedItemId
      ? response.prescribedItemId
      : null;
    this.prescriptionID = response.prescriptionId
      ? response.prescriptionId
      : null;
    this.prescriptionItemId = response.prescriptionItemId
      ? response.prescriptionItemId
      : null;
    this.prescriptionIncomingId = response.prescriptionIncomingId
      ? response.prescriptionIncomingId
      : null;
    this.epsLineItem = response.epsLineItem ? response.epsLineItem : null;
    this.quantity = response.quantity ? response.quantity : 0;
    this.hasFlavours = response.hasFlavours ? response.hasFlavours : false;
    this.dosageText = response.dosageText ? response.dosageText : "";
  }
}

export default setCCToolTipData;

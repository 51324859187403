import React from "react";
import "../assets/patientprofile.css";
import PropTypes from "prop-types";
import CustomLabel from "./controls/CustomLabel";
import { FaRegUser } from "react-icons/fa";
import { getFormattedDate } from "../utils/FormattedDate";
import { genderTypes } from "../enum/genderTypes";
import useTitanApp from "../hooks/useTitanApp";
import PatientTag from "../pages/Dashboard/ServicesTab/ServicesContainer/PatientTag";
import ClinicalWarning from "./PrescriptionClinicalWarning";

function PatientProfile({ setOpenFindPatientModal }) {
  const { clincicalChecksState } = useTitanApp();
  const { prescriptions, patientTag, checkNowModel, prescriptionChecks } =
    clincicalChecksState || {};
  const { patient } = prescriptions || {};
  const patientName = `${patient?.title} ${patient?.forename} ${patient?.middleName} ${patient?.surname}`,
    dob = getFormattedDate(patient?.dob),
    sex = genderTypes[patient?.sex],
    nhsNumber = patient?.nhsNumber,
    patientAddress = `${patient?.preferredAddress?.address?.houseName} ${
      patient?.preferredAddress?.address?.streetName
    }, ${
      patient?.preferredAddress?.address?.locality
        ? patient?.preferredAddress?.address?.locality + ", "
        : ""
    }${patient?.preferredAddress?.address?.town}, ${
      patient?.preferredAddress?.address?.county
    }, ${patient?.preferredAddress?.address?.postcode}`,
    patientAllergy = "Unknown",
    patientSurgery = "";
  // patientSurgery = `${patient?.surgery?.surgery?.name}, ${patient?.surgery?.surgery?.address?.houseName}, ${patient?.surgery?.surgery?.address?.streetName}, ${patient?.surgery?.surgery?.address?.postcode}`;

  function checkPrescriptionActions() {
    let allMatch = true;
    const prescriptionCheckActions = checkNowModel?.prescriptionCheckActions;

    const filteredChecks = prescriptionChecks.filter(
      (check) =>
        check?.checkSection === 2 &&
        check?.clinicalCheckId !== 3 &&
        check?.clinicalCheckId !== 4 &&
        check?.clinicalCheckId !== 2
    );
    allMatch = filteredChecks.every((check) =>
      prescriptionCheckActions.some(
        (action) =>
          action?.clinicalCheckId === check?.clinicalCheckId &&
          check?.clinicalCheckOptions.some(
            (option) =>
              option?.clinicalCheckOptionId === action?.clinicalCheckOptionId
          )
      )
    );
    return allMatch;
  }

  return (
    <div
      className="parent_template"
      style={{
        borderLeft:
          checkPrescriptionActions() === true
            ? "2px solid #00ca69"
            : "2px solid #E22424",
      }}
    >
      <div className="user-profile-card customcard">
        <div
          className="card-step-number"
          style={{
            background:
              checkPrescriptionActions() === true ? "#00ca69" : "#E22424",
          }}
        >
          <h2>2</h2>
        </div>
        <h2 className="card-heading">
          <FaRegUser />
          {patientName}
        </h2>
        <div className="service_tags_list">
          {patientTag?.map((tag, index) => (
            <PatientTag key={index} tag={tag} index={index} />
          ))}
        </div>
        <div className="card-inner-boxes">
          <div className="patient-profile">
            <span>
              <CustomLabel name="Date of Birth" />
              <p className="card-inner-value"> {dob}</p>
            </span>
          </div>
          <div className="patient-profile">
            <span>
              <CustomLabel name="Sex" />
              <p className="card-inner-value">{sex}</p>
            </span>
          </div>
          <div className="patient-profile">
            <span>
              <CustomLabel name="NHS Number" />
              <p className="card-inner-value">{nhsNumber}</p>
            </span>
          </div>
        </div>
        <div className="card-inner-boxes">
          <div className="patient-profile">
            <span>
              <CustomLabel name="Address" />
              <p className="card-inner-value"> {patientAddress}</p>
            </span>
          </div>
          <div className="patient-profile">
            <span>
              <CustomLabel name="Allergy" />
              <p className="card-inner-value">{patientAllergy}</p>
            </span>
          </div>
          <div className="patient-profile">
            <span>
              <CustomLabel name="Surgery" />
              <p className="card-inner-value">{patientSurgery}</p>
            </span>
          </div>
        </div>
        {/* {prescriptionChecks?.map((items) => {
                        if (
                          items.checkSection === 2 &&
                          items?.clinicalCheckId !== 2 &&
                          items?.clinicalCheckId !== 3 &&
                          items?.clinicalCheckId !== 4
                        ) {
                          return <ClinicalWarning item={items} />;
                        }
                      })} */}
        {prescriptionChecks?.map((items) => {
          if (items?.checkSection === 2) {
            return (
              <ClinicalWarning
                item={items}
                setOpenFindPatientModal={setOpenFindPatientModal}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default PatientProfile;

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import useTitanApp from "../../../../../../../hooks/useTitanApp";
import Avatar from "react-initials-avatar";

export default function OrbitAgencyWorkPlannerInfo() {
  const { orbitState } = useTitanApp();
  const { orbitServicesWorkPlanner, orbitPharmanautsWorkPlanner } =
    orbitState || {};

  return (
    <div className="orbit-agency-work-planner-info-wrapper">
      <div className="info-wrapper">
        <Accordion defaultExpanded className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="info-wrapper-label-box">
              <p className="info-wrapper-head-label">NHS</p>
              <p className="info-wrapper-head-label-value">
                (
                {orbitServicesWorkPlanner?.length > 0
                  ? orbitServicesWorkPlanner?.length
                  : 0}
                )
              </p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="info-wrapper-list">
              {orbitServicesWorkPlanner?.length > 0 ? (
                orbitServicesWorkPlanner.map((service, index) => (
                  <div className="info-wrapper-list-box" key={index}>
                    <span className="">{service.serviceName}</span>
                    <diV>{service.serviceCount}</diV>
                  </div>
                ))
              ) : (
                <div>
                  <p className="orbit-no-data-note t-center">
                    No data available
                  </p>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="info-wrapper">
        <Accordion defaultExpanded className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="info-wrapper-label-box">
              <p className="info-wrapper-head-label">Pharmanauts</p>
              <p className="info-wrapper-head-label-value">
                (
                {orbitPharmanautsWorkPlanner?.length > 0
                  ? orbitPharmanautsWorkPlanner?.length
                  : 0}
                )
              </p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="info-wrapper-list">
              {orbitPharmanautsWorkPlanner?.length > 0 ? (
                orbitPharmanautsWorkPlanner.map((pharmanaut, index) => (
                  <div
                    className="info-wrapper-list-box pharmanauts"
                    key={index}
                  >
                    <div className="avatar-box">
                      <Avatar
                        name={pharmanaut.fullName}
                        size={40}
                        fontSize={10}
                      />
                    </div>

                    <span className="">{pharmanaut.fullName}</span>
                  </div>
                ))
              ) : (
                <div>
                  <p className="orbit-no-data-note t-center">
                    No data available
                  </p>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

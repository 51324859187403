export const therapyLevel = [
  {
    label: "Unassigned",
    value: 0,
  },
  {
    label: "Acute",
    value: 1,
  },
  {
    label: "Chronic",
    value: 2,
  },
];

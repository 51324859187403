import React, { useState } from "react";
import "./../../assets/css/NotesControl.scss";
import { v4 as uuidv4 } from "uuid";
import AddedNotes from "./AddedNotes";
import { HiPaperAirplane } from "react-icons/hi";
import setCheckNow from "../../Model/CheckNow/setCheckNow";
import useTitanApp from "../../hooks/useTitanApp";
import {
  setCheckNowModel,
  setNotes,
} from "../../dispatchers/clinicalChecksDispatchers";
function NotesControl({ PrescriptionItemId }) {
  const { appState, clincicalChecksState } = useTitanApp();

  const {
    checkNowModel,
    postMedicationId,
    itemReturnDose,
    itemReturnQuantity,
    returnDispense,
    isReturnDispense,
    notesModel,
    selectedBucketType,
  } = clincicalChecksState || {};
  const { currentUser } = appState;
  const [addedNotes, setAddedNotes] = useState(notesModel);
  const [notesValue, setNotesValue] = useState("");
  function ChangeNotes(e) {
    setNotesValue(e?.target?.value);
  }

  function UpdateNotesModel(Notesmodel) {
    setNotes(Notesmodel);
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      PrescriptionItemId,
      "",
      itemReturnQuantity,
      itemReturnDose,
      false,
      postMedicationId,
      false,
      0,
      isReturnDispense,
      isReturnDispense === true ? returnDispense : "",
      true,
      Notesmodel,
      false,
      "",
      "",
      false,
      selectedBucketType,
      0,
      null,
      false,
      null
    );
    setCheckNowModel(newCheckNowModel);
  }

  const addNewNote = () => {
    if (notesValue !== "") {
      const newNote = {
        professionalName: currentUser?.fullName,

        prescriptionItemNoteId: uuidv4(),

        prescriptionItemId: PrescriptionItemId,

        dispenseItemId: "00000000-0000-0000-0000-000000000000",

        note: notesValue,

        archiveDate: null,

        awaitingQuery: false,

        pinNote: false,

        showOnPicking: false,

        showOnLabelling: false,

        showOnHandout: false,

        needCheck: false,

        createdOn: new Date(),
      };
      const updatedNotesArray = [...addedNotes];
      updatedNotesArray.push(newNote);
      setNotesValue("");
      setAddedNotes(updatedNotesArray);
      UpdateNotesModel(updatedNotesArray);
    }
  };
  const ChangeAddedNotes = (notes) => {
    setAddedNotes(notes);
    UpdateNotesModel(notes);
  };
  return (
    <>
      {addedNotes?.map((item, index) => {
        return (
          <AddedNotes
            key={item.PrescriptionItemNoteId}
            item={item}
            addedNotes={addedNotes}
            handleAddedNotes={ChangeAddedNotes}
          />
        );
      })}

      <div className="note-box">
        <input
          type="text"
          placeholder="Write your comment here"
          value={notesValue}
          onChange={ChangeNotes}
          className="input-field"
        />
        <button className="note-box-add" onClick={addNewNote}>
          <HiPaperAirplane size={20} />
        </button>
      </div>
    </>
  );
}

export default NotesControl;

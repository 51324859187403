import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDropdown from "../controls/CustomDropdown";
import CustomLabel from "../controls/CustomLabel";
import { colorArray } from "../../enum/colorDropdown";
import { preparationArray } from "../../enum/preparationDropdown";
import useTitanApp from "../../hooks/useTitanApp";
import { setPatientTherapy } from "../../data/setPatientTherapy";
function MdsAccordion({ patientMedicationTherapy, changeTherapyData }) {
  const { clincicalChecksState } = useTitanApp();

  const { isEditable } = clincicalChecksState || {};
  const [expanded, setExpanded] = React.useState(true);
  const [inputclass, setInputClass] = useState("disable");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function changePreparation(value) {
    var therapyObj = setPatientTherapy(
      "preparationType",
      value,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  function changeMarking(e) {
    var therapyObj = setPatientTherapy(
      "marking",
      e?.target?.value,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  function changeColor(value) {
    var therapyObj = setPatientTherapy(
      "color",
      value.toString(),
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleChange("panel1")}
        style={{ marginBottom: "15px" }}
        className="clinical-accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="card-accordion-cont"
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="8" fill="#00CC6A" />
              <path
                d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                fill="white"
              />
            </svg>
            <p>MDS</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="active-inner-boxes">
            <div className="cc-dropdown-class mds-preparation">
              <CustomLabel name="Preparation" />
              <CustomDropdown
                handleChange={changePreparation}
                label="Preparation"
                items={preparationArray}
                selected={patientMedicationTherapy?.preparationType}
                isEdit={isEditable}
              />
            </div>
            <div className="dose dose-element">
              <CustomLabel name="Marking" />
              <input
                className={inputclass + " card-inner-value"}
                type="text"
                disabled={isEditable}
                onChange={changeMarking}
                value={patientMedicationTherapy?.marking}
              />
            </div>

            <div className="cc-dropdown-class mds-colour">
              <CustomLabel name="Colour" />
              <CustomDropdown
                handleChange={changeColor}
                label="Colour"
                items={colorArray}
                selected={patientMedicationTherapy?.color}
                isEdit={isEditable}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default MdsAccordion;

import React from "react";
import "../assets/clinicalactions.css";
import CustomButton from "./controls/CustomButton";
import { useNavigate } from "react-router-dom";
import useTitanApp from "../hooks/useTitanApp";
import {
  setCurrentClinicalChecksNotes,
  setItemChecks,
  setMandatory,
  setMedicineWarning,
  setNotes,
  setPatientClinicalChecks,
  setPatientImage,
  setSeverityCount,
  setIsParked,
} from "../dispatchers/clinicalChecksDispatchers";
import { setAppLoading } from "../dispatchers/appDispatchers";
function ClinicalActions({ submit, isValidateChecks }) {
  let navigate = useNavigate();
  const { clincicalChecksState } = useTitanApp();
  const { isSkip, checkNowModel, severityCount, clinicalChecksPharmacy } =
    clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };

  async function handleChecknow(exit) {
    setAppLoading(true);
    await setPatientClinicalChecks({
      pharmacyID,
      checkNowModel,
    });
    if (exit) {
      setSeverityCount(0);
      setItemChecks([]);
      setMedicineWarning([]);
      setPatientImage([]);
      setNotes([]);
      setCurrentClinicalChecksNotes([]);
      setIsParked(false);
      setAppLoading(false);
      navigate(`/pharmacyservices/${pharmacyID}`, {
        state: {
          serviceType: "Kanban",
        },
      });
    } else {
      submit();
    }
  }

  return (
    <div className="actions">
      <CustomButton
        label="Exit"
        action={(event) => {
          navigate("/");
        }}
      />

      <CustomButton label="Skip" action={submit} />

      <CustomButton
        label="Submit & do another"
        action={(event) => {
          handleChecknow(false);
        }}
        isdisable={isValidateChecks()}
      />

      <CustomButton
        label="Submit & Exit"
        action={(event) => {
          handleChecknow(true);
        }}
        isdisable={isValidateChecks()}
      />
    </div>
  );
}

export default ClinicalActions;

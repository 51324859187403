import * as React from "react";
import { useState } from "react";
import CustomLabel from "../controls/CustomLabel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ImWarning } from "react-icons/im";
import CustomSwitch from "../controls/CustomSwitch";
import CustomDropdown from "../controls/CustomDropdown";
import useTitanApp from "../../hooks/useTitanApp";
import { setPatientTherapy } from "../../data/setPatientTherapy";
import { therapyLevel } from "../../enum/therapyLevel";
function CoreAcoordion({
  medicineValues,
  patientMedicationTherapy,
  changeTherapyData,
} = {}) {
  const { clincicalChecksState } = useTitanApp();

  const { isTherapy, isEditable } = clincicalChecksState || {};

  const [isDoseValid, setIsDoseValid] = useState(true);
  const [isQuantityValid, setIsQuantityValid] = useState(true);
  const [switchClass, setSwitchclass] = useState("switch-content");
  const [inputclass, setInputClass] = useState("disable");
  const [expanded, setExpanded] = useState(true);

  const errorMessage = "This field is required!";
  function handleChange() {
    setExpanded(!expanded);
  }

  function handleQuantitychange(e) {
    const quantityValue = e?.target?.value;
    const isValid = quantityValue !== "";
    setIsQuantityValid(isValid);
    var therapyObj = setPatientTherapy(
      "quantity",
      quantityValue,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }

  function handleDosechange(e) {
    const doseValue = e?.target?.value;
    const isValid = doseValue !== "";
    setIsDoseValid(isValid);
    var therapyObj = setPatientTherapy(
      "dose",
      doseValue,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }

  function handleChangeColor(isChecked) {
    !isChecked
      ? setSwitchclass("switch-content active-switch")
      : setSwitchclass("switch-content");
    var therapyObj = setPatientTherapy(
      "archive",
      !isChecked,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  function changeTherapy(value) {
    var therapyObj = setPatientTherapy(
      "therapyType",
      value,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  function changeStartDate(e) {
    var therapyObj = setPatientTherapy(
      "startDate",
      e?.target?.value,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  function changeStopDate(e) {
    var therapyObj = setPatientTherapy(
      "stopDate",
      e?.target?.value,
      patientMedicationTherapy
    );
    changeTherapyData(therapyObj);
  }
  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        style={{ marginBottom: "15px" }}
        className="clinical-accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="card-accordion-cont"
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="8" fill="#00CC6A" />
              <path
                d="M6.48857 9.51115L4.99581 7.97574C4.768 7.74143 4.39866 7.74143 4.17085 7.97574C3.94305 8.21006 3.94305 8.58995 4.17085 8.82427L6.11529 10.8243C6.35784 11.0737 6.75631 11.0551 6.9759 10.7841L10.8648 5.98412C11.071 5.72956 11.0376 5.35122 10.7901 5.13908C10.5426 4.92694 10.1748 4.96134 9.96852 5.2159L6.48857 9.51115Z"
                fill="white"
              />
            </svg>
            <p>Core</p>
          </div>
          {patientMedicationTherapy?.dose !== medicineValues?.dosageText ? (
            <div>
              <ImWarning size={15} color={"red"} />
              <p>Dosage change</p>
            </div>
          ) : patientMedicationTherapy?.quantity !==
            medicineValues?.quantity ? (
            <div>
              <ImWarning size={15} color={"red"} />
              <p>Dosage change</p>
            </div>
          ) : (
            <></>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {isTherapy ? (
            <>
              {" "}
              <div className="dose dose-element">
                <CustomLabel name="Dose" />
                <input
                  className={inputclass + " card-inner-value"}
                  type="text"
                  disabled={isEditable}
                  value={patientMedicationTherapy?.dose}
                  onChange={handleDosechange}
                />
                {isDoseValid ? null : (
                  <p className="validation-message">{errorMessage}</p>
                )}
              </div>
              <div className="core-inner-boxes">
                <div className="quantity">
                  <CustomLabel name="Quantity" />
                  <input
                    className={inputclass + " card-inner-value quantity-box"}
                    type="number"
                    disabled={isEditable}
                    value={patientMedicationTherapy?.quantity}
                    onChange={handleQuantitychange}
                  />
                  {isQuantityValid ? null : (
                    <p className="validation-message">{errorMessage}</p>
                  )}
                </div>
                <div className="cc-dropdown-class therapy-level">
                  <CustomLabel name="Therapy level" />
                  <CustomDropdown
                    handleChange={changeTherapy}
                    label="Therapy level"
                    items={therapyLevel}
                    isEdit={isEditable}
                    selected={patientMedicationTherapy?.therapyType}
                  />
                </div>
              </div>
              <div className="active-inner-boxes">
                <div className="dose dose-element">
                  <CustomLabel name="Start Date" />
                  <input
                    className={inputclass + " card-inner-value"}
                    type="date"
                    disabled={isEditable}
                    onChange={changeStartDate}
                    value={patientMedicationTherapy?.startDate}
                  />
                </div>
                <div className="dose dose-element">
                  <CustomLabel name="Stop Date" />
                  <input
                    className={inputclass + " card-inner-value"}
                    type="date"
                    disabled={isEditable}
                    value={patientMedicationTherapy?.stopDate}
                    onChange={changeStopDate}
                  />
                </div>
                <div className={switchClass}>
                  <CustomLabel name="Archive" />
                  <div>
                    <CustomSwitch
                      handleCallback={handleChangeColor}
                      isEdit={isEditable}
                      defaultValue={patientMedicationTherapy?.archive}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="core-inner-boxes">
                <div className="quantity">
                  <CustomLabel name="Dose" />
                  <input
                    className={inputclass + " card-inner-value"}
                    type="text"
                    disabled={!isEditable}
                    value={patientMedicationTherapy?.dose}
                    onChange={handleDosechange}
                  />
                </div>
                <div className="quantity">
                  <CustomLabel name="Quantity" />
                  <input
                    className={inputclass + " card-inner-value quantity-box"}
                    type="number"
                    disabled={!isEditable}
                    value={patientMedicationTherapy?.quantity}
                    onChange={handleQuantitychange}
                  />
                </div>
              </div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CoreAcoordion;

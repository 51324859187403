import React, { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { debounce } from "lodash";
import "../../assets/clinicalcheck.css";
import "./../../assets/css/NmsIntervention/NmsIntervention.scss";
import setServicePrivate from "../../Model/Private/setServicePrivate";
import setAddTemplate from "../../Model/AddTemplateSection/setAddTemplate";
import LoadingComponentNMS from "../../components/LoadingComponentNMS/LoadingComponentNMS";
import CustomButton from "../../components/controls/CustomButton";
import { useNavigate } from "react-router-dom";
import addedTemplate from "../../Model/AddTemplateSection/addedTemplate";
import setTemplateModel from "../../Model/TemplateModel/setTemplateModel";
import {
  setCurrentIsNmsLoading,
  setCurrentNmsModel,
  setCurrentTimelineElements,
  setCurrentIsServiceNotesLoading,
  updatePrivateService,
  setDrugsData,
  setDrugsAboutData,
  generatePrescriptionService,
  setselectedDrugs,
  // setTempVaccineTemplates,
} from "../../dispatchers/nmsDispatchers";
import { FaTimes } from "react-icons/fa";
import { PiCaretRightBold } from "react-icons/pi";
import useTitanApp from "../../hooks/useTitanApp";
import { showInfo } from "../../components/ToastedMessage/ToastedMessage";
import setAddTimelineItem from "../../Model/TimelineItem/setAddTimelineItem";
import { Skeleton } from "@mui/material";
import PrivateServiceTabs from "./components/PrivateServiceTabs";
import AddPrescriptionItem from "../../components/AddPrescriptionItem/AddPrescriptionItem";
import PrivateServiceTemplateSection from "./components/components/PrivateServiceTemplateSection";
import AddVaccineItem from "../../components/AddVaccineItem/AddVaccineItem";
import addedVaccineTemplate from "../../Model/AddTemplateSection/addedVaccineTemplate";

function PrivateService() {
  const { auth } = useTitanApp();
  const { user } = auth || {};
  let navigate = useNavigate();
  let count = 0;
  let currentIndex = 0;
  let stageName = "",
    previousStageName = "";

  const [isRequired, setIsRequired] = useState(false);
  const [TempMedicineTemplates, setTempMedicineTemplates] = useState([]);
  const [TempVaccineTemplates, setTempVaccineTemplates] = useState([]);
  const [prescriptionMedication, setPrescriptionMedication] = useState([]);

  let isCompleted = false;
  const { appState, nmsServiceState } = useTitanApp();
  const { isNmsLoading, timelineElements } = nmsServiceState || {};
  const { isOrbitUser, darkMode, currentPharmacyId } = appState || {};
  const userFullName = user?.name || "";
  const userEmail = user?.email || "";
  const {
    patientName,
    service,
    pharmacyID,
    clinicalpatientId,
    clinicalServiceId,
    NmsModel,
    value,
    selectedDrugsData,
    // TempVaccineTemplates,
  } = nmsServiceState || {};
  const clinicalServiceType = service?.serviceType;

  const boxStyling = (totalElements, position) => {
    if (totalElements === 1) {
      return { gridColumn: "1 / -1" };
    } else if (totalElements === 2) {
      return { gridColumn: "span 6" };
    } else {
      const alternatingGroupSize = 5;
      const alternatingSpan = position % alternatingGroupSize < 3 ? 4 : 6;

      return { gridColumn: `span ${alternatingSpan}` };
    }
  };
  async function CheckCompleted(indexNo) {
    NmsModel?.stages[indexNo]?.template?.templateSections?.map((template) => {
      template?.userControls?.map((item) => {
        if (item?.required === true) {
          if (item?.value === "" || item?.value === null) {
            if (item?.type === 0) {
              if (item?.type === 3) {
                isCompleted = true;
              }
            }
          }
        }
      });
    });
  }
  const setCompletedNms = async (res) => {
    setCurrentNmsModel(res);
  };
  async function submitPrivateService(indexNo, isAddPrescription, type) {
    setDrugsData([]);
    setDrugsAboutData([]);
    try {
      if (type === "generatePrescription") {
        let prescriptionTemplates = [];
        if (TempMedicineTemplates.length > 0) {
          prescriptionTemplates = prescriptionTemplates.concat(
            TempMedicineTemplates
          );
        }
        if (TempVaccineTemplates.length > 0) {
          prescriptionTemplates =
            prescriptionTemplates.concat(TempVaccineTemplates);
        }

        var data = {
          PharmacyId: pharmacyID,
          ClinicalServiceId: clinicalServiceId,
          Medicines: prescriptionMedication,
          Templates: prescriptionTemplates,
        };
        await generatePrescriptionService(data);
        setTempMedicineTemplates([]);
        setTempVaccineTemplates([]);
        setPrescriptionMedication([]);
        setselectedDrugs([]);
      } else {
        let res = NmsModel;
        res = resetTextBoxControls();
        if (TempMedicineTemplates.length > 0) {
          const filteredTemplates = TempMedicineTemplates.filter(
            (template) => template.sectionTitle !== ""
          );
          if (filteredTemplates.length > 0) {
            const newTemplateSection =
              res?.stages[value]?.template?.templateSections;
            const updatedSections = [
              ...newTemplateSection,
              ...TempMedicineTemplates,
            ];
            res = new setAddTemplate(res, value, updatedSections, userFullName);
            setCurrentNmsModel(res);
          }
          setTempMedicineTemplates([]);
        }
        if (TempVaccineTemplates.length > 0) {
          const filteredTemplates = TempVaccineTemplates.filter(
            (template) => template.sectionTitle !== ""
          );
          if (filteredTemplates.length > 0) {
            const newTemplateSection =
              res?.stages[value]?.template?.templateSections;
            const updatedSections = [
              ...newTemplateSection,
              ...TempVaccineTemplates,
            ];
            res = new setAddTemplate(res, value, updatedSections, userFullName);

            setCurrentNmsModel(res);
          }
          setTempVaccineTemplates([]);
        }
        isCompleted = false;
        setIsRequired(true);
        res = new setServicePrivate(
          res,
          indexNo,
          null,
          null,
          true,
          clinicalServiceType,
          null,
          null,
          null,
          "",
          userFullName,
          "00000000-0000-0000-0000-000000000000",
          userEmail
        );
        await setCompletedNms(res);
        await CheckCompleted(indexNo);
        let lstElement = [];
        if (isCompleted === false) {
          let resTimeline;
          resTimeline = new setAddTimelineItem(
            userEmail,
            userFullName,
            lstElement?.length > 0 ? lstElement : timelineElements,
            indexNo === 0 ? 28 : 29,
            clinicalServiceId
          );

          const clinicalServiceWtihTimeline = {
            timeline: resTimeline,
            clinicalServiceBuilderResponseDTO: res,
          };
          updatePrivateService(
            clinicalServiceWtihTimeline,
            isOrbitUser,
            isAddPrescription
          )
            .then(() => {
              navigate(`/pharmacyservices/${currentPharmacyId}`);
            })
            .catch((error) => {
              console.error("Error updating private service:", error);
            });
          setPrescriptionMedication([]);
          setCurrentTimelineElements([]);
          // await ActionTrackForServices(
          //   indexNo,
          //   false,
          //   "Private Services Stage Complete",
          //   currentUser
          // );
        }
      }
    } catch (error) {
      console.log(error);
      setCurrentIsNmsLoading(false);
      setCurrentIsServiceNotesLoading(false);
      setIsRequired(false);
      showInfo("Private service not updated due to some reason");
    }
  }
  const updateReduxState = debounce((res, updatedTimelineElements) => {
    setCurrentTimelineElements(updatedTimelineElements);
    setCurrentNmsModel(res);
  }, 3000);
  async function UpdateNmsModel(
    title,
    userControls,
    groupId,
    updatedTimelineElements
  ) {
    const res = new setServicePrivate(
      NmsModel,
      value,
      title,
      userControls,
      false,
      clinicalServiceType,
      null,
      null,
      null,
      "",
      userFullName,
      groupId,
      userEmail
    );
    setCurrentTimelineElements(updatedTimelineElements);
    setCurrentNmsModel(res);
    // updateReduxState(res, updatedTimelineElements);
  }
  const AddTemplateSection = async () => {
    if (TempMedicineTemplates.length > 0) {
      const newTemplateSection =
        NmsModel?.stages[value]?.template?.templateSections;
      const updatedSections = [...newTemplateSection, ...TempMedicineTemplates];
      const res = new setAddTemplate(
        NmsModel,
        value,
        updatedSections,
        userFullName
      );
      setTempMedicineTemplates([]);
      setCurrentNmsModel(res);
    } else {
      const newSection = new addedTemplate(``, 98).obj;
      setTempMedicineTemplates([newSection]);
    }
  };

  function AddVaccine(name) {
    if (TempVaccineTemplates.length > 0) {
      const newTemplateSection =
        NmsModel?.stages[value]?.template?.templateSections;
      const updatedSections = [...newTemplateSection, ...TempVaccineTemplates];
      const res = new setAddTemplate(
        NmsModel,
        value,
        updatedSections,
        userFullName
      );
      setTempVaccineTemplates([]);
      setCurrentNmsModel(res);
    } else {
      const newSection = new addedVaccineTemplate(name, 99).obj;
      setTempVaccineTemplates([newSection]);
    }
  }
  const UpdateTemplateModel = async (
    title,
    UserControls,
    active,
    medicationData
  ) => {
    const resp = new setTemplateModel(
      TempMedicineTemplates,
      title,
      UserControls,
      active
    ).obj;
    setTempMedicineTemplates(resp);
    if (medicationData !== null) {
      setPrescriptionMedication((prevState) => [...prevState, medicationData]);
    }
  };
  function removeTemplateItem(title) {
    const updatedData = TempMedicineTemplates.filter(
      (item) => item.sectionTitle !== title
    );
    setTempMedicineTemplates(updatedData);
  }
  const UpdateVaccineTemplateModel = async (
    title,
    UserControls,
    active,
    vaccineData
  ) => {
    const resp = new setTemplateModel(
      TempVaccineTemplates,
      title,
      UserControls,
      active
    ).obj;
    setTempVaccineTemplates(resp);
    if (vaccineData !== null) {
      setPrescriptionMedication((prevState) => [...prevState, vaccineData]);
    }
  };
  function removeVaccineTemplateItem(title) {
    const updatedData = TempVaccineTemplates.filter(
      (item) => item.sectionTitle !== title
    );
    setTempVaccineTemplates(updatedData);
  }
  const UpdateTemplateInNmsModel = async (title, UserControls, active) => {};
  function removeTemplateFromNms(title, order, non) {
    const currentTemplates =
      NmsModel?.stages[value]?.template?.templateSections?.filter(
        (section) =>
          section?.templateSectionType === 3 && section?.order === order
      ) || [];
    const nonUpdatedSectionsMedicine =
      NmsModel?.stages[value]?.template?.templateSections?.filter(
        (section) =>
          section?.templateSectionType === 3 && section?.order === non
      ) || [];
    const nonUpdatedSections =
      NmsModel?.stages[value]?.template?.templateSections?.filter(
        (section) => section?.templateSectionType !== 3
      ) || [];
    const updatedData = currentTemplates.filter(
      (item) => item.sectionTitle !== title
    );
    const updatedTemplateSections = [
      ...nonUpdatedSections,
      ...updatedData,
      ...nonUpdatedSectionsMedicine,
    ];
    const updatedNmsModel = {
      ...NmsModel,
      stages: NmsModel.stages.map((stage, index) =>
        index === value
          ? {
              ...stage,
              template: {
                ...stage.template,
                templateSections: updatedTemplateSections,
              },
            }
          : stage
      ),
    };
    setCurrentNmsModel(updatedNmsModel);
    setDrugsData([]);
    setDrugsAboutData([]);
  }
  const [showResponsiveSidebar, setShowResponsiveSidebar] = useState(false);
  const handleNMSSidebar = () => {
    if (window.innerWidth < 1025) {
      setShowResponsiveSidebar(!showResponsiveSidebar);
    }
  };
  const getStageNameAndCurrentIndex = () => {
    {
      service?.stages?.map((stage, index) => {
        if (currentIndex === 0 && stage?.clinicalServiceStageStatus !== 2) {
          currentIndex = index;
        }
        if (stage?.clinicalServiceStageStatus !== 2) {
          if (stageName === "") {
            stageName = stage?.stageName;
          }
          if (index !== 0 && previousStageName === "") {
            switch (index) {
              case 1:
                previousStageName = service?.stages[index - 1]?.stageName;
                break;
              case 2:
                previousStageName = service?.stages[index - 2]?.stageName;
                break;
              case 3:
                previousStageName = service?.stages[index - 1]?.stageName;
                break;
              default:
                previousStageName = service?.stages[index - 1]?.stageName;
            }
          }
        }
      });
    }
  };
  function validateComplete(indexNo) {
    let isValidate = false;
    let isFollowup = false;
    let filledControlArray = [];

    NmsModel?.stages[indexNo]?.template?.templateSections?.forEach((item) => {
      if (
        item.isRequired &&
        item.sectionTitle !== "Follow up" &&
        item.sectionTitle !== "Follow Up Rejection"
      ) {
        const hasFilledControl = item?.userControls.some(
          (control) => control.value === "true"
        );
        filledControlArray.push(hasFilledControl);
      }
    });
    if (filledControlArray.includes(false)) {
      isValidate = true;
    }
    return isValidate;
  }
  function setCountColor(indexNo, groupId, title) {
    let isValidate = false;
    let filledControlArray = [];
    NmsModel?.stages[indexNo]?.template?.templateSections?.forEach((item) => {
      if (groupId === "00000000-0000-0000-0000-000000000000") {
        if (
          item?.order !== 99 &&
          item.sectionTitle === title &&
          item?.isRequired === true
        ) {
          const hasFilledControl = item?.userControls?.some(
            (control) => control.value === "true"
          );
          filledControlArray.push(hasFilledControl);
        }
      } else {
        if (
          item?.isParent === false &&
          groupId === item.groupId &&
          item?.isRequired === true
        ) {
          const hasFilledControl = item?.userControls?.some(
            (control) => control.value === "true"
          );
          filledControlArray.push(hasFilledControl);
        }
      }
    });
    if (filledControlArray.includes(false)) {
      isValidate = true;
    }
    return isValidate;
  }

  function resetTextBoxControls() {
    // Clone the templateSections
    const updatedTemplate = NmsModel?.stages[
      value
    ]?.template?.templateSections?.map((section) => ({
      ...section,
      userControls: section?.userControls?.map((control) => ({ ...control })),
    }));

    // Find the medical history section
    const medicalHistorySectionIndex = updatedTemplate?.findIndex(
      (section) => section?.sectionTitle === "Medical History"
    );

    if (medicalHistorySectionIndex !== -1) {
      const medicalHistorySection = updatedTemplate[medicalHistorySectionIndex];

      // Update the user controls of the medical history section
      medicalHistorySection?.userControls?.forEach((control) => {
        const parentControl = medicalHistorySection?.userControls?.find(
          (item) => item?.userControlId === control?.parentUserControlId
        );
        if (parentControl?.type === 5 && parentControl?.value === "false") {
          control.value = ""; // Reset the value
        }
      });

      // Replace the modified section in the template
      updatedTemplate[medicalHistorySectionIndex] = {
        ...medicalHistorySection,
      };
    }
    const updatedNmsModel = { ...NmsModel };
    updatedNmsModel.stages = [...updatedNmsModel.stages];
    updatedNmsModel.stages[value] = {
      ...updatedNmsModel.stages[value],
      template: {
        ...updatedNmsModel.stages[value].template,
        templateSections: updatedTemplate,
      },
    };
    const model = updatedNmsModel;
    // Return the updated templateSections
    return model;
  }

  const handleCloseConsultationForm = () => {
    setDrugsData([]);
    setDrugsAboutData([]);
    navigate(`/pharmacyservices/${currentPharmacyId}`);
  };

  const buttonStyle = {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    color: "#d3d3d3",
  };
  const containerStyle = {
    backgroundColor: darkMode ? "#222" : "#fff",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  };

  return (
    <div className="patient-consultation-wrapper">
      {isNmsLoading === false && service === null ? (
        <></>
      ) : (
        <div className="patient-consultation-content-wrapper">
          <div style={containerStyle}>
            <button onClick={handleCloseConsultationForm} style={buttonStyle}>
              ✖
            </button>
          </div>
          <div className="page-content nms">
            {getStageNameAndCurrentIndex()}
            <div className="main-div">
              <div className="main-div-inner">
                <div
                  className={`nms-tabs-container nms ${
                    showResponsiveSidebar ? "show-nms-sidebar" : ""
                  }`}
                >
                  <h2 className="img-heading para_color">
                    <FaRegUser />
                    {isNmsLoading === true ? (
                      <Skeleton
                        sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                        height={30}
                        width={100}
                        animation="wave"
                        variant="rounded"
                      />
                    ) : (
                      service?.patientName
                    )}
                  </h2>

                  <PrivateServiceTabs
                    stageName={previousStageName}
                    AddVaccine={AddVaccine}
                  />
                </div>
                <div
                  className="sidebar-hamburger nms-hamburger"
                  onClick={handleNMSSidebar}
                >
                  {showResponsiveSidebar ? (
                    <div className="nms-ham">
                      <FaTimes />
                      <span> Close Patient Details</span>
                    </div>
                  ) : (
                    <div className="nms-ham">
                      <PiCaretRightBold />
                      <span> Patient Details</span>
                    </div>
                  )}
                </div>
                <div className="content-div">
                  <div className="custom-staging-tabs-container nms-content-area ps-content-area">
                    {isNmsLoading ? (
                      <LoadingComponentNMS />
                    ) : (
                      service?.stages[value].template?.templateSections?.map(
                        (item, index) => {
                          if (
                            item.isParent ||
                            item.groupId ===
                              "00000000-0000-0000-0000-000000000000"
                          ) {
                            count++;
                            return (
                              <>
                                <PrivateServiceTemplateSection
                                  key={index}
                                  UserControls={item.userControls}
                                  number={count}
                                  SectionTitle={item.sectionTitle}
                                  groupId={item.groupId}
                                  templateSection={
                                    service?.stages[value].template
                                      .templateSections
                                  }
                                  stageStatus={
                                    service?.stages[value]
                                      ?.clinicalServiceStageStatus === 2
                                      ? true
                                      : false
                                  }
                                  boxStyling={boxStyling}
                                  order={item.order}
                                  Type={item.templateSectionType}
                                  handleTemplateData={UpdateNmsModel}
                                  isCompleted={isRequired}
                                  pharmacyId={pharmacyID}
                                  ClinicalpatientId={clinicalpatientId}
                                  clinicalServiceId={clinicalServiceId}
                                  darkModeChange={darkMode}
                                  stageNo={value}
                                  patientName={patientName}
                                  patientNameChange={null}
                                  serviceID={service?.serviceID}
                                  templateRequired={setCountColor(
                                    value,
                                    item?.groupId,
                                    item?.sectionTitle
                                  )}
                                />
                              </>
                            );
                          }
                        }
                      )
                    )}
                    {TempVaccineTemplates.length > 0 ? (
                      service?.stages[1]?.clinicalServiceStageStatus !== 2 ? (
                        <>
                          {TempVaccineTemplates?.map((medicine, index) => {
                            count++;
                            return (
                              <AddVaccineItem
                                key={index}
                                template={medicine}
                                active={index}
                                number={count}
                                handleChangeModel={UpdateVaccineTemplateModel}
                                handleRemove={removeVaccineTemplateItem}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {TempMedicineTemplates.length > 0 ? (
                      service?.stages[1]?.clinicalServiceStageStatus !== 2 ? (
                        <>
                          {TempMedicineTemplates?.map((medicine, index) => {
                            count++;
                            return (
                              <AddPrescriptionItem
                                key={index}
                                template={medicine}
                                active={index}
                                number={count}
                                handleChangeModel={UpdateTemplateModel}
                                handleRemove={removeTemplateItem}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isOrbitUser && (
              <div className="action-footer">
                <div className="ps-action-footer-cont ">
                  {isNmsLoading ? (
                    <Skeleton
                      sx={{ bgcolor: darkMode ? "grey.800" : "" }}
                      height={30}
                      width={150}
                      animation="wave"
                      variant="rounded"
                    />
                  ) : (
                    <>
                      <CustomButton
                        label="Add Medicine"
                        action={AddTemplateSection}
                        className={"ps-btn-green"}
                      />
                      <CustomButton
                        label="Add Vaccine"
                        className={"ps-btn-green"}
                        action={() => {
                          AddVaccine("");
                        }}
                      />
                      {/* <CustomButton
                        label="Write GP Letter"
                        className={"ps-btn-blue"}
                        action={null}
                      />
                      <CustomButton
                        label="Email Certificate"
                        className={"ps-btn-blue"}
                        action={null}
                      /> */}
                      <CustomButton
                        label="Generate Prescription"
                        className={"ps-btn-blue"}
                        action={() => {
                          submitPrivateService(
                            value,
                            true,
                            "generatePrescription"
                          );
                        }}
                      />

                      <CustomButton
                        className={"ps-btn-grey"}
                        label="Complete"
                        action={() => {
                          submitPrivateService(value, false, "complete");
                        }}
                        isdisable={validateComplete(value)}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PrivateService;

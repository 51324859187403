import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Dropdown } from "primereact/dropdown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as AutomateIcon } from "./../../assets/images/automate-icon.svg";
import useTitanApp from "../../hooks/useTitanApp";
import {
  changePharmacyActions,
  setSendInterventionAutomation,
  setSendOrbitAutomation,
  setSendSMSAutomation,
  setSendToInterventionOptions,
  setIsAutomationMenuOpen,
  setSendConsentOnly,
} from "../../dispatchers/pharmacyServiceDispatchers";
import { ReactComponent as ClinicalChecksIcon } from "./../../assets/images/medical_checklist.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getPrescriptionIncomingIds,
  setIsCCOpenModal,
  setIsCCModalLoader,
} from "../../dispatchers/clinicalChecksDispatchers";
import CustomLoader from "../controls/CustomLoader";
import { Oval } from "react-loader-spinner";

export default function ServicesAutomation() {
  let navigate = useNavigate();
  const { pharmacyServiceState, appState, clincicalChecksState } =
    useTitanApp();
  const { darkMode } = appState;
  const { CCModalLoader } = clincicalChecksState || {};
  const params = useParams();
  const { pharmacyId } = params || {
    pharmacyId: "00000000-0000-0000-0000-000000000000",
  };
  const {
    sendSMSAutomation,
    sendOrbitAutomation,
    sendInterventionAutomation,
    sendToInterventionOptions,
    SendToInterventionDropdownOptions,
    isAutomationMenuOpen,
    sendConsentOnly,
  } = pharmacyServiceState;
  const { currentPharmacyId, features, currentTeamId } = appState;
  const { ifClinicalChecksEnabled } = features;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [automationMenuToggle, setAutomationMenuToggle] = useState(false);
  const [sInterventionAutomation, setSInterventionAutomation] = useState(
    sendInterventionAutomation
  );
  const [stInterventionOptions, setSTInterventionOptions] = useState(
    sendToInterventionOptions
  );
  const [sInterventionDDOptions, setSInterventionDDOptions] = useState(
    SendToInterventionDropdownOptions
  );
  const [sSMSAutomation, setSSMSAutomation] = useState(sendSMSAutomation);
  const [stOrbit, setSTOrbit] = useState(sendOrbitAutomation);
  const [sConsentOnly, setSConsentOnly] = useState(sendConsentOnly);

  const handleConsentChange = (event) => {
    setSTInterventionOptions(event.value);
    if (event?.value?._id === 0) {
      changePharmacyActions(currentPharmacyId, 3, true);
      changePharmacyActions(currentPharmacyId, 2, false);
      setSInterventionAutomation(false);
      setSConsentOnly(true);
    } else {
      changePharmacyActions(currentPharmacyId, 2, true);
      changePharmacyActions(currentPharmacyId, 3, false);
      setSConsentOnly(false);
      setSInterventionAutomation(true);
    }
  };

  const handleClick = (event) => {
    setAutomationMenuToggle(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAutomationMenuToggle(false);
    setAnchorEl(null);
  };

  const handleChecksClick = () => {
    setIsCCModalLoader(true);
    // setIsCCOpenModal(true);

    getPrescriptionIncomingIds({ pharmacyId, currentTeamId }).then(
      (response) => {
        if (response.success && response.response.length > 0) {
          setIsCCModalLoader(false);
          // setIsCCOpenModal(false);

          const url = `/clinicalchecks/${pharmacyId}`;
          navigate(url, {
            state: {
              serviceType: "Kanban",
            },
          });
        } else {
          setIsCCModalLoader(false);
          setIsCCOpenModal(true);
        }
      }
    );
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleAutoSendSMSChange = (e) => {
    changePharmacyActions(currentPharmacyId, 0, !sSMSAutomation);
    setSSMSAutomation(!sSMSAutomation);
  };

  const handleAutoSendOrbitChange = (e) => {
    changePharmacyActions(currentPharmacyId, 1, !stOrbit);
    setSTOrbit(!stOrbit);
  };

  const handleAutoSendInterventionChange = (e) => {
    if (e.target.checked) {
      changePharmacyActions(currentPharmacyId, 3, true);
      setSConsentOnly(true);
    } else {
      changePharmacyActions(currentPharmacyId, 4, false);
      setSInterventionAutomation(false);
      setSConsentOnly(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="custom-btns-container">
        {CCModalLoader && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "30px",
            }}
          >
            <Oval
              height={25}
              width={25}
              color={`${darkMode ? "#fff" : "#333"}`}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={`${darkMode ? "#fff" : "#333"}`}
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}

        {ifClinicalChecksEnabled && (
          <>
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleChecksClick}
              className="automation-button"
            >
              <ClinicalChecksIcon />
            </Button>
          </>
        )}

        <Button
          id="fade-button"
          aria-controls={automationMenuToggle ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={automationMenuToggle ? "true" : undefined}
          onClick={handleClick}
          className="automation-button"
        >
          <AutomateIcon />
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          className="automation-menu"
          anchorEl={anchorEl}
          open={automationMenuToggle}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem className="menuItem" disabled={true}>
            <AntSwitch
              name="autoSendSMS"
              checked={sSMSAutomation}
              onChange={handleAutoSendSMSChange}
              disabled={true}
            />
            <div className="content">
              <p>Auto Send SMS</p>
              <p>
                Automatically send the patient an SMS to consent and sign up for
                NMS
              </p>
            </div>
          </MenuItem>
          <MenuItem className="menuItem" disabled={true}>
            <AntSwitch
              name="autoSendOrbit"
              checked={stOrbit}
              onChange={handleAutoSendOrbitChange}
              disabled={true}
            />
            <div className="content">
              <p>Auto Send to Orbit</p>
              <p>
                Automatically send NMS to ORBIT for outsourcing and completion
              </p>
            </div>
          </MenuItem>
          <MenuItem className="menuItem">
            <AntSwitch
              name="autoSendIntervention"
              checked={sInterventionAutomation || sConsentOnly}
              onChange={handleAutoSendInterventionChange}
            />
            <div className="content consent_dropdown">
              <p>Auto Send to Intervention</p>
              <p className="content_paragraph">
                Automatically send NMS to Intervention for outsourcing and
                completion
              </p>
              {sInterventionAutomation || sConsentOnly ? (
                <Dropdown
                  value={stInterventionOptions}
                  onChange={handleConsentChange}
                  options={sInterventionDDOptions}
                  optionLabel="optionName"
                  className="invite-dropdown"
                />
              ) : (
                <></>
              )}
            </div>
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  );
}

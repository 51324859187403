import { dispatch, getDataFromStore } from "../store/store";
import {
  addServiceData,
  getServicesData,
  updateServiceData,
  getServiceTypeByIdData,
  setIsTypeLoading,
  setCurrentTypeData,
  setCurrentServiceModelData,
  setCurrentClinicalServiceBuilderData,
  setCurrentStagesData,
  setCurrentPharmacyIdsData,
  getServicesByCompanyData,
  getClinicalServiceBuilderResponseData,
  setProductDetail,
  setAppointmentOptions,
  setConfirmationCancellation,
  setReminders,
  setShareandEmbed,
  setGeneral,
  setServiceTemplateControlValue,
  setCheckServiceTemplate,
  updateClinicalServiceBuilderData,
  updateAvailabilitySlot,
  removeAvailabilitySlot,
  setClinicalServiceBuilderModel,
} from "../features/typeSlice/typeSlice";
import { logError } from "../services/logService";

const reducerName = "type";

// #region State Setters
export const setTypeLoading = (isLoading) => {
  dispatch(setIsTypeLoading(isLoading));
};

export const setCurrentType = (typeObject) => {
  dispatch(setCurrentTypeData(typeObject));
};

export const setCurrentServiceModel = (modelObject) => {
  dispatch(setCurrentServiceModelData(modelObject));
};

export const setCurrentClinicalServiceBuilder = (
  clinicalServiceBuilderObject
) => {
  dispatch(setCurrentClinicalServiceBuilderData(clinicalServiceBuilderObject));
};

export const setCurrentStages = (stagesArray) => {
  dispatch(setCurrentStagesData(stagesArray));
};

export const setCurrentPharmacyIds = (pharmacyIdsArray) => {
  dispatch(setCurrentPharmacyIdsData(pharmacyIdsArray));
};

export const setProductDetails = (details) => {
  try {
    dispatch(setProductDetail(details));
  } catch (error) {
    logError(error);
  }
};

export const setProductAppointments = (details) => {
  try {
    dispatch(setAppointmentOptions(details));
  } catch (error) {
    logError(error);
  }
};
export const setConfirmationCancellationDetails = (details) => {
  try {
    dispatch(setConfirmationCancellation(details));
  } catch (error) {
    console.error("Error updating confirmation cancellation details:", error);
  }
};

export const setReminderDetails = (details) => {
  try {
    dispatch(setReminders(details));
  } catch (error) {
    console.error("Error updating confirmation cancellation details:", error);
  }
};
export const setShareAndEmbedData = (details) => {
  try {
    dispatch(setShareandEmbed(details));
  } catch (error) {
    logError(error);
  }
};

export const setGeneralData = (details) => {
  try {
    dispatch(setGeneral(details));
  } catch (error) {
    logError(error);
  }
};

export const verifyServiceTemplateChecks = (isChecked) => {
  dispatch(setCheckServiceTemplate(isChecked));
};
export const initializeClinicalServiceBuilderModel = (appData) => {
  try {
    dispatch(setClinicalServiceBuilderModel(appData));
  } catch (error) {
    console.error("Error initializing model:", error);
  }
};

export const updateClinicalServiceBuilder = async (data) => {
  let result = null;
  try {
    result = await dispatch(updateClinicalServiceBuilderData(data)).unwrap();
  } catch (error) {
    result = null;
  }
  return result;
};

export const updateServiceTemplateControlValue = (
  templateIndex,
  sectionIndex,
  controlIndex,
  value,
  control
) => {
  try {
    dispatch(
      setServiceTemplateControlValue({
        templateIndex,
        sectionIndex,
        controlIndex,
        value,
        control,
      })
    );
  } catch (error) {
    logError(error);
  }
};

// Add or update a slot in availability
export const addOrUpdateSlot = (dayOfWeek, slots) => {
  try {
    dispatch(updateAvailabilitySlot({ dayOfWeek, slots }));
  } catch (error) {
    console.error("Error updating slot:", error);
  }
};

// Remove a slot or an entire day
export const deleteSlot = (dayOfWeek, slotIndex = null) => {
  try {
    dispatch(removeAvailabilitySlot({ dayOfWeek, slotIndex }));
  } catch (error) {
    console.error("Error removing slot:", error);
  }
};

// #endregion

// #region API getter/caller functions for components usage to dispatch REDUX extra reducers
export const getServicesTypes = async () => {
  let result = null;
  try {
    result = await dispatch(getServicesData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const addServiceType = async (data) => {
  let result = null;
  try {
    result = await dispatch(addServiceData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getServiceTypeById = async (serviceTypeId) => {
  let result = null;
  try {
    result = await dispatch(getServiceTypeByIdData(serviceTypeId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const updateServiceType = async (data) => {
  let result = null;
  try {
    result = await dispatch(updateServiceData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getServicesByCompany = async () => {
  let result = null;
  try {
    result = await dispatch(getServicesByCompanyData()).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getClinicalServiceBuilderResponse = async (
  serviceId,
  companyId
) => {
  let result = [];
  try {
    result = await dispatch(
      getClinicalServiceBuilderResponseData({ serviceId, companyId })
    ).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

// #endregion
